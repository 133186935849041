import TWEEN from '@tweenjs/tween.js'
import { Vector3 } from 'three'

import { Info } from '@/logic/Info'
import { Debounce } from '@/Util/decorators/Debounce'

import MainView from '.'
import ConditionUtil from './ConditionUtil'
import Getters from './Getters'

export default class CameraHandlers {
  public static stopTween (view: MainView) {
    if (view.cameraTween) {
      view.cameraTween.stop()

      TWEEN.remove(view.cameraTween)

      view.tweenActive = false
    }
  }

  public static tweenCamera (
    view: MainView,
    position: Vector3,
    center: Vector3,
    xAngle: number | undefined,
    yAngle: number | undefined,
    duration: number,
    callback: () => void,
  ) {
    if (!view.camera) {
      return
    }

    view.tweenActive = true

    CameraHandlers.stopTween(view)

    const { position: positionRaw } = view.camera
    const { target: targetRaw } = view.controls
    const pos = positionRaw.clone()
    const target = targetRaw.clone()

    if (ConditionUtil.isCameraInPlace(pos, target, position, center)) {
      view.tweenActive = false

      return callback()
    }

    view.cameraTween = Getters.getCameraTween(view, pos, target, xAngle, yAngle, center, position, duration, callback)
  }

  public static setCameraPosition (
    position: Vector3,
    lookAt: Vector3,
    camera: any,
    controls: any,
    isMainView: boolean,
  ) {
    if (!camera) {
      return
    }

    camera.position.copy(position)
    camera.lookAt(lookAt)
    controls.target.copy(lookAt)

    if (!isMainView) {
      return
    }

    CameraHandlers.updateState(position, lookAt)
  }

  @Debounce(100)
  public static updateState (position: Vector3, lookAt: Vector3) {
    Info.setRecentlyUsedInfo({ casterCameraPosition: position, casterCameraLookAt: lookAt })
  }
}
