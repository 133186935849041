/* eslint-env browser */

import { faCopy } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'

import { DialogID } from '@/react/driver/DriverID'
import { Text } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import TableComponent from '@/react/visualization/dashboard/Plots/TablePlot/Table'
import * as ApplicationActions from '@/store/application/main/actions'
import type { CasterElementNames } from '@/types/data'
import type { DefaultState } from '@/types/state'
import { Identifiable } from '@/Util/decorators/Identifiable'
import { generateChangeLogs } from '@/Util/RealDataUtil'

import BaseDialog from '../BaseDialog'
import { Content } from '../DialogStyles'

const StyledIcon = styled(FontAwesomeIcon)`${() => css`
  cursor: pointer;
  margin-left: 5px;
`}`

const RealDataContent = styled(Content)`${() => css`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 20px;
  font-size: 14px;

  > div {
    flex: 1;
  }
`}`

const connector = connect((state: DefaultState) => ({
  params: state.application.main.params,
  currentProject: state.application.main.currentProject,
}), {
  closeDialog: ApplicationActions.closeDialog,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  params: { elementType: CasterElementNames, realDataUUID: string }
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  mountLogs: any[]
}

const T = 'realDataElementDetailsDialog'

export class RealDataElementDetailsDialog extends Component<Props, State> {
  @Identifiable('RealDataElementDetailsDialog') public static readonly NAME: string

  public override state: State = {
    mountLogs: [],
  }

  public override componentDidMount () {
    hotkeys('Escape', this.handleClose)

    this.fetchData()
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(RealDataElementDetailsDialog.NAME)
  }

  private readonly handleCopyCaseId = async () => {
    const { t } = this.props
    const { realDataUUID } = this.props.params

    if (!realDataUUID) {
      enqueueSnackbar(t(`${T}.message.cannotCopyRealDataUUID`), { autoHideDuration: 3000, variant: 'error' })
    }

    try {
      // this only works in secure contexts (https)
      await navigator.clipboard.writeText(realDataUUID)

      enqueueSnackbar(t(`${T}.message.copiedRealDataUUID`), { autoHideDuration: 3000, variant: 'success' })
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.error(error?.message)

      enqueueSnackbar(t(`${T}.message.cannotCopyRealDataUUID`), { autoHideDuration: 3000, variant: 'error' })
    }
  }

  // FIXME: rework to use the new getTemporalData function
  private readonly fetchData = () => {
    // const { params, currentProject } = this.props
    // const neededData: TypeAndIdNeededData[] = [ `${params.elementType}|${params.realDataUUID}` ]

    this.setState({ mountLogs: [] })

    // try {
    //   const data = await getTemporalData(currentProject.id, neededData)

    //   if (!data?.[0]) {
    //     return
    //   }

    //   this.setState({ mountLogs: data[0] })
    // }
    // catch (error: any) {
    //   // eslint-disable-next-line no-console
    //   console.log(error)
    // }
  }

  public override render () {
    const { params, currentProject, t } = this.props
    const { realDataUUID, elementType } = params
    const { mountLogs } = this.state

    const changeLogs = generateChangeLogs(mountLogs)

    return (
      <BaseDialog
        id={DialogID.RealDataElementDetails.ID}
        title={t(`${T}.title`)}
        icon='pe-7s-folder'
        header={t(`${T}.header`)}
        onClose={this.handleClose}
        // small
      >
        <RealDataContent>
          <Text $margin={10} style={{ marginTop: '0px', marginBottom: '20px' }}>
            RealDataUUID: {realDataUUID} <StyledIcon icon={faCopy} onClick={this.handleCopyCaseId} />
          </Text>
          <div style={{ minHeight: '200px', maxHeight: '500px' }}>
            <TableComponent
              realDataUUID={realDataUUID}
              elementType={elementType}
              temporalData={{ [realDataUUID]: mountLogs }}
              project={currentProject}
            
            />
          </div>
          <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {
              changeLogs.map((log, index) => (
                <div key={`change${index}`} style={{ marginTop: '10px' }}>
                  {log.firstInterval} - {log.lastInterval}
                  {
                    log.changedKeys && Object.entries(log.changedKeys).map(([ key, { from, to } ]) => (
                      <div key={key}>
                        {key}: {JSON.stringify(from)} &#10143; {JSON.stringify(to)}
                      </div>
                    ))
                  }
                </div>
              ))
            }
          </div>
        </RealDataContent>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(RealDataElementDetailsDialog)

export default hoistStatics(composedComponent, RealDataElementDetailsDialog)
