import { Component } from 'react'
import styled, { css, keyframes } from 'styled-components'

const spin = keyframes`
  0% {
    transform:rotate(0deg);
  }
  100% { 
    transform:rotate(360deg); 
  }
`

export const Loading = styled.div`${() => css`
  position: absolute;
  inset: 0;
  user-select: none;
  overflow: hidden;
  backdrop-filter: blur(10px);
`}`

export const LoadingCircle = styled.div`
  top: calc(50% - 75px);
  left: calc(50% - 50px);
  position: absolute;
  width: 100px;
  height: 100px;
  pointer-events: none;
  align-items: center;
  justify-content: center;
  display: flex;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    filter: brightness(0) invert(1);
    opacity: 0.15;
  }
`

export const OuterCircle = styled.img`
  animation: ${spin} 3.4s infinite linear;
`

export const InnerCircle = styled.img`
  animation: ${spin} 4.2s infinite linear;
`

export const MiddleCircle = styled.img`
  animation: ${spin} 3.8s infinite reverse linear;
`

export const Ball = styled.img``

export default class CasterLoadingOverlay extends Component {
  public override render () {
    return (
      <Loading>
        <LoadingCircle>
          <Ball src='images/waiting-1.svg' alt='' />
          <InnerCircle src='images/waiting-2.svg' alt='' />
          <MiddleCircle src='images/waiting-3.svg' alt='' />
          <OuterCircle src='images/waiting-4.svg' alt='' />
        </LoadingCircle>
      </Loading>
    )
  }
}
