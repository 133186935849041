import { NewProjectDialog } from '@/react/dialogs/project/NewProjectDialog'
import { OpenProjectDialog } from '@/react/dialogs/project/OpenProjectDialog'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import { DataActionsEnum } from '@/store/data/consts'

import { ApplicationMainActionsEnum as AppMainActions } from '../consts'

const dialogReducers = {
  [AppMainActions.ACTION_SET_OPEN_DIALOGS]: (state: ApplicationMainState, action: DialogAction) => {
    const openDialogs = [ ...state.openDialogs ]

    if (openDialogs.includes(action.dialogName)) {
      return {
        ...state,
        openDialogs: openDialogs.filter((dialog, i) =>
          dialog !== action.dialogName && openDialogs.indexOf(dialog) === i
        ),
      }
    }

    openDialogs.push(action.dialogName)

    return {
      ...state,
      openDialogs,
    }
  },
  [AppMainActions.ACTION_OPEN_DIALOG]: (state: ApplicationMainState, { dialogName, params }: DialogAction) =>
    !state.openAppDialogs.includes(dialogName)
      ? ({
        ...state,
        openAppDialogs: [
          ...state.openAppDialogs,
          dialogName,
        ],
        params,
      })
      : state,

  [AppMainActions.ACTION_CLOSE_DIALOG]: (state: ApplicationMainState, { dialogName }: DialogAction) => {
    let dialogNameToClose: string | null

    switch (dialogName) {
      case ProjectDataDialog.NAME:
        dialogNameToClose = ProjectDataDialog.NAME
        break
      case NewProjectDialog.NAME:
        dialogNameToClose = NewProjectDialog.NAME
        break
      case OpenProjectDialog.NAME:
        dialogNameToClose = OpenProjectDialog.NAME
        break
      default:
        dialogNameToClose = null
    }

    return {
      ...state,
      openAppDialogs: state
        .openAppDialogs
        .filter(openAppDialog => openAppDialog !== dialogName && openAppDialog !== dialogNameToClose),
    }
  },

  [AppMainActions.ACTION_CLEAR_DIALOGS]: (state: ApplicationMainState) => ({
    ...state,
    openAppDialogs: [],
  }),

  [DataActionsEnum.ACTION_SELECT_EDIT_DATA]: (
    state: ApplicationMainState,
    { selectedData }: { selectedData: string[] | null },
  ) => {
    if (!selectedData?.length || state.openDialogs.includes('CasterDialog')) {
      return state
    }

    if (state.openDialogs.includes('PartsWarehouse')) {
      return {
        ...state,
        openDialogs: [
          ...state.openDialogs,
          'PartsWarehouse',
        ],
      }
    }

    return {
      ...state,
      openDialogs: [
        ...state.openDialogs,
        'CasterDialog',
      ],
    }
  },
}

export default dialogReducers
