import cloneDeep from 'lodash/cloneDeep'
import { enqueueSnackbar } from 'notistack'

import { request } from '@/api'
import { getNozzleCatalogContents } from '@/api/nozzle-catalog'
import { App, type Props } from '@/App'
import AppHandlers from '@/App/handlers'
import { useConfig } from '@/config'
import { AuthDataStore } from '@/logic/auth-data-store'
import { Info, RecentlyUsedInfo } from '@/logic/Info'
import BothLogic from '@/logicHandlers/BothLogic'
import ElectronLogic from '@/logicHandlers/ElectronLogic'
import ExternalLogic from '@/logicHandlers/ExternalLogic'
import InternalLogic from '@/logicHandlers/InternalLogic'
import ServerLogic from '@/logicHandlers/ServerLogic'
import DownloadUtil from '@/logicHandlers/ServerLogic/actions/Util'
import { DownloadXMLDialog } from '@/react/dialogs/executables/DownloadXMLDialog'
import { ExecutableDialog } from '@/react/dialogs/executables/ExecutableDialog'
import { LoginDialog } from '@/react/dialogs/LoginDialog'
import { CreateRealDataCaseDialog } from '@/react/dialogs/project/CreateRealDataCaseDialog'
import { CreateRealDataDialog } from '@/react/dialogs/project/CreateRealDataDialog'
import { ManageDynamicDataSourcesDialog } from '@/react/dialogs/project/ManageDynamicDataSourcesDialog'
import { ManageExternalDataSourcesDialog } from '@/react/dialogs/project/ManageExternalDataSourcesDialog'
import { NewProjectDialog } from '@/react/dialogs/project/NewProjectDialog'
import { OpenProjectDialog } from '@/react/dialogs/project/OpenProjectDialog'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import { CasterColumnLogic } from '@/react/dialogs/project/ProjectDataDialog/CasterColumnLogic'
import Logic from '@/react/dialogs/project/ProjectDataDialog/Logic'
import { SelectCatalogDialog } from '@/react/dialogs/project/ProjectDataDialog/SelectCatalogDialog'
import { SelectDashboardDialog } from '@/react/dialogs/project/SelectDashboardDialog'
import { ShareStateDialog } from '@/react/dialogs/ShareStateDialog'
import { UsageTimeExceededDialog } from '@/react/dialogs/UsageTimeExceededDialog'
import FeatureFlags from '@/react/FeatureFlags'
import ApiClient from '@/store/apiClient'
import { setLastLoadedCasterCatalogId } from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import { handleNewMountLog } from '@/store/data/logic'
import { linkDataLinesWithElements } from '@/store/elements/logic/index'
import Util from '@/store/visualization/util/Util'
import ThreeManager from '@/three/ThreeManager'
import type { ElementMaps } from '@/types/state'
import { buildElementMountLogsToKeyUUIDsMap } from '@/Util/dataServerUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'

import { getCasterAtTime, getCurrentCaster } from './api/caster'
import { getElementMapsObject } from './store/elements/logic'
import CameraHandlers from './three/views/MainView/CameraHandlers'
import { Mapping } from './Util/mapping/Mapping'
import { SetUtil } from './Util/SetUtil'

export default class IpcManager {
  public static props: Props

  // Function to handle lock caster inside App class
  public static handleLockCaster: AppHandlers['handleLockCaster'] // FIXME: we should not need this

  private static electronHandler: ElectronLogic

  private static serverHandler: ServerLogic

  private static externalHandler: ExternalLogic

  private static internalHandler: InternalLogic

  private static bothHandler: BothLogic

  private static app: App

  public static featureFlags: Record<string, boolean>

  public static init (app: App) {
    IpcManager.app = app

    window.IpcManager = IpcManager
  }

  public static get electron () {
    if (!IpcManager.electronHandler) {
      IpcManager.electronHandler = new ElectronLogic()
    }

    return IpcManager.electronHandler
  }

  public static get server () {
    if (!IpcManager.serverHandler) {
      IpcManager.serverHandler = new ServerLogic()
    }

    return IpcManager.serverHandler
  }

  public static get external () {
    if (!IpcManager.externalHandler) {
      IpcManager.externalHandler = new ExternalLogic()
    }

    return IpcManager.externalHandler
  }

  public static get internal () {
    if (!IpcManager.internalHandler) {
      IpcManager.internalHandler = new InternalLogic()
    }

    return IpcManager.internalHandler
  }

  public static get both () {
    if (!IpcManager.bothHandler) {
      IpcManager.bothHandler = new BothLogic()
    }

    return IpcManager.bothHandler
  }

  public static send (key: string, ...params: unknown[]) {
    if (IpcManager.internal.has(key)) {
      return IpcManager.internal.send(key, ...params)
    }

    return IpcManager.external.send(key, ...params)
  }

  public static updateProps (props: Props) {
    IpcManager.props = props
  }

  public static registerEvents (props: Props, forceUpdate: () => void) {
    IpcManager.props = props

    const {
      // addComparisonCaster, // FIXME
      closeDialog,
      hasChanges,
      openDialog,
      resetHasChanges,
      resetReducer,
      // setAppState,
      // setAuthenticationData,
      setCurrentProject,
      setCurrentProjectCasesMetadata,
      setCurrentSimulationCase,
      setLoadingStatus,
      setSavePath,
      setTerm,
      setUserSettings,
      switchProject,
    } = IpcManager.props

    IpcManager.both.on('loggingInToLocalServer', (_event: Event, loggingIn: boolean) => {
      IpcManager.app.setState({
        loggingInToLocalServer: loggingIn,
      })
    })

    // FIXME: use new API once implemented
    IpcManager.both.on(
      'loadConsistencyCheckCase',
      (_event: Event, _consistencyCheckCaseId: string) => {
        // eslint-disable-next-line no-console
        console.error('Not implemented')
      },
    )
    // FIXME: this does not work we need to handle this with the API
    // IpcManager.both.on(
    //   'loadConsistencyCheckCase',
    //   (event: Event, consistencyCheckCaseId: string) => {
    //     authenticateUser(
    //       'ConsistencyCheck',
    //       consistencyCheckCaseId,
    //       false,
    //       {},
    //       (data: any) => {
    //         // this is set in smsg-caster Session.js loginAsConsistencyCheckUser
    //         const { authData, simulationCase, project, root } = data

    //         if (!authData || authData.error) {
    //           enqueueSnackbar(t('localLogin.consistencyCheckError'), { autoHideDuration: 4000, variant: 'error' })

    //           return
    //         }

    //         setAuthenticationData(authData)

    //         if (!authData?.featureFlags?.Caster__View) {
    //           setAppState(AppState.ResultDashboard)
    //         }

    //         closeDialog(LoginDialog.NAME)
    //         closeDialog(OpenProjectDialog.NAME)
    //         closeDialog(NewProjectDialog.NAME)

    //         IpcManager.app.setState({
    //           loggingInToLocalServer: false,
    //         })

    //         setAppState(AppState.Caster)
    //         setCurrentProject(project)
    //         setCurrentSimulationCase(simulationCase)

    //         setTimeout(() => {
    //           IpcManager.internal.send(
    //             // 'data',
    //             // null,
    //             root,
    //             true,
    //             null,
    //           )
    //         }, 100)
    //       },
    //     )
    //   },
    // )

    IpcManager.both.on(
      'loadDefaultCase',
      async (dataFeatureFlags: Record<string, boolean>) => {
        await Info.initFromStateLink()

        const info = Info.getRecentlyUsedInfo()
        const shouldUsePrevious = Boolean(info?.projectId && info?.simulationCaseId)

        if (!shouldUsePrevious) {
          Info.unlock()
        }

        // dataFeatureFlags is sent when authenticated because the event is called before the authentication data
        // is set in redux
        try {
          let defaultProject: Project | null = null
          let defaultCase: SimulationCase | null = null

          if (!shouldUsePrevious) {
            const { cases: defaultCaseArray } = await ApiClient.get(
              `${useConfig().apiBaseURL}/cases`,
              {
                params: {
                  isDefault: true,
                  withProject: true,
                  byUser: true,
                },
              },
            )

            defaultCase = defaultCaseArray?.[0] ?? null
          }

          const defaultProjectId = defaultCase?.projectId ?? info.projectId

          const response = await request<{ cases: SimulationCase[] }>(
            'get',
            'cases',
            '', // error is suppressed
            {
              params: {
                projectId: defaultProjectId,
                withProject: shouldUsePrevious,
              },
            },
            true,
          )

          if (!defaultCase && shouldUsePrevious) {
            defaultCase = response?.cases?.find((c) => c.id === info.simulationCaseId) ?? null
          }

          defaultProject = defaultCase?.project ?? null

          if (defaultCase && defaultProject) {
            const slimVersion = FeatureFlags.usesSlimVersion(dataFeatureFlags)

            switchProject()
            setCurrentProject(defaultProject)
            setCurrentSimulationCase(defaultCase, slimVersion)
            closeDialog(LoginDialog.NAME)
            closeDialog(ProjectDataDialog.NAME)
            setLoadingStatus(true)

            if (response) {
              const { cases } = response

              setCurrentProjectCasesMetadata(cases.map((c) => ({ id: c.id, createdAt: new Date(c.createdAt) })))
            }

            IpcManager.send(
              'loadCurrentCaster',
              {
                simulationCase: defaultCase,
                caseId: null,
                configIdFromExec: shouldUsePrevious ? info.casterDashboardConfigId : null,
                tab: shouldUsePrevious ? info.casterDashboardTabIndex : 0,
                info: shouldUsePrevious ? info : null,
              },
            )

            IpcManager.app.setState({
              loggingInToLocalServer: false,
            })

            return
          }

          closeDialog(LoginDialog.NAME)
          openDialog(OpenProjectDialog.NAME)
          IpcManager.app.setState({
            loggingInToLocalServer: false,
          })
        }
        catch (error: any) {
          enqueueSnackbar('Failed to load default case', { variant: 'error' })
          // eslint-disable-next-line no-console
          console.log(error)
          closeDialog(LoginDialog.NAME)
          openDialog(OpenProjectDialog.NAME)
          IpcManager.app.setState({
            loggingInToLocalServer: false,
          })
        }
      },
    )

    IpcManager.both.on('apiReady', async (
      _event: Event,
      { machineId }: { machineId: string, apiPort: number },
    ) => {
      const { setAuthenticationData } = IpcManager.props
      const apiURL = `${useConfig().apiBaseURL}/auth/local_login`

      try {
        const tokenData = await ApiClient.post(apiURL, { data: { machineId } }) as AuthResult

        const { featureFlags } = tokenData

        if (!featureFlags?.length) {
          // eslint-disable-next-line no-console
          console.error('No local credentials')

          return
        }

        AuthDataStore.set(tokenData)

        const featureFlagsObj = featureFlags.reduce((acc, flag) => {
          acc[flag] = true

          return acc
        }, {} as Record<string, boolean>)

        setAuthenticationData({
          featureFlags: featureFlagsObj,
          id: 'localUserId',
          name: 'localUser',
          time: Date.now(),
          groups: [],
          casterLoadTime: null,
          userType: 'local',
        })

        IpcManager.featureFlags = featureFlagsObj

        IpcManager.internal.send('loadDefaultCase', featureFlagsObj)
      }
      catch (error: any) {
        // eslint-disable-next-line no-console
        console.error('No local credentials')
      }
    })

    IpcManager.both.on(
      'setUserSettings',
      (_event: Event, settings: { version: any, projectName: any, featureFlags: any }) => {
        IpcManager.app.setState({
          electronReady: true,
        })
        setUserSettings(settings)
      },
    )

    IpcManager.both.on('meta', (_event: Event, meta: { DEV: boolean }) => {
      window.meta = meta
    })

    IpcManager.both.on(
      'data',
      ({
        path,
        info,
        keepFilter,
      }: {
        path: string
        info?: RecentlyUsedInfo
        keepFilter?: boolean
      }) => {
        const { term } = IpcManager.props

        if (term && !keepFilter) {
          setTerm('')
        }

        resetReducer(true, true, true)
        setSavePath(path)

        // TODO: find a better solution for this
        IpcManager.app.setState({
          redraw: true,
        })

        if (info?.filterTerm) {
          setTerm(info.filterTerm)
        }

        if (info) {
          ThreeManager.base?.views?.mainView?.applyInfo?.(info)
          ThreeManager.base?.views?.sectionView?.applyInfo?.(info)
          ThreeManager.base?.views?.uiView?.applyInfo?.(info)
        }

        setTimeout(() => {
          IpcManager.app.setState({
            redraw: false,
          })

          if (info) {
            Info.unlock()
            Info.setRecentlyUsedInfo(info)

            if (info.casterCameraPosition && info.casterCameraLookAt) {
              // we need to trigger updateState again or the debounce will set wrong values
              CameraHandlers.updateState(info.casterCameraPosition, info.casterCameraLookAt)
            }
          }
        }, 500)

        IpcManager.both.send('loading', null, false, true)
      },
    )

    IpcManager.both.on(
      'loading',
      (_event: any, status: boolean, forceLoading: boolean, ready: string, isLoadingNewCaster: boolean) => {
        IpcManager.app.setState({
          isNewCaster: false,
        })

        if (!status) {
          // Reset loading state
          window.dispatchEvent(new CustomEvent('CasterStateLoading', { detail: { loading: false } }))
        }

        if (hasChanges || forceLoading) {
          setLoadingStatus(status)
        }

        if (ready === 'loadingReact') {
          IpcManager.external.send('loadingReactReady')
        }

        if (ready === 'loadingFileOpen') {
          if (isLoadingNewCaster) {
            IpcManager.app.setState({
              isNewCaster: true,
            })

            setTimeout(() => {
              IpcManager.app.setState({
                isNewCaster: false,
              })
            }, 2000)
          }

          IpcManager.both.send('loadingFileOpenReady', isLoadingNewCaster)
        }
      },
    )

    IpcManager.both.on('setLoadingStatus', (_event: string, status: boolean, save: boolean) => {
      setLoadingStatus(status)

      if (save) {
        setTimeout(() => {
          setLoadingStatus(false)
        }, 1000)
      }
    })

    IpcManager.both.on('updateSavePath', (_event: string, savePath: string) => {
      setUserSettings({ savePath })
    })

    IpcManager.both.on('reset', () => {
      resetHasChanges()
    })

    // Visualization
    IpcManager.both.on('setLoading', (_event: string, loadingStatus: boolean, type: string) => {
      setLoadingStatus(loadingStatus)

      if (type && loadingStatus) {
        setTimeout(() => {
          IpcManager.both.send(type)
        }, 500)
      }
    })

    IpcManager.both.on('VisualizationData', (_event: string, data: any) => {
      const { setVisualizationData } = IpcManager.props

      data.meta.forEach((metaObject: any) => {
        metaObject.id = `config_${Util.generateConfigHash(metaObject.group, metaObject.key)}`
      })

      setVisualizationData(data)
    })

    IpcManager.both.on('openPlotList', (_event: string) => {
      const { showPlotList } = IpcManager.props

      showPlotList()
    })

    IpcManager.both.on('openSelectSourceDialog', (_event: string) => {
      const { openSelectSourceDialog, setAppState } = IpcManager.props

      openSelectSourceDialog()
      setAppState(AppState.ResultDashboard)
    })

    IpcManager.both.on('openPlotExportDialog', (_event: string) => {
      const { openPlotExportDialog, setAppState } = IpcManager.props

      setAppState(AppState.Caster) // TODO: toggle???
      openPlotExportDialog()
    })

    IpcManager.both.on('handleDashboard', (_event: string) => {
      const { setAppState } = IpcManager.props

      setAppState(AppState.Caster) // TODO: toggle???
    })

    IpcManager.both.on('getPlotVisDataElectron', () => {
      const { viewsObject, plotConfigs, tileConfigs, darkTheme } = IpcManager.props
      const saveObject = { viewsObject, plotConfigs, tileConfigs, darkTheme }

      IpcManager.electron.send('saveVisualizationConfigFile', JSON.stringify(saveObject))
    })

    IpcManager.both.on('setViewsObject', (_event: string, newDataObjectRaw: any) => {
      const { setConfig, setTheme } = IpcManager.props
      const newDataObject = typeof newDataObjectRaw === 'object' ? newDataObjectRaw : JSON.parse(newDataObjectRaw)
      const data = {
        viewsObject: newDataObject.viewsObject ?? {},
        tileConfigs: newDataObject.tileConfigs ?? {},
        plotConfigs: newDataObject.plotConfigs ?? {},
      }

      setConfig({ data } as VisualizationConfig, false)

      setTheme(newDataObject.darkTheme)
    })

    IpcManager.both.on('addHdf5Schema', (_event: string, newHdf5SchemaPath: string) => {
      const { addHdf5Schema } = IpcManager.props

      addHdf5Schema(newHdf5SchemaPath)
    })

    IpcManager.both.on('noSchemaFileSync', (_event: string, nonExistentPath: string) => {
      const { setHdf5Schema, removeHdf5Schema } = IpcManager.props

      // TODO: Display warning
      setHdf5Schema('default')
      removeHdf5Schema(nonExistentPath)
    })

    IpcManager.both.on('openProjectDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(OpenProjectDialog.NAME)
    })

    IpcManager.both.on('uploadCaster', (_event: string) => {
      const { setLoadingStatus } = IpcManager.props

      // set timeout only to allow the menu option to close before the dialog opens
      // with 50ms it still happens sometimes
      setTimeout(() => {
        CasterColumnLogic.handleUploadCasterData(
          'replaceCaster',
          IpcManager.props as any,
          true,
          setLoadingStatus,
        )
      }, 100)
    })

    IpcManager.both.on('uploadCatalog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(SelectCatalogDialog.NAME)
    })

    IpcManager.both.on('loadCaster', (_event: string) => {
      CasterColumnLogic.openCaster(IpcManager.props as any)
    })

    IpcManager.both.on('openLoginDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(LoginDialog.NAME)
    })

    IpcManager.both.on('openNewProjectDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(NewProjectDialog.NAME)
    })

    IpcManager.both.on('openManageDynamicDataSourcesDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(ManageDynamicDataSourcesDialog.NAME)
    })

    IpcManager.both.on('openManageExternalDataSourcesDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(ManageExternalDataSourcesDialog.NAME)
    })

    IpcManager.both.on(
      'updateSegmentGroupMountLogs',
      (segmentGroupMountLogLikeHash: Record<string, Record<string, any>>) => {
        const { updateElementMaps, addDirtyPath } = IpcManager.props
        const elementMaps = getElementMapsObject(IpcManager.props)
        const newElementMaps = cloneDeep(elementMaps)
        const dirtyPaths: string[] = []

        for (const segmentGroupMountLogId in segmentGroupMountLogLikeHash) {
          const segmentGroupMountLog = newElementMaps.SegmentGroupMountLog[segmentGroupMountLogId]

          if (!segmentGroupMountLog) {
            continue
          }

          const newSegmentGroupMountLog = segmentGroupMountLogLikeHash[segmentGroupMountLogId]

          for (const key in newSegmentGroupMountLog) {
            // TODO: get rid of any
            ;(segmentGroupMountLog as any)[key] = newSegmentGroupMountLog[key as keyof SegmentGroupMountLog]
          }

          const numericId = Mapping.numericIdByMountLogId[segmentGroupMountLogId]

          dirtyPaths.push(`SegmentGroup:${numericId}`)
        }

        updateElementMaps(newElementMaps)
        addDirtyPath(dirtyPaths)
      },
    )

    IpcManager.both.on('resetAllShim', async () => {
      const projectId = IpcManager.props.currentProject?.id
      const caseId = IpcManager.props.currentSimulationCase?.id
      const casterId = IpcManager.props.currentSimulationCase?.currentCasterId

      if (!casterId) {
        return
      }

      const {
        updateElementMaps,
        addDirtyPath,
        selectedPaths,
        setElements,
      } = IpcManager.props

      const elementMaps = getElementMapsObject(IpcManager.props)

      enqueueSnackbar('Resetting all shim values ...', { variant: 'info', autoHideDuration: 4000 })

      // rollerBodySensorPointMountLogs should be an array with all rollerBodySensorPointMountLogs
      // that have the AlignDevMMPrediction key, and these are the sensorPoints with name: `RGC-Angle${index}`
      // name examples: RGC-Angle1, RGC-Angle2, RGC-Angle3...
      const rollerBodySensorPointMountLogs: RollerBodySensorPointMountLog[] = []
      const rollerBodySensorPointSlotMap: RollerBodySensorPointSlotMap = {}

      Object.values(elementMaps.RollerBodySensorPointMountLog).forEach((mountLog) => {
        if (!mountLog.slotId) {
          return
        }

        if (
          (mountLog?.name ?? '').includes('RGC-Angle') &&
          mountLog.additionalData?.['AlignDevMMPrediction'] !== null &&
          mountLog.additionalData?.['AlignDevMMPrediction'] !== undefined &&
          elementMaps.RollerBodySensorPointSlot[mountLog.slotId]
        ) {
          rollerBodySensorPointMountLogs.push(mountLog)
          rollerBodySensorPointSlotMap[mountLog.slotId] = elementMaps.RollerBodySensorPointSlot[mountLog.slotId]!
        }
      })

      const response = await request<{
        SegmentGroupMountLog: SegmentGroupMountLogMap
        SupportPointMountLog: SupportPointMountLogMap
        RollerBodySensorPointMountLog: RollerBodySensorPointMountLogMap
        RollerBodySensorPointSlot: RollerBodyDataPointSlotMap
        SupportPointSlot: SupportPointSlotMap
        Caster: Caster
      }>(
        'post',
        `shim/reset-all/${projectId}/${caseId}/${casterId}`,
        'Could not reset shim',
        {
          data: {
            rollerBodySensorPointMountLogs,
            sensorPoints: rollerBodySensorPointSlotMap,
          },
        },
      )

      if (!response) {
        return
      }

      const { SegmentGroupMountLog, RollerBodySensorPointMountLog, RollerBodySensorPointSlot, ...restResponse } =
        response

      // TODO: we cannot call this atm because the chnages would be overwritten by the next update below
      // IpcManager.both.send('updateSegmentGroupMountLogs', SegmentGroupMountLog)
      const newElementMaps = cloneDeep(elementMaps)
      const dirtyPaths: string[] = []

      newElementMaps.Caster = response.Caster

      for (const segmentGroupMountLogId in SegmentGroupMountLog) {
        const segmentGroupMountLog = newElementMaps.SegmentGroupMountLog[segmentGroupMountLogId]

        if (!segmentGroupMountLog) {
          continue
        }

        const newSegmentGroupMountLog = SegmentGroupMountLog[segmentGroupMountLogId]

        for (const key in newSegmentGroupMountLog) {
          // TODO: get rid of any
          ;(segmentGroupMountLog as any)[key] = newSegmentGroupMountLog[key as keyof SegmentGroupMountLog]
        }

        const numericId = Mapping.numericIdByMountLogId[segmentGroupMountLogId]

        dirtyPaths.push(`SegmentGroup:${numericId}`)
      }

      // Update SupportPointMountLog and SupportPoint

      const oldNewMountLogMap = restResponse.SupportPointMountLog ?? {}
      const newElements = restResponse.SupportPointSlot ?? {}

      Object.keys(newElements).forEach(id => {
        if (newElements[id]) {
          newElementMaps.SupportPointSlot[id] = newElements[id]
        }
      })

      Object.entries(oldNewMountLogMap).forEach(([ oldId, newMountLog ]: any[]) => {
        const numericSegmentGroupId = Mapping.numericIdByMountLogId[newMountLog.segmentGroupMountLogId]
        const numericId = Mapping.numericIdByMountLogId[oldId]

        dirtyPaths.push(`SegmentGroup:${numericSegmentGroupId}/SupportPoint:${numericId}`)

        handleNewMountLog(newElementMaps, 'SupportPoint', 'SupportPointMountLog', newMountLog, oldId)
      })

      // update RollerBodySensorPointMountLog and SensorPoint
      for (const rollerBodySensorPointMountLogId in RollerBodySensorPointMountLog) {
        const rollerBodySensorPointMountLog = newElementMaps
          .RollerBodySensorPointMountLog[rollerBodySensorPointMountLogId]

        if (!rollerBodySensorPointMountLog) {
          continue
        }

        const newRollerBodySensorPointMountLog = RollerBodySensorPointMountLog[rollerBodySensorPointMountLogId]

        for (const key in newRollerBodySensorPointMountLog) {
          // eslint-disable-next-line max-len
          ;(rollerBodySensorPointMountLog as any)[key] =
            newRollerBodySensorPointMountLog[key as keyof RollerBodySensorPointMountLog]
        }

        const numericId = Mapping.numericIdByMountLogId[rollerBodySensorPointMountLogId] ?? -1

        if (numericId < 0 || !newRollerBodySensorPointMountLog) {
          continue
        }

        // delete map to old id
        delete Mapping.numericIdByMountLogId[rollerBodySensorPointMountLogId]

        // add map to new id
        Mapping.mountLogIdByTypeAndNumericId.SensorPoint[numericId] = newRollerBodySensorPointMountLog.id

        // add new id to numericId
        Mapping.numericIdByMountLogId[newRollerBodySensorPointMountLog.id] = numericId

        const fullPath = Mapping.elementPathByMountLogId[rollerBodySensorPointMountLogId]

        if (!fullPath) {
          continue
        }

        newElementMaps
          .RollerBodySensorPointMountLog[newRollerBodySensorPointMountLog.id] = newRollerBodySensorPointMountLog

        delete Mapping.elementPathByMountLogId[rollerBodySensorPointMountLogId]

        dirtyPaths.push(fullPath)
      }

      Object.values(RollerBodySensorPointSlot).forEach((sensorPoint) => {
        newElementMaps.RollerBodySensorPointSlot[sensorPoint.id] = sensorPoint
      })

      updateElementMaps(newElementMaps)
      addDirtyPath(dirtyPaths)

      enqueueSnackbar('Shim values reset successfully', { variant: 'success', autoHideDuration: 4000 })

      if (SetUtil.some(selectedPaths, (path) => path.includes('SensorPoint'))) {
        const sensorPointPaths = Array.from(SetUtil.filter(selectedPaths, (path) => path.includes('SensorPoint')))

        setElements(sensorPointPaths, undefined, true)
      }

      if (SetUtil.some(selectedPaths, (path) => path.includes('SupportPoint'))) {
        const supportPointPaths = Array.from(SetUtil.filter(selectedPaths, (path) => path.includes('SupportPoint')))

        setElements(supportPointPaths, undefined, true)
      }

      if (SetUtil.some(selectedPaths, (path) => path.includes('SegmentGroup'))) {
        const supportPointPaths: string[] = []
        const mountLogIds = new Set<string>()

        for (const path of selectedPaths) {
          if (!path.includes('SegmentGroup')) {
            continue
          }

          const numericId = Number(path.substring(path.indexOf(':') + 1))
          const mountLogId = Mapping.mountLogIdByTypeAndNumericId.SegmentGroup[numericId]

          if (mountLogId) {
            mountLogIds.add(mountLogId)
          }
        }

        Object.values(newElementMaps.SupportPointMountLog).forEach((mountLog) => {
          if (!mountLog.segmentGroupMountLogId || !mountLogIds.has(mountLog.segmentGroupMountLogId)) {
            return
          }

          const parentNumericId = Mapping.numericIdByMountLogId[mountLog.segmentGroupMountLogId]
          const numericId = Mapping.numericIdByMountLogId[mountLog.id]

          if (Number.isNaN(parentNumericId) || Number.isNaN(numericId)) {
            return
          }

          supportPointPaths.push(`SegmentGroup:${parentNumericId}/SupportPoint:${numericId}`)
        })

        if (supportPointPaths.length > 0) {
          setElements(supportPointPaths, undefined, true)
        }
      }
    })

    IpcManager.both.on('openCreateRealDataCaseDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(CreateRealDataCaseDialog.NAME)
    })

    IpcManager.both.on('openShareStateDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(ShareStateDialog.NAME)
    })

    IpcManager.both.on('openCreateRealDataDialog', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(CreateRealDataDialog.NAME)
    })

    IpcManager.both.on('sendCasterToConsistencyCheck', (_event: string) => {
      const { t } = IpcManager.props

      const simulationCaseId = IpcManager.props.currentSimulationCase.id

      IpcManager.send('sendToConsistencyCheck', simulationCaseId, enqueueSnackbar, t)
    })

    IpcManager.both.on('openSelectDashboard', (_event: string) => {
      const { openDialog } = IpcManager.props

      openDialog(SelectDashboardDialog.NAME)
    })

    IpcManager.both.on('openExecutableDialog', (definition: ExecutableDefinition) => {
      const { openDialog } = IpcManager.props

      openDialog(ExecutableDialog.NAME, { definition })
    })

    IpcManager.both.on('openDownloadXMLDialog', (definition: ExecutableDefinition) => {
      const { openDialog } = IpcManager.props

      openDialog(DownloadXMLDialog.NAME, { definition })
    })

    IpcManager.both.on('saveCasterXML', () => {
      const { t, currentSimulationCase } = IpcManager.props

      if (!currentSimulationCase) {
        return
      }

      if (window.isElectron) {
        enqueueSnackbar('Download initiated...', { variant: 'info', autoHideDuration: 3000 })
      }

      ApiClient
        .get(`${useConfig().apiBaseURL}/export/caster-xml/${currentSimulationCase.id}`)
        .then(async (data) => {
          await DownloadUtil.openDownloadFileDialog(data, 'caster.xml')

          if (!window.isElectron) {
            enqueueSnackbar(t('download.successful'), { variant: 'success', autoHideDuration: 3000 })
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error)

          enqueueSnackbar(t('download.failed'), { variant: 'error' })
        })
    })

    IpcManager.internal.on('saveVisualizationConfig', (data: any, name: string) => {
      const { t } = IpcManager.props

      if (window.isElectron) {
        enqueueSnackbar('Download initiated...', { variant: 'info', autoHideDuration: 3000 })
      }

      DownloadUtil
        .openDownloadFileDialog(data, name ?? 'visualization_config.json')
        .then(() => {
          if (!window.isElectron) {
            enqueueSnackbar(t('download.successful'), { variant: 'success', autoHideDuration: 3000 })
          }
        })
        .catch(() => {
          enqueueSnackbar(t('download.failed'), { variant: 'error' })
        })
    })

    IpcManager.internal.on(
      'loadCurrentCaster',
      async ({
        simulationCase,
        caseId,
        configIdFromExec,
        tab,
        info,
        selection,
        skipVisualization,
        configNotFoundError,
        setSelectionCallback,
      }: {
        simulationCase: SimulationCase | null
        caseId: string | null
        configIdFromExec?: string
        tab?: number
        info?: RecentlyUsedInfo
        selection?: string
        skipVisualization?: boolean
        configNotFoundError?: boolean
        setSelectionCallback?: (selection: string) => void
      }) => {
        const {
          setAppState,
          setError,
          currentSimulationCase,
          authenticationData,
          setConfig,
          setCasterDashboardTabIndex,
          setVisualizationMetaInformation,
          appState,
          currentProject,
          // addComparisonCaster,
          saveCatalog,
          updateElementMaps,
          updateModules,
          setFileUploadLoadingStatus,
          setMountLogToKeyUUIDsMap,
          updateCaseCurrentCasterId,
        } = IpcManager.props

        setError('CasterData')
        setFileUploadLoadingStatus('CasterDataIsLoading', false)
        setAppState(AppState.Caster)

        IpcManager.both.send('loading', null, true, true)

        let start = performance.now()

        const caseIdToUse = simulationCase?.id ?? caseId
        // TODO: this takes about 900ms
        const res = caseIdToUse ? await getCurrentCaster(caseIdToUse) : null

        // eslint-disable-next-line no-console
        console.log('getCurrentCaster', performance.now() - start)

        if (!res?.caster) {
          IpcManager.both.send('loading', null, false, true)

          ThreeManager.cleanViews([ 'MainView', 'SectionView' ])

          return
        }

        updateCaseCurrentCasterId(res.caster.id)

        Info.setRecentlyUsedInfo({ projectId: res.caster.projectId, simulationCaseId: res.caster.caseId })

        const elementMaps: ElementMaps = {
          Caster: res.caster,
          MoldSlot: res.moldSlots,
          MoldMountLog: res.moldMountLogs,
          MoldBCAreaSlot: res.moldBCAreaSlots,
          MoldBCAreaMountLog: res.moldBCAreaMountLogs,
          MoldFaceSlot: res.moldFaceSlots,
          MoldFaceMountLog: res.moldFaceMountLogs,
          PasslineSlot: res.passlineSlots,
          PasslineMountLog: res.passlineMountLogs,
          PasslineSectionSlot: res.passlineSectionSlots,
          PasslineSectionMountLog: res.passlineSectionMountLogs,
          StrandGuideSlot: res.strandGuideSlots,
          StrandGuideMountLog: res.strandGuideMountLogs,
          SegmentGroupSlot: res.segmentGroupSlots,
          SegmentGroupMountLog: res.segmentGroupMountLogs,
          SegmentSlot: res.segmentSlots,
          SegmentMountLog: res.segmentMountLogs,
          RollerSlot: res.rollerSlots,
          RollerMountLog: res.rollerMountLogs,
          RollerBodySlot: res.rollerBodySlots,
          RollerBodyMountLog: res.rollerBodyMountLogs,
          RollerBearingSlot: res.rollerBearingSlots,
          RollerBearingMountLog: res.rollerBearingMountLogs,
          NozzleSlot: res.nozzleSlots,
          NozzleMountLog: res.nozzleMountLogs,
          CoolingLoopSlot: res.coolingLoopSlots,
          CoolingLoopMountLog: res.coolingLoopMountLogs,
          AirLoopSlot: res.airLoopSlots,
          AirLoopMountLog: res.airLoopMountLogs,
          CoolingZoneSlot: res.coolingZoneSlots,
          CoolingZoneMountLog: res.coolingZoneMountLogs,
          LoopAssignmentSlot: res.loopAssignmentSlots,
          LoopAssignmentMountLog: res.loopAssignmentMountLogs,
          SupportPointSlot: res.supportPointSlots,
          SupportPointMountLog: res.supportPointMountLogs,
          DataLineSlot: res.dataLineSlots,
          DataLineMountLog: res.dataLineMountLogs,
          MoldFaceDataPointSlot: res.moldFaceDataPointSlots,
          MoldFaceDataPointMountLog: res.moldFaceDataPointMountLogs,
          StrandDataPointSlot: res.strandDataPointSlots,
          StrandDataPointMountLog: res.strandDataPointMountLogs,
          SegmentDataPointSlot: res.segmentDataPointSlots,
          SegmentDataPointMountLog: res.segmentDataPointMountLogs,
          RollerDataPointSlot: res.rollerDataPointSlots,
          RollerDataPointMountLog: res.rollerDataPointMountLogs,
          RollerBodyDataPointSlot: res.rollerBodyDataPointSlots,
          RollerBodyDataPointMountLog: res.rollerBodyDataPointMountLogs,
          SegmentSensorPointSlot: res.segmentSensorPointSlots,
          SegmentSensorPointMountLog: res.segmentSensorPointMountLogs,
          RollerSensorPointSlot: res.rollerSensorPointSlots,
          RollerSensorPointMountLog: res.rollerSensorPointMountLogs,
          RollerBodySensorPointSlot: res.rollerBodySensorPointSlots,
          RollerBodySensorPointMountLog: res.rollerBodySensorPointMountLogs,
          CCElement: res.ccElements,
          ChangeItem: res.changeItems,
        }

        const currentDataLineMountLogs = ElementsUtil.getDataLineMountLogs(elementMaps)

        linkDataLinesWithElements(elementMaps, currentDataLineMountLogs)

        const mountLogsToKeyUUIDsMap = buildElementMountLogsToKeyUUIDsMap(elementMaps)

        setMountLogToKeyUUIDsMap(mountLogsToKeyUUIDsMap)

        start = performance.now()

        // TODO: this takes about 200ms
        updateElementMaps(elementMaps)

        // eslint-disable-next-line no-console
        console.log('updateElementMaps', performance.now() - start)

        start = performance.now()

        // TODO: this takes about 200ms
        updateModules(elementMaps)

        // eslint-disable-next-line no-console
        console.log('updateModules', performance.now() - start)

        setTimeout(async () => {
          IpcManager.internal.send(
            'data',
            {
              path: null,
              info,
            },
          )

          if (selection) {
            setSelectionCallback?.(selection)
          }

          const defaultConfigId = (simulationCase ?? currentSimulationCase).defaultVisualizationConfigId ??
            authenticationData.defaultCasterDashboardConfig?.id

          const dashboardConfigId = configIdFromExec ?? defaultConfigId
          const tabIndex = String(tab ?? 1)

          if (configNotFoundError) {
            enqueueSnackbar('Dashboard specified in executable definition not found', { variant: 'error' })
          }
          else if (dashboardConfigId && !skipVisualization) {
            // we need to get featureFlags here, because they are undefined in the props above on reload
            const { featureFlags } = IpcManager.props

            await CasterColumnLogic
              .handleLoadCasterDashboardConfigById(
                {
                  featureFlags,
                  setConfig,
                  setCasterDashboardTabIndex,
                  setVisualizationMetaInformation,
                  appState,
                  authenticationData,
                },
                dashboardConfigId ?? defaultConfigId,
                tabIndex,
              )
          }
        }, 100)

        // timeout to avoid reset_all action
        setTimeout(async () => {
          const casterCatalogId = (simulationCase ?? currentSimulationCase)?.loadedNozzleCatalogId

          if (casterCatalogId) {
            const csvString = await getNozzleCatalogContents(
              simulationCase?.projectId ?? currentProject?.id,
              (simulationCase ?? currentSimulationCase).id,
              casterCatalogId,
            )

            if (!csvString) {
              return
            }

            const catalogData = await Logic.getParsedCsv(csvString)

            saveCatalog(catalogData, casterCatalogId)
            setLastLoadedCasterCatalogId(casterCatalogId)
          }
        }, 1000)
      },
    )

    IpcManager.internal.on(
      'loadCasterAtDate',
      async ({
        caseId,
        date,
      }: {
        caseId: string
        date: Date
      }) => {
        const {
          setAppState,
          setError,
          updateMountLogs,
          updateModules,
          setFileUploadLoadingStatus,
          setMountLogToKeyUUIDsMap,
          updateCaseCurrentCasterId,
        } = IpcManager.props

        setError('CasterData')
        setFileUploadLoadingStatus('CasterDataIsLoading', false)
        setAppState(AppState.Caster)

        // IpcManager.both.send('loading', null, true, true)

        let start = performance.now()

        const res = await getCasterAtTime(caseId, date)

        // eslint-disable-next-line no-console
        console.log('getCasterAtTime', performance.now() - start)

        if (!res?.caster) {
          IpcManager.both.send('loading', null, false, true)

          ThreeManager.cleanViews([ 'MainView', 'SectionView' ])

          return
        }

        updateCaseCurrentCasterId(res.caster.id)

        Info.setRecentlyUsedInfo({ projectId: res.caster.projectId, simulationCaseId: res.caster.caseId })

        const currMaps = getElementMapsObject(IpcManager.props)

        const elementMaps: ElementMaps = {
          Caster: res.caster,
          MoldSlot: currMaps.MoldSlot,
          MoldMountLog: res.moldMountLogs,
          MoldBCAreaSlot: currMaps.MoldBCAreaSlot,
          MoldBCAreaMountLog: res.moldBCAreaMountLogs,
          MoldFaceSlot: currMaps.MoldFaceSlot,
          MoldFaceMountLog: res.moldFaceMountLogs,
          PasslineSlot: currMaps.PasslineSlot,
          PasslineMountLog: res.passlineMountLogs,
          PasslineSectionSlot: currMaps.PasslineSectionSlot,
          PasslineSectionMountLog: res.passlineSectionMountLogs,
          StrandGuideSlot: currMaps.StrandGuideSlot,
          StrandGuideMountLog: res.strandGuideMountLogs,
          SegmentGroupSlot: currMaps.SegmentGroupSlot,
          SegmentGroupMountLog: res.segmentGroupMountLogs,
          SegmentSlot: currMaps.SegmentSlot,
          SegmentMountLog: res.segmentMountLogs,
          RollerSlot: currMaps.RollerSlot,
          RollerMountLog: res.rollerMountLogs,
          RollerBodySlot: currMaps.RollerBodySlot,
          RollerBodyMountLog: res.rollerBodyMountLogs,
          RollerBearingSlot: currMaps.RollerBearingSlot,
          RollerBearingMountLog: res.rollerBearingMountLogs,
          NozzleSlot: currMaps.NozzleSlot,
          NozzleMountLog: res.nozzleMountLogs,
          CoolingLoopSlot: currMaps.CoolingLoopSlot,
          CoolingLoopMountLog: res.coolingLoopMountLogs,
          AirLoopSlot: currMaps.AirLoopSlot,
          AirLoopMountLog: res.airLoopMountLogs,
          CoolingZoneSlot: currMaps.CoolingZoneSlot,
          CoolingZoneMountLog: res.coolingZoneMountLogs,
          LoopAssignmentSlot: currMaps.LoopAssignmentSlot,
          LoopAssignmentMountLog: res.loopAssignmentMountLogs,
          SupportPointSlot: currMaps.SupportPointSlot,
          SupportPointMountLog: res.supportPointMountLogs,
          DataLineSlot: currMaps.DataLineSlot,
          DataLineMountLog: res.dataLineMountLogs,
          MoldFaceDataPointSlot: currMaps.MoldFaceDataPointSlot,
          MoldFaceDataPointMountLog: res.moldFaceDataPointMountLogs,
          StrandDataPointSlot: currMaps.StrandDataPointSlot,
          StrandDataPointMountLog: res.strandDataPointMountLogs,
          SegmentDataPointSlot: currMaps.SegmentDataPointSlot,
          SegmentDataPointMountLog: res.segmentDataPointMountLogs,
          RollerDataPointSlot: currMaps.RollerDataPointSlot,
          RollerDataPointMountLog: res.rollerDataPointMountLogs,
          RollerBodyDataPointSlot: currMaps.RollerBodyDataPointSlot,
          RollerBodyDataPointMountLog: res.rollerBodyDataPointMountLogs,
          SegmentSensorPointSlot: currMaps.SegmentSensorPointSlot,
          SegmentSensorPointMountLog: res.segmentSensorPointMountLogs,
          RollerSensorPointSlot: currMaps.RollerSensorPointSlot,
          RollerSensorPointMountLog: res.rollerSensorPointMountLogs,
          RollerBodySensorPointSlot: currMaps.RollerBodySensorPointSlot,
          RollerBodySensorPointMountLog: res.rollerBodySensorPointMountLogs,
          CCElement: res.ccElements,
          ChangeItem: res.changeItems,
        }

        const currentDataLineMountLogs = ElementsUtil.getDataLineMountLogs(elementMaps)

        linkDataLinesWithElements(elementMaps, currentDataLineMountLogs)

        const mountLogsToKeyUUIDsMap = buildElementMountLogsToKeyUUIDsMap(elementMaps)

        setMountLogToKeyUUIDsMap(mountLogsToKeyUUIDsMap)

        Object.keys(elementMaps).forEach((key) => {
          if (key.endsWith('Slot')) {
            delete (elementMaps as any)[key]
          }
        })

        start = performance.now()

        updateMountLogs(elementMaps)

        // eslint-disable-next-line no-console
        console.log('updateMountLogs', performance.now() - start)

        start = performance.now()

        updateModules(elementMaps)

        // eslint-disable-next-line no-console
        console.log('updateModules', performance.now() - start)

        setAppState(AppState.Caster)

        IpcManager.internal.send(
          'data',
          {
            path: null,
            keepFilter: true,
          },
        )
      },
    )

    IpcManager.internal.on('openDashboard', async (
      { configId, tabIndex }: { configId: string, tabIndex: string },
    ) => {
      if (!configId) {
        return
      }

      const {
        authenticationData,
        setConfig,
        setCasterDashboardTabIndex,
        setVisualizationMetaInformation,
        appState,
        featureFlags,
      } = IpcManager.props

      await CasterColumnLogic.handleLoadCasterDashboardConfigById(
        {
          featureFlags,
          setConfig,
          setCasterDashboardTabIndex,
          setVisualizationMetaInformation,
          appState,
          authenticationData,
        },
        configId,
        tabIndex,
      )
    })

    IpcManager.internal.on('openSimulationDataDialog', (data: any, name: string) => {
      const { t } = IpcManager.props

      IpcManager.both.send('saveSimulationData', data, name, enqueueSnackbar, t)
    })

    IpcManager.internal.on('kill3D', (_event: string) => {
      setLoadingStatus(true)

      ThreeManager.base.unmount()
      ThreeManager.killBase()
      ThreeManager.reInit()
      ThreeManager.base.mount()
    })

    IpcManager.internal.on('toggleFullScreenReact', async () => {
      // prevent fullscreen on local development
      if (location.hostname?.endsWith('.local')) {
        return
      }

      if (window.toggleMaxRestoreButtons && window.isElectron) {
        IpcManager.external.send('toggleFullScreen')

        window.toggleMaxRestoreButtons()

        forceUpdate()

        return
      }

      const element = document.documentElement

      try {
        if (document.fullscreenEnabled && element.requestFullscreen) {
          await element.requestFullscreen()
        }
      }
      catch (error: any) {
        // ignore
      }
    })

    IpcManager.external.on('minimizeReact', () => {
      if (window.isElectron) {
        IpcManager.external.send('minimize')
      }
    })

    IpcManager.internal.on('exitFullScreenReact', () => {
      const { openAppDialogs } = this.props

      if (openAppDialogs.length > 0) {
        return
      }

      if (window.toggleMaxRestoreButtons && window.isElectron) {
        IpcManager.external.send('exitFullScreen')

        window.toggleMaxRestoreButtons()

        forceUpdate()

        return
      }

      if (document.fullscreenElement === null) {
        return
      }

      if (document.exitFullscreen) {
        document.exitFullscreen()
      }
      else if ((document as any).webkitExitFullscreen) {
        /* Safari */
        ;(document as any).webkitExitFullscreen()
      }
    })

    IpcManager.external.on('usageTimeUpdate', (_event: any, data: any) => {
      if (data.usageTimeExceeded) {
        const { openDialog, openAppDialogs } = IpcManager.props

        if (!openAppDialogs.includes(UsageTimeExceededDialog.NAME)) {
          openDialog(UsageTimeExceededDialog.NAME)
        }
      }
      else {
        const { closeDialog } = IpcManager.props

        closeDialog(UsageTimeExceededDialog.NAME)
      }

      window.usageTimeInfo = data
    })

    IpcManager.external.send('reactReady')
  }
}
