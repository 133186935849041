import cloneDeep from 'lodash/cloneDeep'

import { setLoadedNozzleCatalog } from '@/api/case'
import { NetworkStatus } from '@/api/network-event'
import { InputDataDialog } from '@/react/dialogs/project/InputDataDialog'
import { OpenProjectDialog } from '@/react/dialogs/project/OpenProjectDialog'
import { ProjectDataDialog } from '@/react/dialogs/project/ProjectDataDialog'
import { ProjectMatrixDialog } from '@/react/dialogs/ProjectMatrixDialog'

import { ApplicationMainActionsEnum as AppMainActions } from '../consts'

const setReducers = {
  [AppMainActions.ACTION_SET_LOADING_STATUS]: (
    state: ApplicationMainState,
    action: { type: string, status: boolean },
  ) => ({
    ...state,
    loadingStatus: action.status,
  }),

  [AppMainActions.ACTION_SET_REQUIRED_COMMAND_FILES]: (
    state: ApplicationMainState,
    { nonexistentCommandFiles, nonexistentCommandFileMapping, visualizationConfigId }: {
      nonexistentCommandFiles: { [key: string]: { fileName: string, commands: string[] } }
      nonexistentCommandFileMapping: string[]
      visualizationConfigId: string
    },
  ) => ({
    ...state,
    nonexistentCommandFiles,
    nonexistentCommandFileMapping,
    latestUploadedVisualizationConfigId: visualizationConfigId,
  }),

  [AppMainActions.ACTION_SET_CURRENT_PROJECT]: (
    state: ApplicationMainState,
    { currentProject }: { currentProject: Project },
  ) => ({
    ...state,
    currentProject,
  }),

  [AppMainActions.ACTION_SET_CURRENT_SIMULATION_CASE]: (
    state: ApplicationMainState,
    {
      currentSimulationCase,
      preventDefault,
    }: { currentSimulationCase: SimulationCase, preventDefault: boolean },
  ) => {
    const { currentProject } = state
    const updatedProject = cloneDeep(currentProject)

    // updatedProject.simulationCases = currentProject.simulationCases.map((sc:SimulationCase) =>
    //   sc._id === currentSimulationCase.id ? currentSimulationCase : sc)

    const additionalDialogs = []

    if (state.openAppDialogs.includes(InputDataDialog.NAME)) {
      additionalDialogs.push(InputDataDialog.NAME)
    }

    const matrixOpened = state.openAppDialogs.includes(ProjectMatrixDialog.NAME)

    return ({
      ...state,
      currentSimulationCase,
      currentProject: updatedProject,
      openAppDialogs: !currentSimulationCase || !currentSimulationCase.id
        // if no project is opened show OpenProjectDialog
        ? [ OpenProjectDialog.NAME ]
        // else
        : (!preventDefault && !matrixOpened && !currentSimulationCase.currentCasterId
          // FIXME: these do not exist on case anymore
          //  &&
          //   (!currentSimulationCase.visualizationConfigList?.length ||
          //        !currentSimulationCase.visualizationDataList?.length)
          // if there is no caster or vis data in the project show ProjectDataDialog
          ? [ ProjectDataDialog.NAME, ...additionalDialogs ]
          // else keep dialogs open
          : [ ...state.openAppDialogs ]),
    })
  },

  [AppMainActions.ACTION_SET_AUTHENTICATION_DATA]: (state: ApplicationMainState, { data }: { data: AuthData }) => {
    if (!data) {
      return {
        ...state,
        authenticationData: null,
      }
    }

    return {
      ...state,
      authenticationData: { ...data },
    }
  },

  [AppMainActions.ACTION_SET_THEME]: (state: ApplicationMainState, { darkTheme }: { darkTheme: boolean }) => ({
    ...state,
    darkTheme: darkTheme || !state.darkTheme,
  }),

  [AppMainActions.ACTION_SET_EDIT_PROJECT_ID]: (state: ApplicationMainState, { projectId }: { projectId: string }) => ({
    ...state,
    editProjectId: projectId,
  }),

  [AppMainActions.ACTION_SET_EDIT_SIMULATION_CASE_ID]: (
    state: ApplicationMainState,
    { simulationCaseId }: { simulationCaseId: string },
  ) => ({
    ...state,
    editSimulationCaseId: simulationCaseId,
  }),

  [AppMainActions.ACTION_SET_EDIT_CONFIG_ID]: (state: ApplicationMainState, { configId }: { configId: string }) => ({
    ...state,
    editConfigId: configId,
  }),

  [AppMainActions.ACTION_SET_APP_STATE]: (state: ApplicationMainState, { mode }: { mode: number }) => ({
    ...state,
    appState: mode,
  }),

  [AppMainActions.ACTION_SET_CASE_LOCKS]: (state: ApplicationMainState, { caseLocks }: { caseLocks: CaseLock[] }) => ({
    ...state,
    caseLocks,
  }),

  [AppMainActions.ACTION_SET_CASE_VERIFICATION]: (
    state: ApplicationMainState,
    { caseVerifications }: { caseVerifications: CaseVerification[] },
  ) => ({
    ...state,
    caseVerifications,
  }),

  [AppMainActions.ACTION_ADD_CASE_VERIFICATION]: (
    state: ApplicationMainState,
    { caseVerification }: { caseVerification: CaseVerification },
  ) => ({
    ...state,
    caseVerifications: [ ...state.caseVerifications, caseVerification ],
  }),

  [AppMainActions.ACTION_SET_DEFAULT_CASTER_DASHBOARD_CONFIG]: (
    state: ApplicationMainState,
    { defaultVisualizationConfigId }: { defaultVisualizationConfigId: number },
  ) => ({
    ...state,
    currentSimulationCase: { ...state.currentSimulationCase, defaultVisualizationConfigId },
  }),

  [AppMainActions.ACTION_SET_LAST_LOADED_CASTER_CATALOG_ID]: (
    state: ApplicationMainState,
    { loadedNozzleCatalogId }: { loadedNozzleCatalogId: string },
  ) => {
    setLoadedNozzleCatalog(state.currentSimulationCase.id, loadedNozzleCatalogId)
      .catch(() => {})

    return {
      ...state,
      currentSimulationCase: { ...state.currentSimulationCase, loadedNozzleCatalogId },
    }
  },

  [AppMainActions.ACTION_SET_CURRENT_PROJECT_CASES_METADATA]: (
    state: ApplicationMainState,
    { currentProjectCasesMetadata }: { currentProjectCasesMetadata: { id: string, createdAt: Date }[] },
  ) => ({
    ...state,
    currentProjectCasesMetadata,
  }),

  [AppMainActions.ACTION_RESET_CURRENT_PROJECT_CASES_METADATA]: (state: ApplicationMainState) => ({
    ...state,
    currentProjectCasesMetadata: [],
  }),

  [AppMainActions.ACTION_DELETE_ID_FROM_CURRENT_PROJECT_CASE_IDS]: (
    state: ApplicationMainState,
    { id }: { id: string },
  ) => ({
    ...state,
    currentProjectCasesMetadata: state
      .currentProjectCasesMetadata
      .filter(metadata => metadata.id !== id),
  }),

  [AppMainActions.ACTION_ADD_ID_TO_CURRENT_PROJECT_CASE_IDS]: (
    state: ApplicationMainState,
    { metadata }: { metadata: { id: string, createdAt: Date } },
  ) => {
    // to avoid duplicates, remove the metadata with the same id
    const cleanedMetadata = state
      .currentProjectCasesMetadata
      .filter(m => m.id !== metadata.id)

    return {
      ...state,
      currentProjectCasesMetadata: [ ...cleanedMetadata, metadata ],
    }
  },

  [AppMainActions.ACTION_STORE_NETWORK_STATUS]: (
    state: ApplicationMainState,
    { status }: { status: NetworkStatus },
  ) => ({
    ...state,
    networkStatus: status,
  }),

  [AppMainActions.ACTION_SET_ACTIVE_DETAIL_DIALOG_FILTER]: (
    state: ApplicationMainState,
    { elementHistoryDetailsInfo }: { elementHistoryDetailsInfo: { segmentGroupMountLogId: string, filter: string } },
  ) => ({
    ...state,
    elementHistoryDetailsInfo,
  }),

  [AppMainActions.ACTION_RESET_ACTIVE_DETAIL_DIALOG_FILTER]: (
    state: ApplicationMainState,
  ) => ({
    ...state,
    elementHistoryDetailsInfo: null,
  }),

  [AppMainActions.ACTION_RENAME_CURRENT_PROJECT]: (
    state: ApplicationMainState,
    { name }: { name: string },
  ) => ({
    ...state,
    currentProject: { ...state.currentProject, name },
  }),

  [AppMainActions.ACTION_RENAME_CURRENT_SIMULATION_CASE]: (
    state: ApplicationMainState,
    { name }: { name: string },
  ) => ({
    ...state,
    currentSimulationCase: { ...state.currentSimulationCase, name },
  }),
}

export default setReducers
