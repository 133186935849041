import { Component } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { ThemeProvider } from 'styled-components'

import type { DefaultState } from '@/types/state'

import { Content, Dialog, DialogBackground, Header, HeaderTitle, I } from './DialogStyles'
import StyleConfig from '../visualization/dashboard/config/StyleConfig'
import { Spinner } from '../visualization/PlotWrapper/EditBoxWrapper/styles'

const connector = connect((state: DefaultState) => ({
  darkTheme: state.application.main.darkTheme,
}))

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  id?: string
  title: string
  icon: string
  header: string
  children: any
  contentMinHeight?: number
  darkTheme: boolean
  hideCloseButton: boolean
  small?: boolean
  medium?: boolean
  fullWidth?: boolean
  headerWidth?: string
  hasScroll?: boolean
  loading?: boolean
  onClose: (e: any) => void
  t(key: string, params?: Record<string, unknown>): string
}

class BaseDialog extends Component<Props & WithTranslation> {
  private readonly handleMouseEvent = (event: any) => {
    event.stopPropagation()
  }
  
  public override render () {
    const {
      id,
      t,
      icon,
      title,
      header,
      children,
      small,
      medium,
      fullWidth,
      headerWidth,
      hasScroll,
      darkTheme,
      hideCloseButton,
      contentMinHeight,
      loading,
      onClose: handleClose,
    } = this.props

    return (
      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
        <div>
          <DialogBackground
            onClick={this.handleMouseEvent}
            onMouseDown={this.handleMouseEvent}
            onMouseUp={this.handleMouseEvent}
            onMouseMove={this.handleMouseEvent}
          />
          <Dialog id={id} $small={small} $medium={medium} $fullWidth={fullWidth}>
            <div>
              <Header title={title}>
                <I className={icon} $left />
                <HeaderTitle $headerWidth={headerWidth}>{header}</HeaderTitle>
                {
                  !hideCloseButton && (
                    <I
                      className='pe-7s-close'
                      $clickable
                      onClick={handleClose}
                      title={t('baseDialog.close')}
                      id='close-dialog'
                    />
                  )
                }
              </Header>
              <Content $minHeight={contentMinHeight} $hasScroll={hasScroll}>
                {
                  loading 
                    ? (
                      <Spinner />
                    )
                    : children
                }
              </Content>
            </div>
          </Dialog>
        </div>
      </ThemeProvider>
    )
  }
}

export default compose<any>(withTranslation('application'), connector)(BaseDialog)
