import * as THREE from 'three'
import { v4 as uuid } from 'uuid'

import Util from '@/three/logic/Util'

import BaseView from '../views/BaseView'

export type XAnchor = 'center' | 'left' | 'right'

export type YAnchor = 'bottom' | 'center' | 'top'

export abstract class UIComponent {
  protected readonly view: BaseView

  public readonly id: string

  public group: THREE.Group

  public constructor (view: BaseView) {
    this.view = view
    this.id = uuid()
    this.group = new THREE.Group()

    this.group.userData['id'] = this.id
  }

  public draw (_group: THREE.Group) {}

  public init () {
    const group = new THREE.Group()

    group.visible = this.group.visible

    group.userData['id'] = this.id

    this.draw(group)

    this.view.scene.remove(this.group)

    this.group = group

    this.view.scene.add(this.group)
  }

  public resize () {
    this.init()
  }

  public update () {
    this.init()
  }

  public animate (_elapsed: number) {}

  public handleEvent (_event: string, _payload: { object: THREE.Object3D | null, data: any }) {}

  public dispose () {
    if (!this.group.parent) {
      return
    }

    this.group.parent.remove(this.group)
  }

  protected getX (anchor: XAnchor, x: number) {
    if (anchor === 'center') {
      return this.view.viewport.width / 2 + x
    }
    else if (anchor === 'left') {
      return x
    }
    else if (anchor === 'right') {
      return this.view.viewport.width - x
    }

    return 0
  }

  protected getY (anchor: YAnchor, y: number) {
    if (anchor === 'center') {
      return this.view.viewport.height / 2 + y
    }
    else if (anchor === 'top') {
      return this.view.viewport.height - y
    }
    else if (anchor === 'bottom') {
      return y
    }

    return 0
  }

  protected getPosition (width: number, height: number, x: number, y: number, viewport: ViewPort) {
    return new THREE.Vector3(width / 2 - viewport.width / 2 + x, height / 2 - viewport.height / 2 + y, 0)
  }

  protected drawPlane (
    group: THREE.Group,
    name: string,
    x: number,
    y: number,
    width: number,
    height: number,
    material: THREE.MeshBasicMaterial,
    clickableObjects?: THREE.Object3D[],
  ) {
    const barGeometry = new THREE.PlaneGeometry(width, height, 1)
    const barMesh = new THREE.Mesh(barGeometry, material)

    barMesh.position.copy(this.getPosition(width, height, x, y, this.view.viewport))
    barMesh.name = name

    Util.addOrReplace(group, barMesh, clickableObjects)
  }
}
