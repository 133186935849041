import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import { getCasesByProjectId } from '@/api/case'
import { getVisualizationConfigById } from '@/api/visualization-config'
import { AppState } from '@/store/application/main/consts'
import * as VisualizationActions from '@/store/visualization/actions'
import type { DefaultState } from '@/types/state'

import FeatureFlags from './FeatureFlags'
import TabPage from './specific/Tabs/TabPage'
import TabView from './specific/Tabs/TabView'
import SimpleDashboard from './visualization/dashboard/SimpleDashboard'

const IconWrapper = styled.div`
  padding-left: 5px;
`

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  casterDashboardTabIndex: state.visualization.casterDashboardTabIndex,
  currentProject: state.application.main.currentProject,
  openDialogs: state.application.main.openDialogs,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  viewsObject: state.visualization.viewsObject,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  caster: state.Caster,
  currentSimulationCase: state.application.main.currentSimulationCase, 
}), {
  setConfig: VisualizationActions.setConfig,
  setDashboardObject: VisualizationActions.setDashboardObject,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  defaultContent: ReactNode
  t(key: string, params?: Record<string, unknown>): string
}

export type CaseInfo = {
  name: string
  id: string
  caster: Caster | null
}

type State = {
  loading: boolean
  casesInfo: CaseInfo[]
}

export class CasterTree extends Component<Props, State> {
  public override state: State = {
    loading: false,
    casesInfo: [],
  }

  public override componentDidMount () {
    this.updateCasesInfo()
  }

  public override componentDidUpdate (prevProps: Props) {
    const { caster: prevCaster } = prevProps
    const { appState, casterDashboardTabIndex, caster, currentSimulationCase } = this.props

    if (prevProps.casterDashboardTabIndex !== casterDashboardTabIndex) {
      this.handleTabClick(casterDashboardTabIndex)
    }

    if (prevProps.appState !== appState && appState === AppState.Caster) {
      this.loadTabs()
    }

    if (
      !isEqual(prevCaster?.additionalData?.XMLDataBase, caster?.additionalData?.XMLDataBase) ||
      prevProps.currentSimulationCase?.id !== currentSimulationCase?.id ||
      prevProps.currentSimulationCase?.name !== currentSimulationCase?.name
    ) {
      this.updateCasesInfo()
    }
  }

  private readonly handleTabClick = (index: number) => {
    const { viewsObject } = this.props
    const values: Array<any> = Object.values(viewsObject)
    let dashboards = {}

    if (values.length > 0) {
      dashboards = values[0].dashboards ?? {}
    }

    const keys = Object.keys(dashboards)
    const viewIds = Object.keys(viewsObject)
    const viewId = viewIds[0]

    if (!viewId) {
      // eslint-disable-next-line no-console
      console.error('No viewId found')
      
      return
    }

    if (index !== keys.length + 1) {
      return
    }

    this.handleAddDashboard()
  }

  private readonly handleAddDashboard = (storeOnly = false) => {
    const { viewsObject, setDashboardObject, t } = this.props
    const viewIds = Object.keys(viewsObject)
    const viewId = viewIds[0]
    const dashboardId = `dashboard_${uuid()}`

    if (!viewId) {
      return
    }

    setDashboardObject(viewId, { name: t('tabs.defaultName') }, dashboardId, storeOnly)
  }

  private readonly updateCasesInfo = () => {
    const { currentProject } = this.props

    getCasesByProjectId(currentProject.id, true).then(cases => {
      const casesInfo = cases.map((c: any) => ({ name: c.name, id: c.id, caster: c.currentCaster }))

      this.setState({ casesInfo })
    })
  }

  private readonly loadTabs = async () => {
    const { visualizationMetaInformation, setConfig } = this.props

    const { config } = visualizationMetaInformation?.[AppState.Caster] ?? {}

    if (!config) {
      return
    }

    this.setState({ loading: true })

    const visualizationConfig = await getVisualizationConfigById(config)

    if (!visualizationConfig) {
      return
    }

    setConfig(visualizationConfig)

    this.setState({ loading: false })
  }

  public override render () {
    const { loading } = this.state
    const {
      defaultContent,
      viewsObject,
      featureFlags,
      visualizationMetaInformation,
      appState,
    } = this.props
    const { casesInfo } = this.state
    const views: Array<any> = Object.values(viewsObject ?? {})
    let dashboards: { [key: string]: any } = {}
    const canAddTab = FeatureFlags.canAddCasterDashboardTab(featureFlags, visualizationMetaInformation, appState)
    const hideTreeViewTab = !FeatureFlags.canViewTreeView(featureFlags)

    if (views.length > 0) {
      dashboards = views[0].dashboards ?? {}
    }

    const keys = Object.keys(dashboards)

    return keys.length > 0
      ? (
        <TabView>
          <TabPage title='T' tooltip='Tree View' hide={hideTreeViewTab}>{defaultContent}</TabPage>
          {
            !loading && keys.map((key, i) => (
              <TabPage key={key} title={String(i + 1)} tooltip={dashboards[key].name}>
                <SimpleDashboard casesInfo={casesInfo} />
              </TabPage>
            ))
          }
          {
            loading &&
            (
              <TabPage
                title={
                  <IconWrapper>
                    <FontAwesomeIcon icon={faSyncAlt} spin fixedWidth />
                  </IconWrapper>
                }
                tooltip='Loading ...'
              />
            )
          }
          <TabPage
            hide={!canAddTab}
            title={<i className='pe-7s-plus' />}
            tooltip='Add Dashboard'
          />
        </TabView>
      )
      : <div>{defaultContent}</div>
  }
}

export default compose<any>(withTranslation('caster'), connector)(CasterTree)
