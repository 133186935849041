import { request } from '@/api'

const PATH = 'caster-changes'

export type CasterChange = {
  caseId: string
  casterId: string
  userId: string
  changeTime: string
}

export function getCasterChanges (caseId: string): Promise<CasterChange[] | null> {
  return request<CasterChange[]>('get', `${PATH}/${caseId}`, 'Failed to get caster changes')
}
