import * as THREE from 'three'

import { UIComponent, type XAnchor, type YAnchor } from './UIComponent'
import Util from '../logic/Util'
import BaseView from '../views/BaseView'
import UIView from '../views/UIView'

type SliderOptions = {
  key: string
  orientation: 'horizontal' | 'vertical'
  barColor: string
  thumbColor: string
  anchorStartX: XAnchor
  anchorStartY: YAnchor
  positionStart: THREE.Vector2
  anchorEndX: XAnchor
  anchorEndY: YAnchor
  positionEnd: THREE.Vector2
  snapSteps?: number[]
  startValue?: number
  showUpDownButtons?: boolean
  showDisplayValue?: boolean
  showSnapGaps?: boolean
  displayValueSize?: number
  displayValueOffset?: THREE.Vector2
}

export class Slider extends UIComponent {
  private readonly barMaterial: THREE.MeshBasicMaterial

  private readonly thumbMaterial: THREE.MeshBasicMaterial

  private readonly buttonMaterial: THREE.MeshBasicMaterial

  private readonly buttonDisabledMaterial: THREE.MeshBasicMaterial

  private readonly thumbThickness: number = 20

  private readonly thumbLength: number = 30

  private readonly buttonSize: number = 20

  private readonly barThickness: number = 6

  private readonly snapGapSize: number = 6

  protected options: SliderOptions

  protected value: number = 0

  protected isDragging: boolean = false

  private startDragPos: THREE.Vector2 = new THREE.Vector2()

  private startValue: number = 0

  public constructor (view: BaseView, options: SliderOptions) {
    super(view)

    this.options = options

    if (options.startValue) {
      this.value = options.startValue
      this.startValue = options.startValue
    }

    this.barMaterial = new THREE.MeshBasicMaterial({ color: options.barColor, transparent: true, opacity: 0.25 })
    this.thumbMaterial = new THREE.MeshBasicMaterial({ color: options.thumbColor })
    this.buttonMaterial = new THREE.MeshBasicMaterial({ color: options.thumbColor })
    this.buttonDisabledMaterial = new THREE.MeshBasicMaterial({
      color: options.thumbColor,
      transparent: true,
      opacity: 0.25,
    })
  }

  public updateOptions (options: Partial<SliderOptions>) {
    this.options = { ...this.options, ...options }

    if (options.barColor) {
      this.barMaterial.color.set(options.barColor)
    }

    if (options.thumbColor) {
      this.thumbMaterial.color.set(options.thumbColor)
    }

    this.init()
  }

  public getValue () {
    return this.value
  }

  private getClosestSnapValue (value: number): number {
    const { snapSteps } = this.options

    if (!snapSteps?.length || snapSteps[0] === undefined) {
      return value
    }

    // Find the closest snap step
    return snapSteps.reduce(
      (closest, step) => Math.abs(step - value) < Math.abs(closest - value) ? step : closest,
      snapSteps[0],
    )
  }

  protected setDisplayValue (value: string, group: THREE.Group) {
    if (!this.options.showDisplayValue) {
      return
    }

    // Create new text mesh
    const textMesh = Util.getText(
      value,
      this.options.displayValueSize ?? 8,
      false,
      true,
    )

    if (!textMesh) {
      // eslint-disable-next-line no-console
      console.warn('Could not create display value text')

      return
    }

    textMesh.name = 'displayValue'

    // Get thumb position for text placement
    const thumb = group.getObjectByName('thumb') as THREE.Mesh | undefined

    if (!thumb) {
      return
    }

    // Position text relative to thumb
    const offset = this.options.displayValueOffset ?? new THREE.Vector2(0, 0)
    const isHorizontal = this.options.orientation === 'horizontal'

    textMesh.position.copy(thumb.position)

    if (isHorizontal) {
      textMesh.position.x += offset.x
      textMesh.position.y += this.thumbThickness + offset.y
    }
    else {
      textMesh.position.x -= this.thumbThickness + offset.x
      textMesh.position.y += offset.y
    }

    // Add text to group
    Util.addOrReplace(group, textMesh)
  }

  public setValue (value: number, force = false) {
    if (value === this.value && !force) {
      return
    }

    const thumb = this.group?.getObjectByName('thumb') as THREE.Mesh | undefined

    if (!thumb || !this.group?.userData) {
      return
    }

    let newValue = Math.max(0, Math.min(1, value))

    newValue = this.getClosestSnapValue(newValue)

    if (newValue === this.value && !force) {
      return
    }

    this.value = newValue

    this.updateButtonMaterials(this.group)

    const { orientation } = this.options
    const isHorizontal = orientation === 'horizontal'
    const { startX, startY, thumbWidth, thumbHeight, thicknessOffset, slideLength } = this.group.userData

    const lengthOffset = this.value * slideLength

    const x = startX + (isHorizontal ? lengthOffset : thicknessOffset)
    const y = startY + (isHorizontal ? thicknessOffset : lengthOffset)

    const position = this.getPosition(thumbWidth, thumbHeight, x, y, this.view.viewport)

    thumb.position.copy(position)

    this.valueChanged(this.value, this.group)
  }

  public valueChanged (value: number, group: THREE.Group) {
    window.dispatchEvent(new CustomEvent(`${this.options.key}:Changed`, { detail: { value } }))

    this.setDisplayValue(String(this.value), group)
  }

  public draggingDone () {
    window.dispatchEvent(new CustomEvent(`${this.options.key}:Done`, { detail: { value: this.value } }))
  }

  private updateButtonMaterials (group: THREE.Group) {
    const upButton = group.getObjectByName('upButton') as THREE.Mesh | undefined
    const downButton = group.getObjectByName('downButton') as THREE.Mesh | undefined

    if (!upButton || !downButton) {
      return
    }

    const isUpButtonDisabled = this.value === 1
    const isDownButtonDisabled = this.value === 0

    UIView.upOrDownButtonDisabled = isUpButtonDisabled || isDownButtonDisabled

    if (upButton.material !== (isUpButtonDisabled ? this.buttonDisabledMaterial : this.buttonMaterial)) {
      upButton.material = isUpButtonDisabled ? this.buttonDisabledMaterial : this.buttonMaterial
    }

    if (downButton.material !== (isDownButtonDisabled ? this.buttonDisabledMaterial : this.buttonMaterial)) {
      downButton.material = isDownButtonDisabled ? this.buttonDisabledMaterial : this.buttonMaterial
    }
  }

  public getUpAndDownButtons (
    a: THREE.Vector3,
    b: THREE.Vector3,
    c: THREE.Vector3,
    d: THREE.Vector3,
    position: THREE.Vector3,
  ) {
    const isHorizontal = this.options.orientation === 'horizontal'

    const buttonUp = Util.getTriangleMesh(a, c, b, this.buttonMaterial)

    buttonUp.name = 'upButton'
    buttonUp.position.copy(position)

    if (isHorizontal) {
      buttonUp.position.x += 5
    }
    else {
      buttonUp.position.y += 5
    }

    const buttonDown = Util.getTriangleMesh(b, d, a, this.buttonMaterial)

    buttonDown.name = 'downButton'
    buttonDown.position.copy(position)

    return { buttonUp, buttonDown }
  }

  public drawUpDownButtons (group: THREE.Group) {
    if (!this.options.showUpDownButtons) {
      return
    }

    const { orientation } = this.options
    const isHorizontal = orientation === 'horizontal'

    const { endX, endY } = group.userData

    const width = this.buttonSize + (isHorizontal ? 20 : 0)
    const height = this.buttonSize + (isHorizontal ? 0 : 20)

    const x = endX - (isHorizontal ? 0 : this.buttonSize / 2 - this.barThickness / 2)
    const y = endY - (isHorizontal ? this.buttonSize / 2 - this.barThickness / 2 : 0)

    const position = this.getPosition(width, height, x, y, this.view.viewport)

    let a
    let b
    let c
    let d

    // up button needs a b c in a clockwise direction
    // down button needs a b d in a counterclockwise direction

    if (isHorizontal) {
      a = new THREE.Vector3(0, -this.buttonSize / 2, 0)
      b = new THREE.Vector3(0, this.buttonSize / 2, 0)
      c = new THREE.Vector3(this.buttonSize / 2, 0, 0)
      d = new THREE.Vector3(-this.buttonSize / 2, 0, 0)
    }
    else {
      a = new THREE.Vector3(this.buttonSize / 2, 0, 0)
      b = new THREE.Vector3(-this.buttonSize / 2, 0, 0)
      c = new THREE.Vector3(0, this.buttonSize / 2, 0)
      d = new THREE.Vector3(0, -this.buttonSize / 2, 0)
    }

    const { buttonUp, buttonDown } = this.getUpAndDownButtons(a, b, c, d, position)

    Util.addOrReplace(group, buttonUp, this.view.clickableObjects)
    Util.addOrReplace(group, buttonDown, this.view.clickableObjects)

    this.updateButtonMaterials(group)
  }

  public override draw (group: THREE.Group) {
    const {
      orientation,
      positionStart,
      positionEnd,
      anchorStartX,
      anchorEndX,
      anchorStartY,
      anchorEndY,
      showSnapGaps,
      snapSteps,
    } = this.options
    const isHorizontal = orientation === 'horizontal'
    const { userData } = group

    const startX = userData['startX'] = this.getX(anchorStartX, positionStart.x)
    const startY = userData['startY'] = this.getY(anchorStartY, positionStart.y)
    const endX = userData['endX'] = this.getX(anchorEndX, positionEnd.x)
    const endY = userData['endY'] = this.getY(anchorEndY, positionEnd.y)

    const barLength = Math.abs(isHorizontal ? endX - startX : endY - startY)

    if (!showSnapGaps || !snapSteps?.length) {
      const barHeight = isHorizontal ? this.barThickness : barLength
      const barWidth = isHorizontal ? barLength : this.barThickness

      this.drawPlane(group, 'bar', startX, startY, barWidth, barHeight, this.barMaterial)
    }
    else {
      const halfGapSize = this.snapGapSize / 2
      const length = barLength - this.thumbLength

      for (let index = 0; index < snapSteps.length - 1; index++) {
        const snapStart = snapSteps[index]!
        const snapEnd = snapSteps[index + 1]!
        const segmentStart = snapStart * length + (index > 0 ? halfGapSize + this.thumbLength / 2 : 0)
        const segmentEnd = snapEnd * length -
          (index < snapSteps.length - 2 ? halfGapSize - this.thumbLength / 2 : -this.thumbLength)

        const segmentStartX = isHorizontal ? startX + segmentStart : startX
        const segmentStartY = isHorizontal ? startY : startY + segmentStart
        const segmentEndX = isHorizontal ? startX + segmentEnd : startX
        const segmentEndY = isHorizontal ? startY : startY + segmentEnd

        const segmentWidth = isHorizontal ? segmentEndX - segmentStartX : this.barThickness
        const segmentHeight = isHorizontal ? this.barThickness : segmentEndY - segmentStartY

        this.drawPlane(
          group,
          `bar-segment-${index}`,
          segmentStartX,
          segmentStartY,
          segmentWidth,
          segmentHeight,
          this.barMaterial,
        )
      }
    }

    const thumbWidth = userData['thumbWidth'] = isHorizontal ? this.thumbLength : this.thumbThickness
    const thumbHeight = userData['thumbHeight'] = isHorizontal ? this.thumbThickness : this.thumbLength

    const thicknessOffset = userData['thicknessOffset'] = -(this.thumbThickness / 2 - this.barThickness / 2)

    const slideLength = userData['slideLength'] = barLength - this.thumbLength
    const lengthOffset = this.value * slideLength

    const thumbX = startX + (isHorizontal ? lengthOffset : thicknessOffset)
    const thumbY = startY + (isHorizontal ? thicknessOffset : lengthOffset)

    this.drawPlane(
      group,
      'thumb',
      thumbX,
      thumbY,
      thumbWidth,
      thumbHeight,
      this.thumbMaterial,
      this.view.clickableObjects,
    )

    this.drawUpDownButtons(group)

    this.valueChanged(this.value, group)
  }

  public override animate (_elapsed: number) {}

  private handleMouseDown (object: THREE.Object3D | null, mouseOnCanvas: THREE.Vector2) {
    const stepSize = this.options.showSnapGaps ? 1 / (this.options.snapSteps?.length ?? 2 - 1) : 0.1

    if (object?.name === 'upButton') {
      this.setValue(this.value + stepSize)

      this.draggingDone()

      return
    }
    else if (object?.name === 'downButton') {
      this.setValue(this.value - stepSize)

      this.draggingDone()

      return
    }

    this.isDragging = true
    this.startDragPos = new THREE.Vector2(mouseOnCanvas.x, mouseOnCanvas.y)
    this.startValue = this.value

    if (this.view.views.mainView) {
      this.view.views.mainView.controls.enabled = false
    }

    // TODO: trigger event Done
  }

  protected handleMouseUp (_mouseOnCanvas: THREE.Vector2) {
    if (this.isDragging && this.startValue !== this.value) {
      this.draggingDone()
    }

    this.isDragging = false

    if (this.view.views.mainView) {
      this.view.views.mainView.controls.enabled = true
    }
  }

  private handleMouseMove (mouseOnCanvas: THREE.Vector2) {
    if (!this.isDragging) {
      return
    }

    const { slideLength } = this.group.userData
    const { orientation } = this.options
    const isHorizontal = orientation === 'horizontal'

    const delta = mouseOnCanvas.clone().sub(this.startDragPos)

    const lengthOffset = isHorizontal ? delta.x : -delta.y

    const newScrollValue = lengthOffset / slideLength

    this.setValue(newScrollValue + this.startValue)
  }

  public override handleEvent (event: string, { object, data }: { object: THREE.Object3D | null, data: any }) {
    const mouseOnCanvas = data as THREE.Vector2

    switch (event) {
      case 'mousedown':
        this.handleMouseDown(object, mouseOnCanvas)
        break
      case 'mouseup':
        this.handleMouseUp(mouseOnCanvas)
        break
      case 'mousemove':
        this.handleMouseMove(mouseOnCanvas)
        break
    }
  }
}
