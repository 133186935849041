import { getTemporalData, getTemporalDataBySlots, TemporalDataResponse } from '@/api/temporal-data'
import { getCurrentDashboardEntry } from '@/App/util'
import { getElementMapsObject } from '@/store/elements/logic'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeUtil from '@/three/logic/Util'
import { CasterElementNames } from '@/types/data'
import { ElementMaps, TagName } from '@/types/state'
import { PlotConfig } from '@/types/visualization'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { Mapping } from '@/Util/mapping/Mapping'

import { Props } from './index'

export interface NeededDataObject {
  byRealDataUUID: Partial<Record<CasterElementNames, Set<string>>>
  bySlotId: Partial<Record<CasterElementNames, Set<string>>>
}
export default class TemporalDataHandler {
  public static async getTemporalData (props: Props) {
    const {
      plotConfigs,
      setTemporalData,
      temporalData,
      currentProject,
    } = props

    const elementMaps = getElementMapsObject(props)
    const plotIds = this.getPlotIds(props)

    if (!plotIds) {
      return
    }

    const neededData = this.getNeededDataArray(plotConfigs, plotIds, temporalData, elementMaps)

    if (Object.keys(neededData.byRealDataUUID).length === 0 && Object.keys(neededData.bySlotId).length === 0) {
      return
    }

    const realDataUUIDsByType: Partial<Record<CasterElementNames, string[]>> = {}

    Object.entries(neededData.byRealDataUUID).forEach(([ type, realDataUUIDs ]) => {
      realDataUUIDsByType[type as CasterElementNames] = Array.from(realDataUUIDs)
    })

    const slotIdsByType: Partial<Record<CasterElementNames, string[]>> = {}

    Object.entries(neededData.bySlotId).forEach(([ type, slotIds ]) => {
      slotIdsByType[type as CasterElementNames] = Array.from(slotIds)
    })

    let mountLogsByRealDataUUIDResponse: TemporalDataResponse = []

    if (Object.keys(realDataUUIDsByType).length) {
      mountLogsByRealDataUUIDResponse = (await getTemporalData(currentProject.id, realDataUUIDsByType)) ?? []
    }

    let mountLogsBySlotsResponse: TemporalDataResponse = []

    if (Object.keys(slotIdsByType).length) {
      mountLogsBySlotsResponse = (await getTemporalDataBySlots(currentProject.id, slotIdsByType)) ?? []
    }

    const dataByUUID = TemporalDataHandler.mapMountLogsToTemporalData(mountLogsByRealDataUUIDResponse, neededData)
    const dataBySlotId = TemporalDataHandler.mapMountLogsToTemporalDataBySlot(mountLogsBySlotsResponse, neededData)

    const data = { ...dataByUUID, ...dataBySlotId }

    setTemporalData(data)
  }

  public static async getTemporalDataForSpecificFilter (
    setTemporalData: (data: any) => void,
    filter: string,
    projectId: string,
  ) {
    const neededData: NeededDataObject = { byRealDataUUID: {}, bySlotId: {} }

    this.getNeededDataByFilter(filter, neededData)

    if (!Object.keys(neededData.byRealDataUUID).length) {
      return
    }

    const realDataUUIDsByType: Partial<Record<CasterElementNames, string[]>> = {}

    Object.entries(neededData.byRealDataUUID).forEach(([ type, realDataUUIDs ]) => {
      realDataUUIDsByType[type as CasterElementNames] = Array.from(realDataUUIDs)
    })

    const mountLogsTypeArray = (await getTemporalData(projectId, realDataUUIDsByType)) ?? []
    const data = this.mapMountLogsToTemporalData(mountLogsTypeArray, neededData)

    setTemporalData(data)
  }

  private static mapMountLogsToTemporalData (mountLogsTypeArray: TemporalDataResponse, neededData: NeededDataObject) {
    const data = {} as any

    const mountLogsPerRealDataUUID: Record<string, any[]> = {}

    for (const mountLogType of mountLogsTypeArray) {
      for (const mountLog of mountLogType) {
        const realDataUUID = mountLog.realDataUUID

        if (!realDataUUID) {
          continue
        }

        if (!mountLogsPerRealDataUUID[realDataUUID]) {
          mountLogsPerRealDataUUID[realDataUUID] = []
        }

        mountLogsPerRealDataUUID[realDataUUID].push(mountLog)
      }
    }

    Object.entries(mountLogsPerRealDataUUID).forEach(([ realDataUUID, mountLogs ]) => {
      data[realDataUUID] = mountLogs
    })

    //  go through all needed data, if it is not in the data object, add it with an empty array
    Object.values(neededData.byRealDataUUID).forEach((realDataUUIDs) => {
      realDataUUIDs.forEach((realDataUUID) => {
        if (!data[realDataUUID]) {
          data[realDataUUID] = []
        }
      })
    })

    return data
  }

  private static mapMountLogsToTemporalDataBySlot (
    mountLogsTypeArray: TemporalDataResponse,
    neededData: NeededDataObject,
  ) {
    const data = {} as any

    const mountLogsPerSlotId: Record<string, any[]> = {}

    for (const mountLogType of mountLogsTypeArray) {
      for (const mountLog of mountLogType) {
        const slotId = mountLog.slotId

        if (!slotId) {
          continue
        }

        if (!mountLogsPerSlotId[slotId]) {
          mountLogsPerSlotId[slotId] = []
        }

        mountLogsPerSlotId[slotId].push(mountLog)
      }
    }

    Object.entries(mountLogsPerSlotId).forEach(([ slotId, mountLogs ]) => {
      data[slotId] = mountLogs
    })

    //  go through all needed data, if it is not in the data object, add it with an empty array
    Object.values(neededData.bySlotId).forEach((slotIds) => {
      slotIds.forEach((slotId) => {
        if (!data[slotId]) {
          data[slotId] = []
        }
      })
    })

    return data
  }

  private static getPlotIds (props: Props) {
    const { currentDashboard, viewsObject, tileConfigs } = props
    const { viewId, dashboardId } = getCurrentDashboardEntry(currentDashboard, viewsObject)

    if (!viewId || !dashboardId) {
      return
    }

    const tileIds: string[] = viewsObject[viewId]?.dashboards?.[dashboardId]?.tileIds ?? []

    if (!tileIds?.length) {
      return
    }

    return tileIds.map(tileId => tileConfigs[tileId]?.configId).filter(Boolean) as string[]
  }

  private static getNeededDataByFilter (
    filter: string,
    neededData: NeededDataObject,
  ) {
    const [ type, rest = '' ] = filter.split('#')
    const [ , realDataUUID ] = rest.split('realDataUUID=')

    if (!realDataUUID || !type) {
      return
    }

    if (!neededData.byRealDataUUID[type as CasterElementNames]) {
      neededData.byRealDataUUID[type as CasterElementNames] = new Set()
    }

    neededData.byRealDataUUID[type as CasterElementNames]?.add(realDataUUID)
  }

  private static getNeededDataArray (
    plotConfigs: any,
    plotIds: string[],
    temporalData: TemporalDataState,
    elementMaps: ElementMaps,
  ): NeededDataObject {
    const neededData: NeededDataObject = { byRealDataUUID: {}, bySlotId: {} }

    for (const plotId of plotIds) {
      const plotConfig = plotConfigs[plotId] ?? {}

      if (plotConfig.configIds?.length) {
        TemporalDataHandler.getNeededDataArrayForMergedConfig(plotConfig, temporalData, elementMaps, neededData)
      }

      const { filter, selectedY, selectedX } = plotConfig

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [ _, selector ] = selectedX?.split('|') ?? []

      if (selector !== 'dataOverTime' && selector !== 'dataOverTimeBySlot') {
        continue
      }

      const isDataOverTimeBySlot = selector === 'dataOverTimeBySlot'
      const [ type ] = selectedY.split('|') as CasterElementNames[]

      if (!type) {
        continue
      }

      const filteredElements = FilterHandler.getFilteredElements(elementMaps, filter, false)
      const mountLogMapKeysByTagName = ElementsUtil.getMountLogMapKeysByTagName(type as TagName)
      const searchedKey = isDataOverTimeBySlot ? 'slotId' : 'realDataUUID'

      const keyValues = Object
        .keys(filteredElements ?? {})
        .map(path => {
          const { type: elementType } = ThreeUtil.getElementInfo(path)

          if (elementType !== type) {
            return null
          }

          const mountLogId = Mapping.mountLogIdByElementPath[path]

          if (!mountLogId) {
            return null
          }

          let element: any = null

          mountLogMapKeysByTagName.forEach((key) => {
            if (elementMaps[key]?.[mountLogId]) {
              element = elementMaps[key]?.[mountLogId]?.[searchedKey]
            }
          })

          return element
        })
        .filter(realDataUUID => typeof realDataUUID === 'string') as string[]

      const missingInfo = keyValues
        .filter(keyValue => !temporalData[keyValue])

      if (!keyValues.length || !missingInfo.length) {
        continue
      }

      const neededDataKey = isDataOverTimeBySlot ? 'bySlotId' : 'byRealDataUUID'

      if (!neededData[neededDataKey][type]) {
        neededData[neededDataKey][type] = new Set()
      }

      missingInfo.forEach(info => {
        neededData[neededDataKey][type]?.add(info)
      })
    }

    return neededData
  }

  private static getNeededDataArrayForMergedConfig (
    plotConfig: PlotConfig,
    temporalData: TemporalDataState,
    elementMaps: ElementMaps,
    neededData: NeededDataObject,
  ) {
    for (const config of plotConfig.configs) {
      const { filter, selectedY, selectedX } = config

      if (!selectedX?.includes('dataOverTime') && !selectedX?.includes('dataOverTimeBySlot')) {
        continue
      }

      const isDataOverTimeBySlot = selectedX.includes('dataOverTimeBySlot')

      const [ type ] = selectedY.split('|') as CasterElementNames[]

      if (!type) {
        continue
      }

      const filteredElements = FilterHandler.getFilteredElements(elementMaps, filter, false)
      const mountLogMapKeysByTagName = ElementsUtil.getMountLogMapKeysByTagName(type as TagName)
      const searchedKey = isDataOverTimeBySlot ? 'slotId' : 'realDataUUID'

      const keyValues = Object
        .keys(filteredElements ?? {})
        .map(path => {
          const { type: elementType } = ThreeUtil.getElementInfo(path)

          if (elementType !== type) {
            return null
          }

          const mountLogId = Mapping.mountLogIdByElementPath[path]

          if (!mountLogId) {
            return null
          }

          let element: any = null

          mountLogMapKeysByTagName.forEach((key) => {
            if (elementMaps[key]?.[mountLogId]) {
              element = elementMaps[key]?.[mountLogId]?.[searchedKey]
            }
          })

          return element
        })
        .filter(realDataUUID => typeof realDataUUID === 'string') as string[]

      const missingInfo = keyValues
        .filter(keyValue => !temporalData[keyValue])

      if (!keyValues.length || !missingInfo.length) {
        continue
      }

      const neededDataKey = isDataOverTimeBySlot ? 'bySlotId' : 'byRealDataUUID'

      if (!neededData[neededDataKey][type]) {
        neededData[neededDataKey][type] = new Set()
      }

      missingInfo.forEach(info => {
        neededData[neededDataKey][type]?.add(info)
      })
    }
  }
}
