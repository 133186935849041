import isEqual from 'lodash/isEqual'
import Menu, { Divider, MenuItem, SubMenu, SubMenuProps } from 'rc-menu'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, type ConnectedProps } from 'react-redux'
import { compose } from 'redux'

import Executables from '@/react/Executables'
import FeatureFlags from '@/react/FeatureFlags'
import * as ApplicationActions from '@/store/application/main/actions'
import * as DataActions from '@/store/data/actions'
import { getFilterControlDefinitions } from '@/store/filter/actions'
import type { DefaultState } from '@/types/state'

import HotKeyHandler from './HotKeyHandler'
import logic from './logic/'
import { Label, MenuBar, Shortcut } from './styles'

import 'rc-menu/assets/index.css'
import './rcMenuStyles.css'

type FixedSubMenuProps = SubMenuProps & { id: string }

const FixedSubMenu = (props: FixedSubMenuProps) => <SubMenu {...props as any} />

const connector = connect((state: DefaultState) => ({
  appState: state.application.main.appState,
  executableDefinitions: state.data.executableDefinitions,
  currentSimulationCase: state.application.main.currentSimulationCase,
  filterControlDefinitions: state.filter.filterControlDefinitions,
  openAppDialogs: state.application.main.openAppDialogs,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  authenticationData: state.application.main.authenticationData,
  currentProject: state.application.main.currentProject,
  isConsistencyCheck: state.util.isConsistencyCheck,
  networkStatus: state.application.main.networkStatus,
  plotConfigs: state.visualization.plotConfigs,
  isEditModeOn: state.visualization.isEditModeOn,
  uploadLoading: state.loading.uploadLoading,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  Caster: state.Caster,
}), {
  handleMenuAction: ApplicationActions.handleMenuAction,
  getExecutableDefinitions: DataActions.getExecutableDefinitions,
  getFilterControlDefinitions,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface MenuBuilderProps extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  alreadyFetchedDefinitions: boolean
  alreadyFetchedFilterControlDefinitions: boolean
}

class MenuBuilder extends Component<MenuBuilderProps> {
  public override state: State = {
    alreadyFetchedDefinitions: false,
    alreadyFetchedFilterControlDefinitions: false,
  }
  
  public override componentDidMount () {
    const { menu } = logic
    const { appState, executableDefinitions, currentSimulationCase, featureFlags } = this.props

    const definitions = Executables.getAllowedExecutables(executableDefinitions, currentSimulationCase)

    menu(appState, definitions, featureFlags).forEach((item: any) => {
      const isDisabled = item.disabled !== undefined ? item.disabled(this.props) : false

      if (!isDisabled) {
        HotKeyHandler.handleShortcuts(true, item, this.handleClick, this.props)
      }
    })
  }
  
  public override async componentDidUpdate (prevProps: MenuBuilderProps) {
    const { menu, update } = logic
    const { alreadyFetchedDefinitions, alreadyFetchedFilterControlDefinitions } = this.state
    const {
      appState,
      executableDefinitions,
      currentSimulationCase,
      featureFlags,
      filterControlDefinitions,
      getExecutableDefinitions,
      getFilterControlDefinitions,
      authenticationData,
    } = this.props

    let refetchExecutablesDefinitions = false
    let refetchFilterControlDefinitions = false

    if (prevProps.executableDefinitions?.length && !this.props.executableDefinitions?.length) {
      refetchExecutablesDefinitions = true
    }

    if (prevProps.filterControlDefinitions?.length && !this.props.filterControlDefinitions?.length) {
      refetchFilterControlDefinitions = true
    }

    if (prevProps.authenticationData.name !== authenticationData.name) {
      refetchExecutablesDefinitions = true
      refetchFilterControlDefinitions = true
    }

    if (
      !executableDefinitions?.length &&
      authenticationData.name &&
      (!alreadyFetchedDefinitions || refetchExecutablesDefinitions)
    ) {
      this.setState({ alreadyFetchedDefinitions: true })
      getExecutableDefinitions()
    }

    if (
      (filterControlDefinitions.length === 0) &&
      authenticationData.name &&
      (!alreadyFetchedFilterControlDefinitions || refetchFilterControlDefinitions)
    ) {
      this.setState({ alreadyFetchedFilterControlDefinitions: true })
      getFilterControlDefinitions()
    }

    let forceUpdate = false

    if (
      prevProps.currentSimulationCase.id !== currentSimulationCase.id || 
      !isEqual(prevProps.executableDefinitions, executableDefinitions)
    ) {
      forceUpdate = true
    }

    const definitions = Executables.getAllowedExecutables(executableDefinitions, currentSimulationCase)

    await update(this.props, definitions, this, forceUpdate)

    if (prevProps.appState !== appState) {
      menu(prevProps.appState, definitions, featureFlags)
        .forEach(item => HotKeyHandler.handleShortcuts(false, item))
    }

    menu(appState, definitions, featureFlags)
      .forEach((item: any) => {
        const featureFlags = authenticationData.featureFlags
        const isVisible = (item.visible ? item.visible(featureFlags, this.props) : true) ?? true
        const isDisabled = item.disabled ? item.disabled(this.props) : false

        if (!isVisible || isDisabled) {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          HotKeyHandler.handleShortcuts(false, item, () => {}, this.props)
        }
        else {
          HotKeyHandler.handleShortcuts(true, item, this.handleClick, this.props)
        }
      })
  }
  
  public override componentWillUnmount (): void {
    const { menu } = logic
    const { appState, executableDefinitions, currentSimulationCase, featureFlags } = this.props

    const definitions = Executables.getAllowedExecutables(executableDefinitions, currentSimulationCase)

    menu(appState, definitions, featureFlags).forEach(item => HotKeyHandler.handleShortcuts(false, item))
  }

  private readonly handleClick = ({ action, params: rawParams, disabled }: any) => {
    const { handleMenuAction } = this.props
    const isDisabled = disabled !== undefined ? disabled(this.props) : false

    if (isDisabled) {
      return
    }

    const params = typeof rawParams === 'function' ? rawParams(this.props) : rawParams

    handleMenuAction(action, params)
  }

  private readonly handleMenuItemMouseDown = (item: any) => {
    this.handleClick(item)
  }
  
  private getSubMenu (item: any, index: any) {
    const { t, featureFlags } = this.props
    const { id, label, type, shortcut, disabled, submenu, visible, onOpen } = item
    const isDisabled = disabled !== undefined ? disabled(this.props) : false
    const isVisible = visible !== undefined ? visible(featureFlags, this.props) : true

    if (!isVisible) {
      return
    }

    if (type === 'separator') {
      return <Divider key={index} />
    }

    if (submenu) {
      return (
        // to disable tooltip in submenus the title has to be an html element
        // https://github.com/ant-design/ant-design/issues/12277
        <FixedSubMenu
          id={id}
          key={index}
          title={<span>{label(t, this.props)}</span>}
          disabled={isDisabled}
          onTitleClick={onOpen ? () => onOpen(this.props) : undefined as any}
        >
          {submenu.map((subMenu: any, childIndex: number) => this.getSubMenu(subMenu, `${index}-${childIndex}`))}
        </FixedSubMenu>
      )
    }

    return (
      <MenuItem
        id={id}
        key={index}
        disabled={isDisabled}
        onMouseDown={() => this.handleMenuItemMouseDown(item)}
      >
        <Label>{label(t, this.props)}</Label>
        <Shortcut $disabled={isDisabled}>{shortcut}</Shortcut>
      </MenuItem>
    )
  }
  
  public override render () {
    const { appState, executableDefinitions, currentSimulationCase, featureFlags } = this.props
    const definitions = Executables.getAllowedExecutables(executableDefinitions, currentSimulationCase)
    const { menu } = logic

    return (
      <MenuBar>
        <Menu mode='horizontal' triggerSubMenuAction='click'>
          {menu(appState, definitions, featureFlags).map((item, index) => this.getSubMenu(item, index))}
        </Menu>
      </MenuBar>
    )
  }
}

export default compose<any>(withTranslation('titlebar'), connector)(MenuBuilder)
