/* eslint-disable max-len */
import hoistStatics from 'hoist-non-react-statics'
import hotkeys from 'hotkeys-js'
import isEqual from 'lodash/isEqual'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import { v4 as uuid } from 'uuid'

import { getLastSuccessfulExecution } from '@/api/executables'
import { useConfig } from '@/config'
import Util from '@/logicHandlers/ServerLogic/actions/Util'
import Button from '@/react/components/Button'
import BaseDialog from '@/react/dialogs/BaseDialog'
import { Spacer } from '@/react/dialogs/project/OpenProjectDialog/Styles'
import { DialogID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import Input from '@/react/specific/Input'
import { Form, Text } from '@/react/visualization/dashboard/Dialogs/DialogStyles'
import ApiClient from '@/store/apiClient'
import * as ApplicationActions from '@/store/application/main/actions'
import * as DataActions from '@/store/data/actions'
import Menu from '@/TitleBar/react/MenuBuilder/logic'
import type { DefaultState } from '@/types/state'
import type { ArrayOfTranslations, Translation } from '@/types/translation'
import { Identifiable } from '@/Util/decorators/Identifiable'

import Logic from './logic'
import ParameterComponent from './Parameter'
import StepComponent from './Step'
import StepWithOptions from './StepWithOptions'
import { ExecutionStepState } from '../enums'

export const submitButtonColor = '#41a880'
export const submitButtonColorHover = '#3a9773'

const connector = connect((state: DefaultState) => ({
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  authenticationData: state.application.main.authenticationData,
  params: state.application.main.params,
  executionStateDictionary: state.data.executionStateDictionary,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  execution: state.data.execution,
  Caster: state.Caster,
}), {
  closeDialog: ApplicationActions.closeDialog,
  executeExecutableDefinition: DataActions.executeExecutableDefinition,
  setExecutionState: DataActions.setExecutionState,
  setManyExecutionStates: DataActions.setManyExecutionStates,
  setExecutionData: DataActions.setExecutionData,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t: ArrayOfTranslations & Translation
}

type State = {
  selectedCase: string | null
  selectedStepCaseOptionRecord: Record<number, number>
  error: string
  parameterValues: Record<string, Record<string, any>>
  uuid: string
  newCaseName: string
  newCaseDescription: string
  waitingForExecutions: string[]
  createNewCaseParam: CreateNewCaseDefinition | null
  initialCreateNewCaseParam: CreateNewCaseDefinition | null
  dynamicOptions: Record<string, Option[]>
  stepIsExpanded: Record<number, boolean>
  stepIsValid: Record<number, boolean>
  lastCompletedStepIndex: number
  lastChangedStep: Record<string, number | string | undefined> | { index: number }
  lastUUIDsUsedInExecutables: string[]
  loadingPreviousExecution: boolean
  uploadingFileParameterId: string
}

const T = 'executableDialog'

export class ExecutableDialog extends Component<Props, State> {
  @Identifiable('ExecutableDialog') public static readonly NAME: string

  public override state: State = {
    selectedCase: null,
    selectedStepCaseOptionRecord: {},
    error: '',
    parameterValues: {},
    uuid: uuid(),
    newCaseName: '',
    newCaseDescription: '',
    waitingForExecutions: [],
    createNewCaseParam: null,
    initialCreateNewCaseParam: null,
    dynamicOptions: {},
    stepIsExpanded: {},
    stepIsValid: {},
    lastCompletedStepIndex: -1,
    lastChangedStep: { index: -1 },
    lastUUIDsUsedInExecutables: [],
    loadingPreviousExecution: true,
    uploadingFileParameterId: '',
  }

  public override async componentDidMount () {
    hotkeys('Escape', this.handleClose)

    const { currentSimulationCase, authenticationData, setExecutionData, params, Caster } = this.props

    const isCaseOwner = currentSimulationCase && currentSimulationCase?.userId === authenticationData?.id

    if (!isCaseOwner) {
      this.setState({ 
        createNewCaseParam: { value: true, autoLoad3D: true, hidden: false },
        initialCreateNewCaseParam: { value: true, autoLoad3D: true, hidden: false },
      })
    }

    const definitionId = params?.definition?.id
    const { activeXMLDataBaseKeysForExecutablesDialog } = (params?.definition ?? {}) as ExecutableDefinition
    const XMLDataBase = Caster?.additionalData?.XMLDataBase ?? {}
    const isKeyActive = this.isSomeXMLDataBaseKeyActive(activeXMLDataBaseKeysForExecutablesDialog, XMLDataBase)

    const lastSuccessfulExecution = isKeyActive
      ? await getLastSuccessfulExecution(currentSimulationCase.id, definitionId)
      : null

    setExecutionData(lastSuccessfulExecution)
    this.selectPreviousUsedCase(lastSuccessfulExecution)

    if (lastSuccessfulExecution) {
      this.restorePreviousExecutionState(lastSuccessfulExecution)
    }

    this.setState({ loadingPreviousExecution: false })
  }

  public override async componentDidUpdate (prevProps: Props, prevState: State) {
    // the problem could be that another thing is updating, could return if new exec state is equal to the old one
    const { executionStateDictionary } = prevProps
    const { 
      executionStateDictionary: currentExecutionStateDictionary,
      params,
      execution,
      currentSimulationCase, 
      setExecutionData,
      Caster,
      
    } = this.props
    const { parameterValues } = this.state
    const { cases, activeXMLDataBaseKeysForExecutablesDialog } = (params?.definition ?? {}) as ExecutableDefinition
    const values = parameterValues ?? {}
    const trueSelectedCaseId = this.getSelectedCaseId()

    if (!trueSelectedCaseId) {
      return
    }

    values[trueSelectedCaseId] = values[trueSelectedCaseId] ?? {}

    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCaseId)

    if (prevState.selectedCase !== this.state.selectedCase) {
      this.setState({ loadingPreviousExecution: true })

      const definitionId = params?.definition?.id
      const XMLDataBase = Caster?.additionalData?.XMLDataBase ?? {}
      const isKeyActive = this.isSomeXMLDataBaseKeyActive(activeXMLDataBaseKeysForExecutablesDialog, XMLDataBase)

      if (!definitionId || !isKeyActive) {
        this.setState({ lastChangedStep: { index: -1 }, loadingPreviousExecution: false })
        setExecutionData(null)

        return
      }

      const lastSuccessfulExecution = await getLastSuccessfulExecution(currentSimulationCase.id, definitionId)

      setExecutionData(lastSuccessfulExecution)

      if (lastSuccessfulExecution) {
        this.restorePreviousExecutionState(lastSuccessfulExecution)
      }

      this.setState({ lastChangedStep: { index: -1 }, loadingPreviousExecution: false })
    }

    if (isEqual(executionStateDictionary, currentExecutionStateDictionary)) {
      return
    }

    const { waitingForExecutions } = this.state

    waitingForExecutions.forEach(key => {
      if (!currentExecutionStateDictionary[key]) {
        return
      }

      if (currentExecutionStateDictionary[key] === ExecutionStepState.Success) {
        const keyIds = key.split('_')

        if (currentCase?.steps && keyIds.length === 4) {
          const stepId = keyIds[3]
          const stepIndex = currentCase.steps.findIndex(step => step.id === stepId)

          if (stepIndex !== currentCase.steps.length - 1) {
            const waitingForExecutionsStateCopy = this.state.waitingForExecutions.filter(id => id !== key)

            this.setState({
              lastChangedStep: { index: -1 },
              lastCompletedStepIndex: stepIndex,
              waitingForExecutions: waitingForExecutionsStateCopy,
            })

            // fetch next step's dynamic options if it has them
            this.handleLoadNextStepsDynamicOptions(stepIndex + 1, execution?.id)

            return
          }
        }

        this.setState({
          newCaseName: '',
          newCaseDescription: '',
          createNewCaseParam: { ...this.state.initialCreateNewCaseParam },
        })
      }
      else if (currentExecutionStateDictionary[key] === 'error') {
        const waitingForExecutionsStateCopy = this.state.waitingForExecutions.filter(id => id !== key)

        this.setState({
          waitingForExecutions: waitingForExecutionsStateCopy,
        })
      }
    })
  }

  public override componentWillUnmount () {
    hotkeys.deleteScope('other')
    hotkeys.unbind('Escape', this.handleClose)
  }

  private readonly handleInitDynamicOptions = async (
    caseId: string,
    lastCompletedStepIndex: number | undefined,
    execution: ExecutionEntity,
  ) => {
    const { params, currentProject, currentSimulationCase } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const { steps: executionSteps, id: executionId } = execution

    if (!cases) {
      return
    }

    const caseObject = cases.find(definitionCase => definitionCase.id === caseId)
    const { parameters = [], steps = [] } = caseObject ?? {}
    const parametersWithOptions = this.getParametersWithDynamicOptions(
      steps,
      parameters,
      lastCompletedStepIndex,
    )

    if (executionSteps.length > 0) {
      this.getParametersWithDynamicsOptionsFromSelectedCaseOptions(
        steps,
        parametersWithOptions,
        executionSteps,
        lastCompletedStepIndex ?? 0,
      )
    }

    try {
      const { options, errors } = await ApiClient.post(
        `${useConfig().apiBaseURL}/executable/dynamic-options`,
        {
          data: {
            parameters: parametersWithOptions.map(parameter => ({ ...parameter, context: caseObject?.context })),
            projectId: currentProject.id,
            caseId: currentSimulationCase.id,
            executionId,
          },
        },
      )

      if (errors && errors.length) {
        enqueueSnackbar(
          'Error while fetching dynamic options, watch logs for details',
          { variant: 'error', autoHideDuration: 3000 },
        )
        // eslint-disable-next-line no-console
        console.log('error paths: ', errors)
      }

      this.setState({ dynamicOptions: options })
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.log('error fetching dynamic options')
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  private readonly handleLoadNextStepsDynamicOptions = async (stepIndex: number, executionId?: string) => {
    const { params, currentProject, currentSimulationCase } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition

    if (!cases) {
      return
    }

    const trueSelectedCase = this.getSelectedCaseId()
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase || !currentCase?.steps) {
      return
    }

    const currentStep = currentCase.steps[stepIndex]

    if (!currentStep) {
      return
    }

    const { parameters = [], caseOptions } = currentStep

    let parametersWithOptions: (Parameter & { context?: string | undefined })[]

    if (caseOptions) {
      const { chosenCaseOption } = this.getChosenCaseOption(currentStep, stepIndex)

      if (!chosenCaseOption || !chosenCaseOption.parameters) {
        return
      }

      parametersWithOptions = chosenCaseOption.parameters.filter(param => (!param.hidden && param.optionsFile))
    }
    else {
      parametersWithOptions = parameters.filter(param => (!param.hidden && param.optionsFile))
    }

    if (parametersWithOptions.length === 0) {
      return
    }

    parametersWithOptions = parametersWithOptions.map(parameter => ({ ...parameter, context: currentCase.context }))

    try {
      const response = await ApiClient.post(`${useConfig().apiBaseURL}/executable/dynamic-options`, {
        data: {
          parameters: parametersWithOptions,
          projectId: currentProject.id,
          caseId: currentSimulationCase.id,
          executionId,
        },
      })

      const { options, errors = [] } = response

      if (errors.length) {
        enqueueSnackbar('Error while fetching dynamic options, watch logs for details', {
          variant: 'error',
          autoHideDuration: 3000,
        })
        // eslint-disable-next-line no-console
        console.log('path: ', errors)

        return
      }

      this.setState({
        dynamicOptions: {
          ...this.state.dynamicOptions,
          ...options,
        },
      })
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.log('error fetching dynamic options')
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  private readonly handleInitStepsExpandedState = () => {
    const { params } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const trueSelectedCase = this.getSelectedCaseId()
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase || !currentCase.steps || !Array.isArray(currentCase.steps)) {
      return
    }

    const stepIsExpanded: Record<number, boolean> = {}
    const stepArrLength = currentCase.steps.length

    for (let i = 0; i < stepArrLength; i++) {
      if (!i) {
        stepIsExpanded[i] = true
        continue
      }

      stepIsExpanded[i] = false
    }

    this.setState({ stepIsExpanded })
  }

  private readonly handleInitInputsWithPreviousSubmission = (lastExecution: ExecutionEntity) => {
    const { steps, definitionCaseId } = lastExecution

    const paramValues: Record<string, Record<string, any>> = {}

    if (!paramValues[definitionCaseId]) {
      paramValues[definitionCaseId] = {}
    }

    const paramValue = paramValues[definitionCaseId]

    for (const step of steps) {
      const { executionInputs, files } = step

      Object.entries(executionInputs ?? {}).forEach(([ paramId, value ]) => {
        paramValue[paramId] = value
      })

      files?.forEach(({ paramId, id }) => {
        paramValue[paramId] = id
      })
    }

    const { params, currentSimulationCase, authenticationData } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const currentCase = cases.find(definitionCase => definitionCase.id === definitionCaseId)
    const createNewCaseParamFromDefinition = currentCase?.steps
      ? currentCase.steps[currentCase.steps.length - 1]?.createNewCase
      : currentCase?.createNewCase ?? null

    const previousInputs = lastExecution.steps[lastExecution.steps.length - 1]?.executionInputs

    const previousCreateCaseInputs = previousInputs?.['newCaseData'] ?? null
    const isCaseOwner = currentSimulationCase && currentSimulationCase?.userId === authenticationData?.id
    const createNewCaseParam: CreateNewCaseDefinition = {
      autoLoad3D: (
        !isCaseOwner
          ? true
          : (previousCreateCaseInputs?.autoLoad3D !== undefined
            ? previousCreateCaseInputs.autoLoad3D
            : createNewCaseParamFromDefinition?.autoLoad3D)
      ),
      value: (
        !isCaseOwner
          ? true
          : (previousCreateCaseInputs?.newCase !== undefined
            ? previousCreateCaseInputs.newCase
            : createNewCaseParamFromDefinition?.value)
      ),
      caseDescriptionTitle: createNewCaseParamFromDefinition?.caseDescriptionTitle ?? '',
      caseNameTitle: createNewCaseParamFromDefinition?.caseNameTitle ?? '',
      hidden: createNewCaseParamFromDefinition?.hidden ?? false,
    }

    this.setState({
      createNewCaseParam,
      initialCreateNewCaseParam: { ...createNewCaseParam },
      newCaseDescription: previousCreateCaseInputs?.newCaseDescription ?? '',
      newCaseName: previousCreateCaseInputs?.newCaseName ?? '',
      parameterValues: paramValues,
    })
  }

  // private readonly handleUpdateCreateNewCaseKeys = () => {
  //   const { params, currentSimulationCase, authenticationData } = this.props
  //   const { cases } = (params?.definition ?? {}) as ExecutableDefinition
  //   const trueSelectedCase = this.getSelectedCaseId()
  //   const caseObject = cases.find((c) => c.id === trueSelectedCase)
  //   const createNewCaseParam = caseObject?.createNewCase ?? null

  //   const isCaseOwner = currentSimulationCase && currentSimulationCase?.userId === authenticationData?.id

  //   if (!isCaseOwner) {
  //     return
  //   }

  //   this.setState({
  //     createNewCaseParam,
  //     initialCreateNewCaseParam: createNewCaseParam ? { ...createNewCaseParam } : null,
  //   })
  // }

  private readonly handleCreateNewCaseChange = (event: Event & { target: HTMLInputElement }) => {
    const { featureFlags } = this.props

    if (!FeatureFlags.canEditExecutableDialog(featureFlags) || !FeatureFlags.canCreateCase(featureFlags)) {
      return
    }

    const { checked } = event.target

    this.setState({
      createNewCaseParam: {
        ...this.state.createNewCaseParam,
        value: checked ?? false,
      },
    })
  }

  private readonly handleClose = () => {
    const { closeDialog } = this.props

    closeDialog(ExecutableDialog.NAME)
  }

  private readonly handleSelectCase = (event: Event & { target: HTMLSelectElement }) => {
    this.setState({ selectedCase: event.target.value })
  }

  private readonly handleNewCaseInputChange = (event: Event & { target: HTMLInputElement }) => {
    const { name, value, checked } = event.target

    // did this this way because of typescript type warning with [name]: value
    if (name === 'newCaseName') {
      this.setState({ newCaseName: value })
    }
    else if (name === 'newCaseDescription') {
      this.setState({ newCaseDescription: value })
    }
    else if (name === 'loadNewCase') {
      this.setState({ createNewCaseParam: { ...this.state.createNewCaseParam, autoLoad3D: checked } })
    }
  }

  private readonly handleSubmit = () => {
    const { parameterValues, uuid } = this.state

    const { params, executeExecutableDefinition, currentProject, currentSimulationCase, setExecutionState, execution } = this.props
    const { cases, id } = (params?.definition ?? {}) as ExecutableDefinition
    const values = parameterValues ?? {}
    const trueSelectedCaseId = this.getSelectedCaseId()
    const caseValues = values[trueSelectedCaseId ?? ''] ?? {}
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCaseId)

    if (!trueSelectedCaseId || !currentCase) {
      return
    }

    const executionInputs: { [key: string]: any } = { }

    Object.keys(caseValues).forEach(paramId => {
      const param = currentCase.parameters.find(param => (param.id === paramId && !param.hidden))

      if (!param || !param.name) {
        return
      }

      executionInputs[param.id] = caseValues[paramId]
    })

    const parameterIdValueMap = currentCase.parameters.reduce((acc, parameter) => ({
      ...acc,
      [parameter.id]: caseValues[parameter.id] !== undefined ? caseValues[parameter.id] : parameter.value,
    }), {}) ?? {}

    setExecutionState(currentSimulationCase.id, id, trueSelectedCaseId, ExecutionStepState.Running)

    const newCaseData = {
      newCaseDescription: this.state.newCaseDescription ?? '',
      newCaseName: this.state.newCaseName ?? '',
      autoLoad3D: this.state.createNewCaseParam?.autoLoad3D ?? false,
      newCase: this.state.createNewCaseParam?.value ?? false,
    }

    executionInputs['newCaseData'] = { ...newCaseData }

    this.setState({
      waitingForExecutions: [
        ...this.state.waitingForExecutions,
        `${currentSimulationCase.id}_${id}_${trueSelectedCaseId}`,
      ],
    })

    executeExecutableDefinition(
      currentProject.id,
      currentSimulationCase.id,
      id,
      trueSelectedCaseId,
      parameterIdValueMap,
      uuid,
      newCaseData,
      [ uuid ],
      executionInputs,
      execution?.id,
    )

    this.reset()
  }

  private readonly handleSetSelectedStepCaseOptionRecord = (stepIndex: number, selectedCaseOptionIndex: number) => {
    // if changed then set lastChangedStep with selectedOptionIndex: ${oldSelectedStep}
    const newState = this.registerCaseOptionChange(stepIndex, selectedCaseOptionIndex)

    // fetch the new selected option's dynamic options
    this.fetchNewSelectedCaseOptionsDynamicOptions(stepIndex, selectedCaseOptionIndex)

    this.setState({
      selectedStepCaseOptionRecord: {
        ...this.state.selectedStepCaseOptionRecord,
        [stepIndex]: selectedCaseOptionIndex,
      },
      ...newState,
    })
  }

  private readonly restorePreviousExecutionState = async (lastSuccessfulExecution: ExecutionEntity) => {
    const selectedStepCaseOptionRecord: Record<number, number> = {}
    const { params } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const trueSelectedCase = this.getSelectedCaseId()
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    lastSuccessfulExecution.steps.forEach(({ caseOptionId, index }) => {
      if (!caseOptionId) {
        return
      }

      const optionIndex = currentCase?.steps?.[index]?.caseOptions?.findIndex(option => option.id === caseOptionId)

      if (optionIndex === undefined || optionIndex === -1) {
        return
      }

      selectedStepCaseOptionRecord[index] = optionIndex
    })
    this.handleInitStepsExpandedState()

    const lastCompletedStepIndex = lastSuccessfulExecution.steps.length - 1

    this.handleInitInputsWithPreviousSubmission(lastSuccessfulExecution)
    await this.handleInitDynamicOptions(
      lastSuccessfulExecution.definitionCaseId,
      lastCompletedStepIndex,
      lastSuccessfulExecution,
    )

    this.setState({
      lastCompletedStepIndex,
      selectedStepCaseOptionRecord,
    })
  }

  private readonly fillExecutionInputs = (
    currentCase: Case,
    caseValues: Record<string, any>,
    executionInputs: Record<number | string, Record<string, any>>,
    lastStepIndex: number,
  ) => {
    const { selectedStepCaseOptionRecord } = this.state

    if (!currentCase.steps) {
      return
    }

    for (let stepIndex = 0; stepIndex <= lastStepIndex; stepIndex++) {
      const currentStep = currentCase.steps[stepIndex]
      const isCaseOption = Boolean(currentStep?.caseOptions)
      const chosenCaseOption = selectedStepCaseOptionRecord[stepIndex] ?? 0

      const step = executionInputs[stepIndex] = {} as Record<string, any>

      Object.keys(caseValues).forEach(paramId => {
        let param: any

        if (isCaseOption) {
          param = (currentStep?.caseOptions as CaseOption[])?.[chosenCaseOption]
            ?.parameters
            ?.find((param: any) => (param.id === paramId && !param.hidden))

          step['chosenCaseOptionIndex'] = chosenCaseOption
        }
        else {
          param = currentStep?.parameters?.find(param => (param.id === paramId && !param.hidden))
        }

        if (param?.ref) {
          const { stepIndex, parameterIndex } = this.getStepAndParameterIndexesFromRef(param.ref)

          if (stepIndex === undefined || parameterIndex === undefined || !currentCase || !currentCase.steps) {
            return
          }

          const referencedStep = currentCase.steps[stepIndex]

          if (!referencedStep) {
            return
          }

          let referencedParameter: Parameter | undefined

          if (referencedStep.caseOptions) {
            const chosenOptionIndex = selectedStepCaseOptionRecord[stepIndex]
            const chosenOption = referencedStep.caseOptions[chosenOptionIndex ?? -1]

            if (!chosenOption) {
              return
            }

            referencedParameter = chosenOption.parameters[parameterIndex]

            if (referencedParameter && chosenOptionIndex !== undefined) {
              referencedParameter.chosenOptionIndex = chosenOptionIndex
            }
          }
          else {
            referencedParameter = referencedStep.parameters[parameterIndex]
          }

          param = referencedParameter
        }

        if (!param || !param.name) {
          return
        }

        step[param.id] = caseValues[paramId]
      })
    }
  }

  private readonly isSomeXMLDataBaseKeyActive = (
    activeXMLDataBaseKeysForExecutablesDialog: string[] | undefined,
    XMLDataBase: CasterXMLDataBase,
  ) => (
    activeXMLDataBaseKeysForExecutablesDialog &&
    Object.entries(XMLDataBase).some(([ key, value ]) => activeXMLDataBaseKeysForExecutablesDialog.includes(key) && value === '1')
  )

  private readonly mapParameterRefToParameter = (
    isCaseOption: boolean,
    currentCase: Case,
    currentStep: Step,
    caseValues: Record<string, any>,
    chosenCaseOption: number,
  ) => {
    const { selectedStepCaseOptionRecord } = this.state
    let parameters = []
    let referenceError = false

    parameters = (
      isCaseOption
        ? (currentStep.caseOptions as CaseOption[])?.[chosenCaseOption]
        : currentStep
    )
      ?.parameters
      ?.map((parameter: Parameter) => {
        if (parameter.ref) {
          const referencedParameter = this.getParameterByRef(parameter.ref)
          const { stepIndex } = this.getStepAndParameterIndexesFromRef(parameter.ref)

          if (!referencedParameter) {
            enqueueSnackbar(
              'There was an error with a parameter reference, please review the executables.json file',
              { variant: 'error', autoHideDuration: 4000 },
            )
            referenceError = true

            return parameter
          }

          if (
            stepIndex !== undefined &&
            currentCase.steps?.[stepIndex]?.caseOptions &&
            selectedStepCaseOptionRecord[stepIndex] !== undefined
          ) {
            referencedParameter.chosenOptionIndex = selectedStepCaseOptionRecord[stepIndex]
          }

          return {
            ...parameter,
            ...referencedParameter,
            value: caseValues[referencedParameter.id] !== undefined
              ? caseValues[referencedParameter.id]
              : referencedParameter.value,
          }
        }

        return {
          ...parameter,
          value: caseValues[parameter.id] !== undefined ? caseValues[parameter.id] : parameter.value,
        }
      }) ?? []

    return { parameters, referenceError }
  }

  private readonly fetchNewSelectedCaseOptionsDynamicOptions = (stepIndex: number, selectedCaseOptionIndex: number) => {
    const { currentProject, currentSimulationCase, execution } = this.props

    const { lastUUIDsUsedInExecutables } = this.state
    const { params } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const trueSelectedCase = this.getSelectedCaseId()
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    if (!currentCase || currentCase.steps === undefined) {
      return
    }

    const currentStep = (currentCase?.steps ?? [])[stepIndex]

    if (!currentStep || !currentStep.caseOptions) {
      return
    }

    const chosenCaseOption = currentStep.caseOptions[selectedCaseOptionIndex]

    let parametersWithOptions = chosenCaseOption?.parameters?.filter(param => (!param.hidden && param.optionsFile))

    if (!parametersWithOptions || (parametersWithOptions.length === 0)) {
      return
    }

    parametersWithOptions = parametersWithOptions.map(parameter => ({ ...parameter, context: currentCase.context }))

    if (stepIndex > 0 && lastUUIDsUsedInExecutables && lastUUIDsUsedInExecutables[stepIndex - 1]) {
      parametersWithOptions = parametersWithOptions
        .map(param => ({ ...param, previousStepUUID: lastUUIDsUsedInExecutables[stepIndex - 1] }))
    }

    ApiClient
      .post(
        `${useConfig().apiBaseURL}/executable/dynamic-options`,
        {
          data: {
            parameters: parametersWithOptions,
            projectId: currentProject.id,
            caseId: currentSimulationCase.id, 
            executionId: execution?.id,
          }, 
        },
      )
      .then(({ options, errors = [] }) => {
        if (errors.length) {
          enqueueSnackbar(
            'Error while fetching dynamic options, watch logs for details',
            { variant: 'error', autoHideDuration: 3000 },
          )
          // eslint-disable-next-line no-console
          console.log('path: ', errors)

          return
        }

        this.setState({
          dynamicOptions: {
            ...this.state.dynamicOptions,
            ...options,
          },
        })
      })
      .catch(err => {
        // eslint-disable-next-line no-console
        console.log('error fetching dynamic options')
        // eslint-disable-next-line no-console
        console.log(err)
      })
  }

  private readonly onSubmitStep = (stepId: string) => {
    const { parameterValues, uuid, selectedStepCaseOptionRecord } = this.state

    const {
      params,
      currentProject,
      currentSimulationCase,
      setManyExecutionStates,
      executeExecutableDefinition,
      execution,
    } = this.props

    const { cases, id } = (params?.definition ?? {}) as ExecutableDefinition
    const values = parameterValues ?? {}
    const trueSelectedCaseId = this.getSelectedCaseId()
    const caseValues = values[trueSelectedCaseId ?? ''] ?? {}
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCaseId)

    if (!trueSelectedCaseId || !currentCase || currentCase.steps === undefined) {
      return
    }

    const currentStep = currentCase.steps.find(step => step.id === stepId)

    if (!currentStep) {
      return
    }
    
    const isCaseOption = Boolean(currentStep.caseOptions)

    if (!currentStep) {
      return
    }

    const stepIndex = currentCase.steps.indexOf(currentStep)
    const chosenCaseOption = selectedStepCaseOptionRecord[stepIndex] ?? 0
    const isLastStep = stepIndex === currentCase.steps.length - 1

    const executionInputs: Record<string, Record<string, any>> = {}

    // fill the execution inputs
    this.fillExecutionInputs(currentCase, caseValues, executionInputs, stepIndex)

    // map parameter ref to referenced parameter
    const { parameters, referenceError } = this.mapParameterRefToParameter(
      isCaseOption,
      currentCase,
      currentStep,
      caseValues,
      chosenCaseOption,
    )

    if (referenceError) {
      return
    }

    const newExecutionState: any = {}

    newExecutionState[`${currentSimulationCase.id}_${id}_${trueSelectedCaseId}_${stepId}`] = ExecutionStepState.Running

    const stepsLength = currentCase.steps.length

    for (let i = stepsLength - 1; i > stepIndex; i--) {
      const step = currentCase.steps[i]

      newExecutionState[`${currentSimulationCase.id}_${id}_${trueSelectedCaseId}_${step?.id}`] = 'delete'
    }

    setManyExecutionStates(newExecutionState)

    const newCaseData = isLastStep
      ? {
        newCaseDescription: this.state.newCaseDescription ?? '',
        newCaseName: this.state.newCaseName ?? '',
        autoLoad3D: this.state.createNewCaseParam?.autoLoad3D ?? false,
        newCase: this.state.createNewCaseParam?.value ?? false,
      }
      : {
        newCaseDescription: '',
        newCaseName: '',
        autoLoad3D: false,
        newCase: false,
      }

    if (executionInputs[stepIndex]) {
      executionInputs[stepIndex]['newCaseData'] = { ...newCaseData }
    }

    // delete next steps case values
    const newCaseValues = { ...caseValues }

    for (let i = stepIndex + 1; i < currentCase.steps.length; i++) {
      const step = currentCase.steps[i]

      if (step?.caseOptions) {
        const caseOptions = step.caseOptions

        if (!caseOptions) {
          continue
        }

        for (const option of caseOptions) {
          for (const param of option.parameters) {
            delete newCaseValues[param.id]
          }
        }

        continue
      }

      const params = step?.parameters ?? []

      for (const param of params) {
        delete newCaseValues[param.id]
      }
    }

    const newSelectedStepCaseOptionRecord = { ...selectedStepCaseOptionRecord }

    Object.keys(newSelectedStepCaseOptionRecord).forEach(key => {
      const numberedKey = Number(key)

      if (numberedKey > stepIndex) {
        newSelectedStepCaseOptionRecord[numberedKey] = -1
      }
    })

    const newLastUUIDsUsedInExecutables = this.state.lastUUIDsUsedInExecutables.slice(0, stepIndex)

    newLastUUIDsUsedInExecutables.push(uuid)

    this.setState({
      parameterValues: {
        ...parameterValues,
        [trueSelectedCaseId]: {
          ...newCaseValues,
        },
      },
      waitingForExecutions: [
        ...this.state.waitingForExecutions,
        `${currentSimulationCase.id}_${id}_${trueSelectedCaseId}_${stepId}`,
      ],
      selectedStepCaseOptionRecord: newSelectedStepCaseOptionRecord,
      lastUUIDsUsedInExecutables: newLastUUIDsUsedInExecutables,
    })

    const parameterIdValueMap = parameters.reduce((acc, parameter) => ({
      ...acc,
      [parameter.id]: caseValues[parameter.id] !== undefined ? caseValues[parameter.id] : parameter.value,
    }), {}) ?? {}

    const chosenCaseOptionId = currentStep.caseOptions ? currentStep.caseOptions[chosenCaseOption]?.id : undefined

    executeExecutableDefinition(
      currentProject.id,
      currentSimulationCase.id,
      id,
      trueSelectedCaseId,
      parameterIdValueMap,
      uuid,
      newCaseData,
      newLastUUIDsUsedInExecutables,
      executionInputs,
      execution?.id,
      stepId,
      isCaseOption ? chosenCaseOptionId : undefined,
    )

    this.reset()
  }

  private readonly onInputChange = (event: Event & { target: HTMLInputElement }, stepIndex?: number) => {
    const { parameterValues, selectedStepCaseOptionRecord } = this.state
    const { params } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const values = parameterValues ?? {}
    const trueSelectedCaseId = this.getSelectedCaseId()

    if (!trueSelectedCaseId) {
      return
    }

    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCaseId)

    const paramId = event.target.name
    const optionIndex = stepIndex !== undefined ? selectedStepCaseOptionRecord[stepIndex] : undefined
    const currentParam = this.getCurrentParam(currentCase, paramId, stepIndex, optionIndex)
    const rawValue = currentParam?.type?.startsWith('bool') ? event.target.checked : event.target.value

    values[trueSelectedCaseId] = values[trueSelectedCaseId] ?? {}

    const oldValue = values[trueSelectedCaseId][paramId]

    values[trueSelectedCaseId][paramId] = rawValue

    // if step index is the same as the lastChanged.index save the original values with paramId as key and original
    // value as value
    // if paramId already exists in lastChangedStep as key, if the new value is equal to the original value
    // delete the key, if no keys other than 'index' remain, then set index to -1
    // if stepIndex is different from lastChangedStep.index it must be smaller, because the next steps are disabled
    // then reset the current lastChangedStep with the keys stored in state, then set the new values
    if (stepIndex === undefined) {
      this.setState({ parameterValues: values, lastChangedStep: { index: -1 } })

      return
    }

    this.registerLastChangedStep(trueSelectedCaseId, values, currentParam, stepIndex, paramId, oldValue, rawValue)
  }

  private readonly onSliderChange = (name: string, value: number, stepIndex?: number) => {
    const { parameterValues, selectedStepCaseOptionRecord } = this.state
    const { params } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const values = parameterValues ?? {}
    const trueSelectedCaseId = this.getSelectedCaseId()

    if (!trueSelectedCaseId) {
      return
    }

    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCaseId)

    const paramId = name
    const optionIndex = stepIndex !== undefined ? selectedStepCaseOptionRecord[stepIndex] : undefined
    const currentParam = this.getCurrentParam(currentCase, paramId, stepIndex, optionIndex)

    values[trueSelectedCaseId] = values[trueSelectedCaseId] ?? {}

    const oldValue = values[trueSelectedCaseId][paramId]

    values[trueSelectedCaseId][paramId] = value

    if (stepIndex === undefined) {
      this.setState({ parameterValues: values, lastChangedStep: { index: -1 } })

      return
    }

    const valueString = String(value)

    this.registerLastChangedStep(trueSelectedCaseId, values, currentParam, stepIndex, paramId, oldValue, valueString)
  }

  private readonly onFileUpload = async (paramId: string, stepId?: string) => {
    const { parameterValues, uuid, lastChangedStep } = this.state
    const { params, currentProject, currentSimulationCase, setExecutionData, execution } = this.props
    const { id, cases } = (params?.definition ?? {}) as ExecutableDefinition
    const trueSelectedCaseId = this.getSelectedCaseId()
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCaseId)

    if (!trueSelectedCaseId || !currentCase) {
      return
    }
    
    const param = this.getParamById(currentCase, paramId)
    
    if (!param) {
      return
    }
  
    const { fileExtension } = param

    const result = await Util.openUploadFileDialog(
      fileExtension ?? '',
      '/executable/upload',
      'post',
      (formData: FormData) => {
        formData.append('projectId', currentProject.id)
        formData.append('simulationCaseId', currentSimulationCase.id)
        formData.append('definitionId', id)
        formData.append('caseId', currentCase.id)

        if (stepId !== undefined) {
          formData.append('stepId', String(stepId))
        }

        formData.append('paramId', paramId)
        formData.append('uuid', uuid)

        if (execution) {
          formData.append('executionId', execution.id)
        }
      },
      // set uploadingFile to true callback, cannot be done before because the module cant detect the 'cancel' event
      // and it will not be able to set the uploadingFile to false 
      () => this.setState({ uploadingFileParameterId: paramId }),
    )

    const parameterValue = parameterValues[trueSelectedCaseId] = parameterValues[trueSelectedCaseId] ?? {}

    parameterValue[paramId] = result.fileId
    setExecutionData(result.execution)

    this.setState({ uploadingFileParameterId: '' })
    
    if (stepId === undefined) {
      this.setState({ parameterValues, lastChangedStep: { index: -1 } })
      
      return
    }
    
    const stepIndex = currentCase.steps?.findIndex(step => step.id === stepId)
    const oldValue = parameterValue[paramId]
    let newLastChangedStep: any = { index: -1 }

    if (lastChangedStep.index === -1) {
      newLastChangedStep = { index: stepIndex, [paramId]: oldValue }
    }
    else if (lastChangedStep.index === stepIndex) {
      if ((lastChangedStep as any)[paramId] === undefined) {
        newLastChangedStep = {
          ...lastChangedStep,
          [paramId]: oldValue,
        }
      }
      else {
        this.setState({ parameterValues })

        return
      }
    }
    else {
      // stepIndex is smaller
      Object
        .keys(lastChangedStep)
        .filter(key => key !== 'index')
        .forEach(key => {
          parameterValue[key] = (lastChangedStep as any)[key]
        })

      newLastChangedStep = { index: stepIndex, [paramId]: oldValue }
    }

    this.setState({ parameterValues, lastChangedStep: newLastChangedStep })
  }

  private readonly onToggleStep = (index: number) => {
    const { stepIsExpanded: expandedState } = this.state
    const newStepIsExpandedState = { ...expandedState }

    newStepIsExpandedState[index] = !newStepIsExpandedState[index]
    this.setState({ stepIsExpanded: newStepIsExpandedState })
  }

  private readonly registerCaseOptionChange = (stepIndex: number, newSelectedCaseOptionIndex: number) => {
    const { parameterValues, selectedStepCaseOptionRecord, lastChangedStep } = this.state
    const values = parameterValues ?? {}
    const trueSelectedCaseId = this.getSelectedCaseId()
    const currentSelectedCaseOption = selectedStepCaseOptionRecord[stepIndex]
    let newLastChangedStep: any = { index: -1 }

    if (lastChangedStep.index === -1) {
      newLastChangedStep = { index: stepIndex, selectedCaseOptionIndex: currentSelectedCaseOption }
    }
    else if (lastChangedStep.index === stepIndex) {
      if ((lastChangedStep as any).selectedCaseOptionIndex === undefined) {
        newLastChangedStep = {
          ...lastChangedStep,
          selectedCaseOptionIndex: currentSelectedCaseOption,
        }
      }
      else if ((lastChangedStep as any).selectedCaseOptionIndex === newSelectedCaseOptionIndex) {
        const amountOfChangedInputs = Object.keys(lastChangedStep).length - 1 // -1 because of index

        if (amountOfChangedInputs > 1) {
          newLastChangedStep = { ...lastChangedStep }
          delete newLastChangedStep.selectedCaseOptionIndex
        }
        else {
          newLastChangedStep = { index: -1 }
        }
      }
      // the original value should already be stored in lastChangedStep object in state
      else {
        return { parameterValues: values, lastChangedStep: { ...lastChangedStep } }
      }
    }
    else {
      // stepIndex is smaller
      Object
        .keys(lastChangedStep)
        .filter(key => key !== 'index')
        .forEach(key => {
          if (values[trueSelectedCaseId ?? '']) {
            values[trueSelectedCaseId!]![key] = (lastChangedStep as any)[key]
          }
        })

      newLastChangedStep = { index: stepIndex, selectedCaseOptionIndex: currentSelectedCaseOption }
    }

    return { lastChangedStep: newLastChangedStep, parameterValues: values }
  }

  private readonly getChosenCaseOption = (step: Step, stepIndex: number): {
    chosenCaseOption: CaseOption | null
    selectors: { key: string, value: string, disabled?: boolean | undefined }[]
  } => {
    const { selectedStepCaseOptionRecord } = this.state
    const { caseOptions } = step

    if (!caseOptions) {
      return { chosenCaseOption: null, selectors: [] }
    }

    const chosenOptionIndex = selectedStepCaseOptionRecord[stepIndex]
    let chosenCaseOption: CaseOption | null = chosenOptionIndex !== undefined ? caseOptions[chosenOptionIndex] ?? null : null

    const selectors: { key: string, value: string, disabled?: boolean }[] = caseOptions
      .filter(option => !this.getIsOptionDisabled(option))
      .map(option => ({ key: option.name, value: option.name }))

    selectors.unshift({ key: 'default', value: 'Please select an option', disabled: true })

    if (chosenCaseOption && !selectors.map(option => option.key).includes(chosenCaseOption.name)) {
      chosenCaseOption = null
    }

    return { chosenCaseOption, selectors }
  }

  private readonly getIsOptionDisabled = ({ name, dependsOn }: CaseOption) => {
    if (!dependsOn) {
      return false
    }

    const { selectedStepCaseOptionRecord } = this.state

    try {
      const [ stepIndex, optionIndex ] = dependsOn.split('.').map((str, index) => {
        if (index === 0) {
          return Number(str.split('step')[1])
        }

        return Number(str.split('option')[1])
      })

      if (Number.isNaN(stepIndex) || Number.isNaN(optionIndex)) {
        // eslint-disable-next-line no-console
        console.log(`error with option dependency in option: ${name}`)
        // eslint-disable-next-line no-console
        console.log('error parsing - make sure to use the example syntax: \'step1.option2\'')

        return true
      }

      return (selectedStepCaseOptionRecord[stepIndex! - 1] !== optionIndex! - 1)
    }
    catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error)
      // eslint-disable-next-line no-console
      console.log(`error with option dependency in option: ${name}`)
    }

    return false
  }

  private readonly registerLastChangedStep = (
    trueSelectedCaseId: string,
    values: Record<string, Record<string, any>>,
    currentParam: Parameter | null | undefined,
    stepIndex: number,
    paramId: string,
    oldValue: any,
    rawValue: boolean | string,
  ) => {
    const { lastChangedStep } = this.state
    let newLastChangedStep: any = { index: -1 }

    if (lastChangedStep.index === -1) {
      newLastChangedStep = { index: stepIndex, [paramId]: oldValue ?? currentParam?.value }
    }
    else if (lastChangedStep.index === stepIndex) {
      if ((lastChangedStep as any)[paramId] === undefined) {
        newLastChangedStep = {
          ...lastChangedStep,
          [paramId]: oldValue ?? currentParam?.value,
        }
      }
      else if ((lastChangedStep as any)[paramId] === rawValue) {
        const amountOfChangedInputs = Object.keys(lastChangedStep).length - 1 // -1 because of index

        if (amountOfChangedInputs > 1) {
          newLastChangedStep = { ...lastChangedStep }
          delete newLastChangedStep[paramId]
        }
        else {
          newLastChangedStep = { index: -1 }
        }
      }
      // the original value should already be stored in lastChangedStep object in state
      else {
        this.setState({ parameterValues: values })

        return
      }
    }
    else {
      // stepIndex is smaller
      Object
        .keys(lastChangedStep)
        .filter(key => key !== 'index')
        .forEach(key => {
          if (values[trueSelectedCaseId]) {
            values[trueSelectedCaseId][key] = (lastChangedStep as any)[key]
          }
        })

      newLastChangedStep = { index: stepIndex, [paramId]: oldValue ?? currentParam?.value }
    }

    // set new state
    this.setState({ lastChangedStep: newLastChangedStep, parameterValues: values })
  }

  private readonly getCurrentParam = (currentCase: Case | undefined, paramId: string, stepIndex?: number, optionIndex?: number) => {
    if (!currentCase) {
      return null
    }

    if (stepIndex === undefined) {
      return currentCase?.parameters?.find(param => param.id === paramId)
    }

    if (!currentCase.steps) {
      return currentCase?.parameters?.find(param => param.id === paramId)
    }

    const currentStep = currentCase.steps[stepIndex]

    if (!currentStep) {
      return null
    }

    if (!currentStep.caseOptions) {
      return currentStep.parameters.find(param => param.id === paramId)
    }

    if (
      optionIndex === undefined ||
      !currentStep.caseOptions[optionIndex] ||
      !currentStep.caseOptions[optionIndex].parameters
    ) {
      return null
    }

    return currentStep.caseOptions[optionIndex].parameters.find(param => param.id === paramId)
  }

  private readonly getParamById = ({ parameters, steps }: Case, paramId: string) => {
    if (steps) {
      const auxArray: Parameter[] = []

      steps.forEach(step => {
        if (step.caseOptions) {
          for (const option of step.caseOptions) {
            if (!option.parameters) {
              continue
            }

            auxArray.push(...option.parameters)
          }

          return
        }

        auxArray.push(...step.parameters)
      })

      return auxArray.find(param => param.id === paramId)
    }

    return parameters?.find(param => param.id === paramId)
  }

  private readonly getParametersWithDynamicsOptionsFromSelectedCaseOptions = (
    steps: Step[],
    parametersWithOptions: (Parameter | { stepIndex?: number })[],
    executionSteps: ExecutionStepEntity[],
    lastCompletedStepIndex: number,
  ) => {
    executionSteps
      .filter(({ index }) => index <= lastCompletedStepIndex)
      .forEach(({ index, caseOptionId }) => {
        const step = steps[index]

        if (!step || !step.caseOptions) {
          return
        }

        const option = step.caseOptions.find(option => option.id === caseOptionId)

        if (!option?.parameters) {
          return
        }

        const paramsWithOptionsFile: any = option
          .parameters
          .filter(param => param.optionsFile)

        parametersWithOptions.push(...paramsWithOptionsFile)
      })
  }

  private readonly getParametersWithDynamicOptions = (
    steps: Step[],
    parameters: Parameter[],
    lastCompletedStepIndex?: number,
  ) => {
    const parametersWithOptions: { stepIndex?: number }[] | Parameter = []

    if (steps.length > 0) {
      const stepsToLoad = lastCompletedStepIndex !== undefined
        ? (lastCompletedStepIndex === steps.length - 1 ? lastCompletedStepIndex : lastCompletedStepIndex + 1)
        : 0

      for (let i = 0; i <= stepsToLoad; i++) {
        const step = steps[i]

        if (!step || step.caseOptions) {
          continue
        }

        if (!step.parameters || !Array.isArray(step.parameters)) {
          // fallback in case executables is not defined properly
          continue
        }

        for (let j = 0; j < step.parameters.length; j++) {
          const param = step.parameters[j]

          if (!param?.hidden && param?.optionsFile) {
            const parameterObject: any = { ...param, stepIndex: i }

            parametersWithOptions.push(parameterObject)
          }
        }
      }

      return parametersWithOptions
    }

    return parameters.filter(param => (!param.hidden && param.optionsFile))
  }

  private readonly getStepAndParameterIndexesFromRef = (ref: string) => {
    const [ stepPart, parameterPart ] = ref.split('.')
    const stepIndex = Number(stepPart?.split('step')[1])
    const parameterIndex = Number(parameterPart?.split('parameter')[1])

    if (Number.isNaN(stepIndex) || Number.isNaN(parameterIndex)) {
      return {}
    }

    return { stepIndex: stepIndex - 1, parameterIndex: parameterIndex - 1 }
  }

  private readonly getParameterByRef = (parameterRef: string) => {
    const { selectedStepCaseOptionRecord } = this.state
    const { params } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const trueSelectedCase = this.getSelectedCaseId()
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCase)

    const { stepIndex, parameterIndex } = this.getStepAndParameterIndexesFromRef(parameterRef)

    if (stepIndex === undefined || parameterIndex === undefined || !currentCase || !currentCase.steps) {
      return null
    }

    const currentStep = currentCase.steps[stepIndex]

    if (currentStep?.caseOptions) {
      const selectedOptionIndex = selectedStepCaseOptionRecord[stepIndex]
      const selectedOptions = currentStep.caseOptions[selectedOptionIndex ?? -1]

      if (
        !selectedOptions ||
        !selectedOptions.parameters ||
        !selectedOptions.parameters[parameterIndex] ||
        selectedOptions.parameters[parameterIndex].ref
      ) {
        // eslint-disable-next-line no-console
        console.log(`error getting parameter by ref with ref: ${parameterRef}`)

        if (selectedOptions?.parameters[parameterIndex]?.ref) {
          // eslint-disable-next-line no-console
          console.log('please use refs only to point to the original parameter, not another ref')
        }

        return null
      }

      return selectedOptions.parameters[parameterIndex] || null
    }

    if (!currentStep?.parameters) {
      return null
    }

    return currentStep.parameters[parameterIndex]
  }

  private readonly selectPreviousUsedCase = (lastExecution: ExecutionEntity | null) => {
    if (!lastExecution) {
      return
    }

    const { definitionCaseId } = lastExecution
    
    this.setState({ selectedCase: definitionCaseId })

    return definitionCaseId
  }

  private readonly reset = () => {
    this.setState({
      uuid: uuid(),
    })
  }

  private readonly isValid = (stepIndex?: number) => {
    const { parameterValues, selectedStepCaseOptionRecord } = this.state
    const { params } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition
    const values = parameterValues ?? {}
    const trueSelectedCaseId = this.getSelectedCaseId()
    const caseValues = values[trueSelectedCaseId ?? ''] ?? {}
    const currentCase = cases.find(definitionCase => definitionCase.id === trueSelectedCaseId)
    const currentStep = stepIndex === undefined ? null : (currentCase?.steps ?? [])[stepIndex]

    if (!currentStep && stepIndex !== undefined) {
      return false
    }

    const isCaseOption = Boolean(currentStep?.caseOptions)
    const chosenCaseOptionIndex = selectedStepCaseOptionRecord[stepIndex ?? 0]

    if (isCaseOption && (chosenCaseOptionIndex === undefined || chosenCaseOptionIndex === -1)) {
      return false
    }

    const parameters = currentStep
      ? (isCaseOption
        ? (currentStep.caseOptions as CaseOption[])[chosenCaseOptionIndex!]?.parameters?.filter(p => !p.ref)
        : currentStep.parameters.filter(p => !p.ref))
      : currentCase?.parameters
    let valid = true

    for (const param of parameters ?? []) {
      const value = caseValues[param.id] !== undefined ? caseValues[param.id] : param.value

      let valueValid = Boolean(param.hidden) || value !== undefined

      if (valueValid && !param.hidden) {
        if (param.options || param.optionsFile) {
          valueValid = value !== 'default'
        }
        else {
          switch (param.type) {
            case 'string':
              valueValid = typeof value === 'string' && value.length > 0
              break
            case 'number':
              valueValid = Logic.numberValid(value, param) // TODO: validate step
              break
            case 'bool':
            case 'boolean':
              valueValid = typeof value === 'boolean' && param.required ? value : true
              break
            case 'file':
              valueValid = typeof value === 'string' && value.length > 0
              break
            default:
              valueValid = false
          }
        }
      }

      if (valid) {
        valid = valueValid
      }
    }

    return valid
  }

  private readonly shouldShowNewCaseInputs = () => {
    const { hidden = false, value = false } = this.state.createNewCaseParam ?? {}

    return (!hidden && value)
  }

  private readonly getNewCaseInputTitles = (showNewCaseInputs: boolean) => {
    const caseNameTitle = this.state.createNewCaseParam?.caseNameTitle?.length && showNewCaseInputs
      ? this.state.createNewCaseParam?.caseNameTitle
      : 'New Case Name'

    const caseDescriptionTitle = this.state.createNewCaseParam?.caseDescriptionTitle?.length && showNewCaseInputs
      ? this.state.createNewCaseParam?.caseDescriptionTitle
      : 'New Case Description'

    return {
      caseNameTitle,
      caseDescriptionTitle,
    }
  }

  private readonly isSomeStepLoading = (fullCaseId: string) => {
    const { executionStateDictionary } = this.props
    const filteredKeys = Object.keys(executionStateDictionary).filter(key => key.includes(fullCaseId))

    return filteredKeys.some(key => executionStateDictionary[key] === ExecutionStepState.Running)
  }

  private readonly isStepLoading = (fullCaseId: string, stepId: string) => {
    const { executionStateDictionary } = this.props
    const filteredKeys = Object.keys(executionStateDictionary).filter(key => key.includes(fullCaseId))
    const idWithStep = `${fullCaseId}_${stepId}`

    return (filteredKeys.includes(idWithStep) && executionStateDictionary[idWithStep] === ExecutionStepState.Running)
  }

  private readonly isStepDisabled = (stepIndex: number) => {
    // all previous steps must have been completed
    const { lastCompletedStepIndex, lastChangedStep } = this.state
    const { currentSimulationCase, params } = this.props
    const { id } = (params?.definition ?? {}) as ExecutableDefinition
    const trueSelectedCase = this.getSelectedCaseId()
    const stateKey = `${currentSimulationCase.id}_${id}_${trueSelectedCase}`

    if (stepIndex === 0) {
      return this.isSomeStepLoading(stateKey)
    }

    const lastChangedStepIndex = lastChangedStep.index as number

    return (lastCompletedStepIndex < stepIndex - 1) ||
      (lastChangedStepIndex !== undefined && lastChangedStepIndex < stepIndex && lastChangedStep.index !== -1)
  }

  private readonly getSelectedCaseId = () => {
    const { selectedCase } = this.state
    const { params } = this.props
    const { cases } = (params?.definition ?? {}) as ExecutableDefinition

    return selectedCase && cases.find($case => $case.id === selectedCase) ? selectedCase : cases[0]?.id
  }

  public override render () {
    const { parameterValues, error, createNewCaseParam, dynamicOptions, uploadingFileParameterId } = this.state
    const {
      t,
      params,
      executionStateDictionary,
      currentSimulationCase,
      authenticationData,
      Caster,
      featureFlags,
      execution,
    } = this.props
    const { id, name, cases, selectCaseLabel } = (params?.definition ?? {}) as ExecutableDefinition
    const isCaseOwner = currentSimulationCase && currentSimulationCase?.userId === authenticationData?.id

    if (!cases) {
      return null
    }

    const trueSelectedCaseId = this.getSelectedCaseId()
    const values = parameterValues ?? {}
    const caseValues = values[trueSelectedCaseId ?? ''] ?? {}
    const caseObject = cases.find(definitionCase => definitionCase.id === trueSelectedCaseId)
    const { steps = [], parameters = [], createNewCase = {} } = caseObject ?? {}
    const { hidden: createNewCaseHidden } = createNewCase
    const autoLoad = createNewCaseParam?.autoLoad3D
    const showNewCaseInputs = this.shouldShowNewCaseInputs()
    const { caseNameTitle, caseDescriptionTitle } = this.getNewCaseInputTitles(showNewCaseInputs)
    const activeKeyValid = Menu.evaluateActiveKey(caseObject?.activeKey ?? '', Caster)
    const activeKeyMessage = caseObject?.activeKeyMessage ??
      `Case is not executable: condition ${caseObject?.activeKey} not met`

    const caseSelectors = cases.map(definitionCase => ({
      key: definitionCase.id,
      value: definitionCase.name,
      title: definitionCase.description ?? '',
      disabled: !Menu.evaluateActiveKey(definitionCase?.activeKey ?? '', Caster),
    }))

    const stateKey = `${currentSimulationCase.id}_${id}_${trueSelectedCaseId}`
    const running = executionStateDictionary[stateKey] === ExecutionStepState.Running

    const importCaseNameParameter = parameters.find(param => param.setCaseNameFromXML === true)
    const importCaseNameFromXML = caseValues[importCaseNameParameter?.id ?? ''] ?? false

    // TODO: translations

    // TODO: min/max/step validation (set on input)
    return (
      <BaseDialog
        id={DialogID.ExecutableDialog.ID}
        title={name}
        icon='pe-7s-settings'
        header={name}
        onClose={this.handleClose}
        hasScroll
        small
        headerWidth='390px'
        loading={this.state.loadingPreviousExecution}
      >
        <Form>
          {!activeKeyValid && Caster && <Text style={{ color: 'yellow' }}>{activeKeyMessage}</Text>}
          {
            cases?.length > 1 && (
              <Input
                id={DialogID.ExecutableDialog.SelectCaseDropDown}
                name='selectCase'
                type='select'
                label={selectCaseLabel ?? t(`${T}.input.selectCase.label`)}
                title={caseObject?.description ?? t(`${T}.input.selectCase.title`)}
                value={trueSelectedCaseId}
                selectors={caseSelectors}
                onChange={this.handleSelectCase}
                disabled={running || !FeatureFlags.canEditExecutableDialog(featureFlags)}
              />
            )
          }
          {
            (steps.length > 0)
              ? (
                steps.map((step, index) => (
                  step.caseOptions
                    ? (
                      <StepWithOptions
                        handleRunStep={this.onSubmitStep}
                        valid={this.isValid(index)}
                        disabled={this.isStepDisabled(index) || this.isSomeStepLoading(stateKey) || !activeKeyValid}
                        handleExpandStep={this.onToggleStep}
                        step={step}
                        stepIndex={index}
                        stepDefinitionId={step.id}
                        executionSteps={execution?.steps ?? []}
                        executionStepId={execution?.steps?.find(executionStep => executionStep.stepId === step.id)?.id}
                        key={index}
                        caseValues={caseValues}
                        dynamicOptions={dynamicOptions}
                        featureFlags={featureFlags ?? {}}
                        loading={this.isStepLoading(stateKey, step.id)}
                        t={t}
                        handleInputChange={this.onInputChange}
                        handleUploadFile={this.onFileUpload}
                        expanded={this.state.stepIsExpanded[index] ?? false}
                        lastStep={index === steps.length - 1}
                        getParameterByRef={this.getParameterByRef}
                        onCaseOptionChange={this.handleSetSelectedStepCaseOptionRecord}
                        getChosenCaseOption={this.getChosenCaseOption}
                        uploadingFileParameterId={uploadingFileParameterId}
                      />
                    )
                    : (
                      <StepComponent
                        handleRunStep={this.onSubmitStep}
                        valid={this.isValid(index)}
                        disabled={this.isStepDisabled(index) || this.isSomeStepLoading(stateKey) || !activeKeyValid}
                        handleExpandStep={this.onToggleStep}
                        step={step}
                        stepIndex={index}
                        key={index}
                        caseValues={caseValues}
                        dynamicOptions={dynamicOptions}
                        featureFlags={featureFlags ?? {}}
                        loading={this.isStepLoading(stateKey, step.id)}
                        t={t}
                        handleInputChange={this.onInputChange}
                        handleUploadFile={this.onFileUpload}
                        expanded={this.state.stepIsExpanded[index] ?? false}
                        lastStep={index === steps.length - 1}
                        getParameterByRef={this.getParameterByRef}
                        executionSteps={execution?.steps ?? []}
                        executionStepId={execution?.steps?.find(executionStep => executionStep.index === index)?.id}
                        stepDefinitionId={step.id}
                        uploadingFileParameterId={uploadingFileParameterId}
                      />
                    )
                ))
              )
              : parameters?.filter(param => !param.hidden).map((param, index) => (
                <ParameterComponent
                  disabled={running || !activeKeyValid}
                  caseValues={caseValues}
                  dynamicOptions={dynamicOptions}
                  featureFlags={featureFlags ?? {}}
                  loading={running}
                  uploadingFileParameterId={uploadingFileParameterId}
                  parameter={param}
                  t={t}
                  handleInputChange={this.onInputChange}
                  handleSliderChange={this.onSliderChange}
                  handleUploadFile={this.onFileUpload}
                  key={index}
                  executionSteps={execution?.steps ?? []}
                  executionStepId={execution?.steps?.[0]?.id ?? ''}
                />
              ))
          }
          {
            !createNewCaseHidden && (
              <Input
                key='createNewCase'
                name='Create New Case'
                type='checkbox'
                label='Create New Case'
                title='Create New Case'
                value={FeatureFlags.canCreateCase(featureFlags) ? (createNewCaseParam?.value ?? false) : false}
                onChange={this.handleCreateNewCaseChange}
                disabled={
                  !isCaseOwner ||
                  running ||
                  !activeKeyValid || 
                  !FeatureFlags.canEditExecutableDialog(featureFlags) ||
                  !FeatureFlags.canCreateCase(featureFlags)
                }
              />
            )
          }
          {
            showNewCaseInputs && (
              <div>
                <Input
                  key='loadNewCase'
                  name='loadNewCase'
                  type='checkbox'
                  label='Load New Case'
                  title='Load New Case'
                  value={autoLoad}
                  onChange={this.handleNewCaseInputChange}
                  disabled={!isCaseOwner || running || !FeatureFlags.canEditExecutableDialog(featureFlags)}
                />
                {
                  !importCaseNameFromXML && (
                    <>
                      <Input
                        key='newCaseName'
                        name='newCaseName'
                        type='text'
                        label={caseNameTitle ?? 'New Case Name'}
                        title={caseNameTitle ?? 'New Case Name'}
                        value={this.state.newCaseName}
                        onChange={this.handleNewCaseInputChange}
                        disabled={running || !FeatureFlags.canEditExecutableDialog(featureFlags)}
                      />
                      <Input
                        key='newCaseDescription'
                        name='newCaseDescription'
                        type='text'
                        label={caseDescriptionTitle ?? 'New Case Description'}
                        title={caseDescriptionTitle ?? 'New Case Description'}
                        value={this.state.newCaseDescription}
                        onChange={this.handleNewCaseInputChange}
                        disabled={running || !FeatureFlags.canEditExecutableDialog(featureFlags)}
                      />
                    </>
                  )
                }
              </div>
            )
          }
          <Spacer $h={15} $br />
          <Button
            id={DialogID.ExecutableDialog.RunButton}
            overrideHoverColor={submitButtonColorHover}
            overrideColor={submitButtonColor}
            title={t(`${T}.button.run`)}
            type='primary'
            disabled={
              uploadingFileParameterId ||
              ((steps.length > 0) ? this.isStepLoading(stateKey, steps?.[steps.length - 1]?.id ?? '') : running) ||
              !(
                (steps.length > 0)
                  ? (this.isValid(steps.length - 1) && !this.isStepDisabled(steps.length - 1))
                  : this.isValid()
              ) ||
              !activeKeyValid ||
              (
                showNewCaseInputs &&
                !importCaseNameFromXML &&
                (!this.state.newCaseDescription.length || !this.state.newCaseName.length)
              )
            }
            onClick={(steps.length > 0) ? () => this.onSubmitStep(steps[steps.length - 1]?.id ?? '') : this.handleSubmit}
            error={error}
            loading={(steps.length > 0) ? this.isStepLoading(stateKey, steps?.[steps.length - 1]?.id ?? '') : running}
          >
            {t(`${T}.button.run`)}
          </Button>
        </Form>
      </BaseDialog>
    )
  }
}

const composedComponent = compose<any>(withTranslation('application'), connector)(ExecutableDialog)

export default hoistStatics(composedComponent, ExecutableDialog)
