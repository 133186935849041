import * as THREE from 'three'

import { UIComponent } from './UIComponent'
import BaseView from '../views/BaseView'

export default class ComponentManager {
  private readonly components: Map<string, UIComponent> = new Map()

  private readonly view: BaseView

  public constructor (view: BaseView) {
    this.view = view
  }

  public registerComponent (component: UIComponent): void {
    component.init()

    this.view.scene.add(component.group)

    this.components.set(component.id, component)
  }

  public unregisterComponent (component: UIComponent): void {
    component.dispose()

    this.components.delete(component.id)
  }

  public getComponent (id: string): UIComponent | null {
    if (!id) {
      return null
    }

    return this.components.get(id) ?? null
  }

  public resize (): void {
    this.components.forEach(component => component.resize())
  }

  public update (): void {
    this.components.forEach(component => component.update())
  }

  public animate (elapsed: number): void {
    this.components.forEach(component => component.animate(elapsed))
  }

  public handleEvent (object: THREE.Object3D | null, event: string, data: any): void {
    if (!object) {
      this.components.forEach(component => component.handleEvent(event, { object: null, data }))

      return
    }

    const component = this.getComponent(object.parent?.userData['id'])

    if (!component) {
      return
    }

    component.handleEvent(event, { object, data })
  }
}
