import { request } from '@/api'

type CurrentCasterResponse = {
  caster: Caster

  // SLOTS

  // General
  moldSlots: MoldSlotMap
  moldBCAreaSlots: MoldBCAreaSlotMap
  moldFaceSlots: MoldFaceSlotMap
  passlineSlots: PasslineSlotMap
  passlineSectionSlots: PasslineSectionSlotMap
  strandGuideSlots: StrandGuideSlotMap
  segmentGroupSlots: SegmentGroupSlotMap
  supportPointSlots: SupportPointSlotMap
  segmentSlots: SegmentSlotMap
  rollerSlots: RollerSlotMap
  rollerBodySlots: RollerBodySlotMap
  rollerBearingSlots: RollerBearingSlotMap
  nozzleSlots: NozzleSlotMap

  // Data & Sensors
  dataLineSlots: DataLineSlotMap
  moldFaceDataPointSlots: MoldFaceDataPointSlotMap
  strandDataPointSlots: StrandDataPointSlotMap
  segmentDataPointSlots: SegmentDataPointSlotMap
  rollerDataPointSlots: RollerDataPointSlotMap
  rollerBodyDataPointSlots: RollerBodyDataPointSlotMap
  segmentSensorPointSlots: SegmentSensorPointSlotMap
  rollerSensorPointSlots: RollerSensorPointSlotMap
  rollerBodySensorPointSlots: RollerBodySensorPointSlotMap

  // Cooling System
  airLoopSlots: AirLoopSlotMap
  coolingLoopSlots: CoolingLoopSlotMap
  coolingZoneSlots: CoolingZoneSlotMap
  loopAssignmentSlots: LoopAssignmentSlotMap

  // Consistency Check
  ccElements: CCElementMap
  changeItems: ChangeItemMap

  // MOUNT LOGS

  // General
  moldMountLogs: MoldMountLogMap
  moldBCAreaMountLogs: MoldBCAreaMountLogMap
  moldFaceMountLogs: MoldFaceMountLogMap
  passlineMountLogs: PasslineMountLogMap
  passlineSectionMountLogs: PasslineSectionMountLogMap
  strandGuideMountLogs: StrandGuideMountLogMap
  segmentGroupMountLogs: SegmentGroupMountLogMap
  supportPointMountLogs: SupportPointMountLogMap
  segmentMountLogs: SegmentMountLogMap
  rollerMountLogs: RollerMountLogMap
  rollerBodyMountLogs: RollerBodyMountLogMap
  rollerBearingMountLogs: RollerBearingMountLogMap
  nozzleMountLogs: NozzleMountLogMap

  // Data & Sensors
  dataLineMountLogs: DataLineMountLogMap

  moldFaceDataPointMountLogs: MoldFaceDataPointMountLogMap
  strandDataPointMountLogs: StrandDataPointMountLogMap
  segmentDataPointMountLogs: SegmentDataPointMountLogMap
  rollerDataPointMountLogs: RollerDataPointMountLogMap
  rollerBodyDataPointMountLogs: RollerBodyDataPointMountLogMap

  segmentSensorPointMountLogs: SegmentSensorPointMountLogMap
  rollerSensorPointMountLogs: RollerSensorPointMountLogMap
  rollerBodySensorPointMountLogs: RollerBodySensorPointMountLogMap

  // Cooling System
  airLoopMountLogs: AirLoopMountLogMap
  coolingLoopMountLogs: CoolingLoopMountLogMap
  coolingZoneMountLogs: CoolingZoneMountLogMap
  loopAssignmentMountLogs: LoopAssignmentMountLogMap
}

const PATH = 'casters'

export function getCurrentCaster (caseId: string): Promise<CurrentCasterResponse | null> {
  // TODO: translate message
  return request<CurrentCasterResponse>('get', `${PATH}/${caseId}/current`, 'Failed to get current caster')
}

export function getCasterAtTime (caseId: string, date: Date): Promise<CurrentCasterResponse | null> {
  return request<CurrentCasterResponse>(
    'get',
    `${PATH}/${caseId}/at-time/${date.toISOString()}`,
    'Failed to get caster at time',
  )
}
