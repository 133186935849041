import { NetworkEvent, NetworkStatus } from '@/api/network-event'
import IpcManager from '@/IpcManager'
import ThreeManager from '@/three/ThreeManager'

import { actionList, ApplicationMainActionsEnum, AppState } from './consts'

export function setOpenDialogs (dialogName: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_OPEN_DIALOGS,
    dialogName,
  }
}

export function setLoadingStatus (status: boolean) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_LOADING_STATUS,
    status,
  }
}

export function handleMenuAction (key: string, params: any) {
  if (Object.values(actionList).includes(key as ApplicationMainActionsEnum)) {
    return {
      type: key,
      params,
    }
  }
  else {
    IpcManager.send(key, params)

    return () => {} // no op
  }
}

export function openDialog (dialogName: string, params?: any) {
  return {
    type: ApplicationMainActionsEnum.ACTION_OPEN_DIALOG,
    dialogName,
    params,
  }
}

export function setActiveDetailDialogFilter (filter: string, segmentGroupMountLogId: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_ACTIVE_DETAIL_DIALOG_FILTER,
    elementHistoryDetailsInfo: { filter: filter ?? '', segmentGroupMountLogId },
  }
}

export function resetActiveDetailDialogFilter () {
  return {
    type: ApplicationMainActionsEnum.ACTION_RESET_ACTIVE_DETAIL_DIALOG_FILTER,
  }
}

export function closeDialog (dialogName: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_CLOSE_DIALOG,
    dialogName,
  }
}

export function setRequiredCommandFiles (
  nonexistentCommandFiles: any,
  nonexistentCommandFileMapping: Array<string>,
  visualizationConfigId: string,
) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_REQUIRED_COMMAND_FILES,
    nonexistentCommandFiles,
    nonexistentCommandFileMapping,
    visualizationConfigId,
  }
}

export function clearOpenAppDialogs () {
  return {
    type: ApplicationMainActionsEnum.ACTION_CLEAR_DIALOGS,
  }
}

export function setCurrentProject (currentProject: any) {
  NetworkEvent.setClientData({ projectId: currentProject?.id ?? null })

  return {
    type: ApplicationMainActionsEnum.ACTION_SET_CURRENT_PROJECT,
    currentProject,
  }
}

export function renameCurrentProject (name: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_RENAME_CURRENT_PROJECT,
    name,
  }
}

export function renameCurrentSimulationCase (name: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_RENAME_CURRENT_SIMULATION_CASE,
    name,
  }
}

export function updateCurrentProjectAdditionalData (additionalData: Record<string, any>) {
  return {
    type: ApplicationMainActionsEnum.ACTION_UPDATE_CURRENT_PROJECT_ADDITIONAL_DATA,
    additionalData,
  }
}

export function setCurrentSimulationCase (currentSimulationCase: SimulationCase, preventDefault = false) {
  NetworkEvent.setClientData({ caseId: currentSimulationCase?.id ?? null })

  return {
    type: ApplicationMainActionsEnum.ACTION_SET_CURRENT_SIMULATION_CASE,
    currentSimulationCase,
    preventDefault,
  }
}

export function updateCaseCurrentCasterId (currentCasterId: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_UPDATE_CASE_CURRENT_CASTER_ID,
    currentCasterId,
  }
}

/**
 * @deprecated
 */
export function updateCurrentProjectContainer (containerName: string, data: Array<any>) {
  return {
    type: ApplicationMainActionsEnum.ACTION_UPDATE_CURRENT_PROJECT_SCHEMA,
    containerName,
    data,
  }
}

export function setAuthenticationData (data: AuthData = {} as AuthData) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_AUTHENTICATION_DATA,
    data,
  }
}

export function switchProject () {
  return {
    type: 'ACTION_SWITCH_PROJECT',
  }
}

export function setTheme (darkTheme = true) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_THEME,
    darkTheme,
  }
}

export function setEditProjectId (projectId: string | null | undefined = null) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_EDIT_PROJECT_ID,
    projectId,
  }
}

export function setEditSimulationCaseId (simulationCaseId: string | null | undefined = null) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_EDIT_SIMULATION_CASE_ID,
    simulationCaseId,
  }
}

export function setEditConfigId (configId: string | null | undefined = null) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_EDIT_CONFIG_ID,
    configId,
  }
}

export function setAppState (mode: AppState, avoidDashboard = false) {
  if (mode === AppState.Caster) {
    window.setTimeout(ThreeManager.base.handleResize, 0)
  }

  return {
    type: ApplicationMainActionsEnum.ACTION_SET_APP_STATE,
    mode,
    avoidDashboard,
  }
}

export function setCaseLocks (caseLocks: CaseLock[]) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_CASE_LOCKS,
    caseLocks,
  }
}

export function setCaseVerifications (caseVerifications: CaseVerification[]) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_CASE_VERIFICATION,
    caseVerifications,
  }
}

export function addCaseVerification (caseVerification: CaseVerification) {
  return {
    type: ApplicationMainActionsEnum.ACTION_ADD_CASE_VERIFICATION,
    caseVerification,
  }
}

export function setDefaultCasterDashboardConfig (defaultVisualizationConfigId?: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_DEFAULT_CASTER_DASHBOARD_CONFIG,
    defaultVisualizationConfigId,
  }
}

export function setLastLoadedCasterCatalogId (loadedNozzleCatalogId: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_LAST_LOADED_CASTER_CATALOG_ID,
    loadedNozzleCatalogId,
  }
}

export function setCurrentProjectCasesMetadata (currentProjectCasesMetadata: { id: string, createdAt: Date }[]) {
  return {
    type: ApplicationMainActionsEnum.ACTION_SET_CURRENT_PROJECT_CASES_METADATA,
    currentProjectCasesMetadata,
  }
}

export function resetCurrentProjectCasesMetadata () {
  return {
    type: ApplicationMainActionsEnum.ACTION_RESET_CURRENT_PROJECT_CASES_METADATA,
  }
}

export function deleteIdFromCurrentProjectCasesMetadata (id: string) {
  return {
    type: ApplicationMainActionsEnum.ACTION_DELETE_ID_FROM_CURRENT_PROJECT_CASE_IDS,
    id,
  }
}

export function addMetadataToCurrentProjectCasesMetadata (metadata: { id: string, createdAt: Date }) {
  return {
    type: ApplicationMainActionsEnum.ACTION_ADD_ID_TO_CURRENT_PROJECT_CASE_IDS,
    metadata,
  }
}

export function logout () {
  return {
    type: 'ACTION_RESET_STORE',
  }
}

export function storeNetworkStatus (status: NetworkStatus) {
  return {
    type: ApplicationMainActionsEnum.ACTION_STORE_NETWORK_STATUS,
    status,
  }
}
