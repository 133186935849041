import { request } from '@/api'
import type { CasterElementNames } from '@/types/data'

const PATH = 'temporal-data'

export type TemporalDataResponse = Array<Array<any>>

export function getTemporalData (
  projectId: string,
  realDataUUIDsByType: Partial<Record<CasterElementNames, string[]>>,
) {
  const errorText = 'Failed to get temporal data' // TODO: translate message

  return request<TemporalDataResponse | null>(
    'post',
    String(PATH),
    errorText,
    { data: { projectId, realDataUUIDsByType } },
  )
}

export function getTemporalDataBySlots (
  projectId: string,
  slotIdsByType: Partial<Record<CasterElementNames, string[]>>,
) {
  const errorText = 'Failed to get temporal data by slots' // TODO: translate message

  return request<TemporalDataResponse | null>(
    'post',
    `${String(PATH)}/by-slots`,
    errorText,
    { data: { projectId, slotIdsByType } },
  )
}
