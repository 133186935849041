import { faDotCircle } from '@fortawesome/free-regular-svg-icons'
import {
  faAngleLeft,
  faAngleRight,
  faInfoCircle,
  faMinus,
  faPlus,
  faWarehouse, 
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import hoistStatics from 'hoist-non-react-statics'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css, ThemeProvider } from 'styled-components'

import { NetworkEvent } from '@/api/network-event'
import IpcManager from '@/IpcManager'
import Util from '@/logic/Util'
import CasterContainer from '@/react/Caster'
import CasterDialog from '@/react/CasterDialog'
import CasterFilter from '@/react/CasterFilter'
import CasterTree from '@/react/CasterTree'
import DialogManager from '@/react/dialogs/DialogManager'
import { CasterDetailsID, TreeViewID } from '@/react/driver/DriverID'
import FeatureFlags from '@/react/FeatureFlags'
import DeleteDialog from '@/react/specific/DeleteDialog'
import LoadingScreen from '@/react/specific/LoadingScreen'
import CommandMapping from '@/react/visualization/dashboard/CommandMapping'
import StyleConfig from '@/react/visualization/dashboard/config/StyleConfig'
import Dashboard from '@/react/visualization/dashboard/Dashboard/index'
import PlotConfigurator from '@/react/visualization/dashboard/PlotConfigurator'
import AllActions from '@/store/actions'
import * as ErrorActions from '@/store/application/error/actions'
import * as ApplicationActions from '@/store/application/main/actions'
import { AppState } from '@/store/application/main/consts'
import DataActions, * as DataActions2 from '@/store/data/actions'
import * as ElementsActions from '@/store/elements/actions'
import { updateElementMaps, updateMountLogs } from '@/store/elements/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import * as LoadingActions from '@/store/LoadingStore'
import { updateModules } from '@/store/modules'
import { setMountLogToKeyUUIDsMap } from '@/store/mountLogToKeyUUIDs'
import * as UtilActions from '@/store/util/actions'
import * as VisualizationActions from '@/store/visualization/actions'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeManager from '@/three/ThreeManager'
import type { FilterableElementType } from '@/types/filter'
import type { DefaultState } from '@/types/state'

import Handlers from './handlers'
import { initHotkeys, isConsistencyCheckURL } from './util'

const connector = connect((state: DefaultState) => ({
  amountOfComparisonCasterColumns: state.visualization.amountOfComparisonCasterColumns,
  appState: state.application.main.appState,
  authenticationData: state.application.main.authenticationData,
  catalog: state.data.catalog,
  comparisonCasters: state.ComparisonCasters,
  currentCasterDialogWidth: state.visualization.currentCasterDialogWidth,
  currentDashboard: state.visualization.currentDashboard,
  casterDashboardWidth: state.visualization.casterDashboardWidth,
  currentProject: state.application.main.currentProject,
  currentSimulationCase: state.application.main.currentSimulationCase,
  casterDashboardTabIndex: state.visualization.casterDashboardTabIndex,
  darkTheme: state.application.main.darkTheme,
  dirtyDeletePaths: state.data.dirtyDeletePaths,
  executableDefinitions: state.data.executableDefinitions,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  hasChanges: state.data.hasChanges,
  isCommandMappingOpen: state.visualization.isCommandMappingOpen,
  isLoggedIn: FeatureFlags.isLoggedIn(state),
  isPlotListOpened: state.visualization.isPlotListOpened,
  loadingStatus: state.application.main.loadingStatus,
  openAppDialogs: state.application.main.openAppDialogs,
  openDialogs: state.application.main.openDialogs,
  pendingDeleteList: state.data.pendingDeleteList,
  plotConfigs: state.visualization.plotConfigs,
  selectedPaths: state.data.selectedPaths,
  selectedComparisonCaseIds: state.visualization.selectedComparisonCaseIds,
  term: state.filter.term,
  tileConfigs: state.visualization.tileConfigs,
  viewsObject: state.visualization.viewsObject,
  visualizationMetaInformation: state.visualization.visualizationMetaInformation,
  networkStatus: state.application.main.networkStatus,
  timestamps: state.timestamps,
  liveMode: state.data.liveMode,
  ...getElementMapsObject(state), // TODO: switch to using elementMaps instead of the single stores
}), {
  addDirtyPath: DataActions.addDirtyPath,
  addHdf5Schema: VisualizationActions.addHdf5Schema,
  clearDirtyDeletePaths: DataActions.clearDirtyDeletePaths,
  closeDialog: ApplicationActions.closeDialog,
  openDialog: ApplicationActions.openDialog,
  openPlotExportDialog: VisualizationActions.openPlotExportDialog,
  openSelectSourceDialog: VisualizationActions.openSelectSourceDialog,
  removeHdf5Schema: VisualizationActions.removeHdf5Schema,
  resetHasChanges: DataActions.resetHasChanges,
  resetReducer: DataActions.resetReducer,
  saveCatalog: DataActions.saveCatalog,
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
  setAmountOfComparisonCasterColumns: VisualizationActions.setAmountOfComparisonCasterColumns,
  setAppState: ApplicationActions.setAppState,
  setAuthenticationData: ApplicationActions.setAuthenticationData,
  setConfig: VisualizationActions.setConfig,
  setCurrentCasterDialogWidth: VisualizationActions.setCurrentCasterDialogWidth,
  resetAllElements: ElementsActions.resetAllElements,
  setCurrentProject: ApplicationActions.setCurrentProject,
  setCurrentSimulationCase: ApplicationActions.setCurrentSimulationCase,
  setCurrentProjectCasesMetadata: ApplicationActions.setCurrentProjectCasesMetadata,
  addMetadataToCurrentProjectCasesMetadata: ApplicationActions.addMetadataToCurrentProjectCasesMetadata,
  setDataSources: VisualizationActions.setDataSources,
  setError: ErrorActions.setError,
  setExecutionState: DataActions2.setExecutionState,
  setHdf5Schema: VisualizationActions.setHdf5Schema,
  setIsConsistencyCheck: UtilActions.setIsConsistencyCheck,
  setLoadingStatus: ApplicationActions.setLoadingStatus,
  setOpenDialogs: ApplicationActions.setOpenDialogs,
  setPlotsMeta: VisualizationActions.setPlotsMeta,
  setSavePath: UtilActions.setSavePath,
  setCasterDashboardTabIndex: VisualizationActions.setCasterDashboardTabIndex,
  setTerm: FilterActions.setTerm,
  setTheme: ApplicationActions.setTheme,
  setUserSettings: UtilActions.setUserSettings,
  setVisualizationData: VisualizationActions.setData,
  setVisualizationMetaInformation: VisualizationActions.setVisualizationMetaInformation,
  showPlotList: VisualizationActions.showPlotList,
  storeNetworkStatus: ApplicationActions.storeNetworkStatus,
  updateNotification: DataActions.updateNotification,
  applyUpdates: DataActions.applyUpdates,
  switchProject: ApplicationActions.switchProject,
  setFileUploadLoadingStatus: LoadingActions.setFileUploadLoadingStatus,
  toggleEditMode: VisualizationActions.toggleEditMode,
  updateWholeState: UtilActions.updateWholeState,
  updateElementMaps,
  updateMountLogs,
  updateModules,
  setCaseLocks: ApplicationActions.setCaseLocks,
  updateCaseCurrentCasterId: ApplicationActions.updateCaseCurrentCasterId,
  setElements: DataActions.setElements,
  setExecutionData: DataActions2.setExecutionData,
  setMountLogToKeyUUIDsMap,
})

type PropsFromRedux = ConnectedProps<typeof connector>

export interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

const AppContainer = styled.div`
  overflow: hidden;
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  user-select: none;
`

// TODO: move down
const DialogButton = styled.div<{
  $orientation: 'left' | 'right'
  $isOpen: boolean
  $isBig: boolean
  $up?: number
  $smallIcon?: boolean
  $dashboardWidth?: number
  $currentCasterDialogWidth?: number
}>`
  ${({ $orientation, $isOpen, $isBig, $dashboardWidth, $currentCasterDialogWidth, $up, $smallIcon }) => css`
    position: absolute;
    top: calc(50% - 5px - ${($up ?? 0) * 35}px);
    width: 100px;
    height: 10px;
    background: #212427;
    border-radius: 7px;
    color: #CCCCCC;
    font-size: ${$smallIcon ? '12px' : '24px'};
    padding: 15px ${$isOpen ? '6px' : '4px'};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    ${$orientation === 'left' && 'text-align: right;'}
    ${$orientation === 'left' && 'justify-content: flex-end;'}
    ${$orientation === 'right'
    ? `right: ${$isOpen ? `${($currentCasterDialogWidth ?? 335) - 77}px` : '-80px'};`
    : `left: ${$isOpen ? ($isBig ? `${($dashboardWidth ?? 500) - 80}px` : '200px') : '-80px'};`
}
`}`

const ComparisonControllerButton = styled.div<{
  $orientation: 'left' | 'right'
  $isOpen: boolean
  $isBig: boolean
  $dashboardWidth?: number
  $topOffset: number
  $currentCasterDialogWidth: number
  $disabled: boolean
}>`
  ${({ $orientation, $isOpen, $isBig, $dashboardWidth, $topOffset, $currentCasterDialogWidth, $disabled }) =>
    css`
    position: absolute;
    top: calc(50% - ${$topOffset}px);
    width: 100px;
    height: 10px;
    background: ${$disabled ? '#033b5e' : '#0777BD'};
    border-radius: 7px;
    color: ${$disabled ? '#6b6b6b' : '#CCCCCC'};
    font-size: 18px;
    padding: 15px 4px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    ${$orientation === 'left' && 'text-align: right;'}
    ${$orientation === 'left' && 'justify-content: flex-end;'}
    ${
  $orientation === 'right'
    ? `right: ${$isOpen ? `${$currentCasterDialogWidth - 77}px` : '-80px'};`
    : `left: ${$isOpen ? ($isBig ? `${($dashboardWidth ?? 500) - 80}px` : '200px') : '-80px'};`
}
  `}
`

const CasterLoadingScreen = styled.div<{
  $CasterTree?: boolean
  $CasterDialog?: boolean
  $CasterDashboard?: boolean
  children?: React.ReactElement
}>`
  ${({ $CasterTree, $CasterDialog, $CasterDashboard }) =>
    css`
    position: absolute;
    top: 50px;
    right: ${$CasterDialog ? '335px' : '0'};
    bottom: 0;
    left: ${$CasterTree ? ($CasterDashboard ? '500px' : '280px') : '0'};
    overflow: hidden;
    background-color: #000000;
    width: calc(100vw - ${$CasterDialog ? '335px' : '0px'} - ${
  $CasterTree ? ($CasterDashboard ? '500px' : '280px') : '0px'
});
  `}
`

type State = {
  isNewCaster: boolean
  redraw: boolean
  openDashboard: boolean
  electronReady: boolean
  loggingInToLocalServer: boolean
  resizeDashboardWidth: number
}

export class App extends Component<Props, State> {
  private wasFullScreen: boolean | null

  private readonly handlers: Handlers

  private selectionQueue: string

  public constructor (props: Props) {
    super(props)
    this.handlers = new Handlers({ props })
    this.wasFullScreen = null
    this.selectionQueue = ''
    AllActions.AppErrorActions.resetErrors()
    Util.bindMethods(this.handlers, this)

    IpcManager.init(this)

    initHotkeys()
  }

  public override state: State = {
    isNewCaster: false,
    redraw: false,
    openDashboard: true,
    electronReady: false,
    loggingInToLocalServer: true,
    resizeDashboardWidth: 0,
  }

  public override componentDidMount () {
    const title = process.env.NODE_ENV === 'development' ? 'DEV Caster Configurator' : 'CasterXpert'

    document.title = title

    IpcManager.handleLockCaster = this.handlers.handleLockCaster

    IpcManager.registerEvents(this.props, this.forceUpdate.bind(this))

    if (document.body) {
      document.body.addEventListener('contextmenu', (event: Event) => {
        event.preventDefault()
      })
    }

    const consistencyCheckCaseId = isConsistencyCheckURL()

    if (consistencyCheckCaseId) {
      this.props.setLoadingStatus(true)
      this.props.setIsConsistencyCheck()
      IpcManager.internal.send('loadConsistencyCheckCase', null, consistencyCheckCaseId)
    }

    NetworkEvent.subscribe(NetworkEvent.ConnectionStatusChanged, this.handlers.handleNetworkStatus)
    NetworkEvent.subscribe('execution-done', this.handlers.executionDoneCallback)
    NetworkEvent.subscribe('live-mode-update', this.handlers.updateNotificationCallback)
  }

  public override async componentDidUpdate (prevProps: Props) {
    IpcManager.updateProps(this.props)

    await this.handlers.handleLockCaster(prevProps)

    const {
      openDialogs,
      casterDashboardWidth,
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      selectedPaths,
      setAmountOfComparisonCasterColumns,
      setCurrentCasterDialogWidth,
    } = this.props

    const elementMaps = getElementMapsObject(this.props)
    const {
      openDialogs: prevOpenDialogs,
      hasChanges: prevHasChanges,
      casterDashboardWidth: prevDashboardWidth,
      currentCasterDialogWidth: prevCasterDialogWidth,
    } = prevProps

    if (this.props.hasChanges && !prevHasChanges && IpcManager.both) {
      IpcManager.both.send('changes')
    }

    if (
      openDialogs !== prevOpenDialogs ||
      casterDashboardWidth !== prevDashboardWidth ||
      currentCasterDialogWidth !== prevCasterDialogWidth
    ) {
      setTimeout(ThreeManager.base.handleResize, 1)
    }

    const isFullScreen = Boolean(document.fullscreenElement)

    if (this.wasFullScreen !== isFullScreen) {
      this.wasFullScreen = isFullScreen

      this.handlers.handleFullScreenNotification(isFullScreen)
    }

    if (selectedPaths.size === 0 && amountOfComparisonCasterColumns) {
      setAmountOfComparisonCasterColumns(0)
      setCurrentCasterDialogWidth(335)
    }

    // FIXME: elementMaps changes
    if (this.selectionQueue) {
      const { setSelectedElementPaths } = this.props
      const filteredElements = FilterHandler
        .getFilteredElements(
          elementMaps,
          this.selectionQueue,
          false,
        ) as Record<string, FilterableElementType>

      const paths = Object
        .entries(filteredElements)
        .filter(([ _path, type ]) => !/^Segment(Group)?/.test(type))
        .map(([ path ]) => path)

      this.selectionQueue = ''

      // FIXME: this is a workaround for the issue with the selection queue
      // it is so because opening the dashboard clears the selection
      setTimeout(() => {
        setSelectedElementPaths(paths, true)
      }, 500)
    }
  }

  private readonly handleResizeCasterTree = (width: number) => {
    this.setState({
      resizeDashboardWidth: width,
    })
  }

  public override render () {
    const {
      isLoggedIn,
      openDialogs,
      loadingStatus,
      Caster,
      pendingDeleteList,
      selectedPaths,
      isPlotListOpened,
      isCommandMappingOpen,
      appState,
      darkTheme,
      t,
      casterDashboardTabIndex,
      casterDashboardWidth,
      currentCasterDialogWidth,
      amountOfComparisonCasterColumns,
      selectedComparisonCaseIds,
      featureFlags,
    } = this.props

    const { isNewCaster, redraw, resizeDashboardWidth } = this.state
    const openCasterTree = openDialogs.includes('CasterTree')
    const openCasterDialog = openDialogs.includes('CasterDialog')
    const openPartsWarehouse = openDialogs.includes('PartsWarehouse')
    const openCasterDashboard = casterDashboardTabIndex > 0

    let openPendingDeleteDialog = false

    pendingDeleteList.length > 0 && pendingDeleteList.forEach(pendingElement => {
      openPendingDeleteDialog = !selectedPaths.has(pendingElement)
    })

    const isDialogOpen = openPendingDeleteDialog

    const canCompareCasters = FeatureFlags.canCompareCastersInCasterDialog(featureFlags)
    const isComparingCasters = selectedComparisonCaseIds.length > 0

    if (window.isElectron && this.state.loggingInToLocalServer) {
      return (
        <MuiThemeProvider theme={StyleConfig.muiTheme(darkTheme)}>
          <AppContainer className={darkTheme ? undefined : 'light'}>
            <CasterLoadingScreen>
              <LoadingScreen />
            </CasterLoadingScreen>
          </AppContainer>
        </MuiThemeProvider>
      )
    }

    return (
      <MuiThemeProvider theme={StyleConfig.muiTheme(darkTheme)}>
        <AppContainer className={darkTheme ? undefined : 'light'}>
          {
            isLoggedIn
              ? (
                <div>
                  {
                    !loadingStatus && (isPlotListOpened || isCommandMappingOpen) && (
                      <ThemeProvider theme={darkTheme ? StyleConfig.darkTheme : StyleConfig.lightTheme}>
                        <div>
                          {isPlotListOpened && <PlotConfigurator />}
                          {isCommandMappingOpen && <CommandMapping />}
                        </div>
                      </ThemeProvider>
                    )
                  }
                  <DialogManager />
                  {appState !== AppState.Caster && <Dashboard />}
                  <div
                    style={
                      appState === AppState.Caster && !isPlotListOpened
                        ? { background: 'black', height: 'calc(100vh - 32px)', position: 'relative' }
                        : { display: 'none' }
                    }
                  >
                    <CasterContainer isNewCaster={isNewCaster} redraw={redraw} blur={isDialogOpen} />
                    {
                      (loadingStatus || !Caster) &&
                      (
                        <CasterLoadingScreen
                          $CasterTree={openCasterTree}
                          $CasterDialog={openCasterDialog || openPartsWarehouse}
                          $CasterDashboard={openCasterDashboard}
                        >
                          <LoadingScreen />
                        </CasterLoadingScreen>
                      )
                    }
                    <CasterFilter />
                    {openPendingDeleteDialog && <DeleteDialog />}
                    <DialogButton
                      id={TreeViewID.ToggleSidebarButton}
                      $dashboardWidth={Math.min(resizeDashboardWidth ?? casterDashboardWidth, window.innerWidth - 390)}
                      $orientation='left'
                      onClick={() => this.handlers.handleToggleDialog('CasterTree')}
                      $isOpen={openCasterTree}
                      $isBig={openCasterDashboard}
                      title={t(`treeView.${openCasterTree ? 'hide' : 'show'}`)}
                    >
                      {
                        openCasterTree
                          ? <FontAwesomeIcon icon={faAngleLeft} />
                          : <FontAwesomeIcon icon={faAngleRight} />
                      }
                    </DialogButton>
                    {
                      FeatureFlags.canViewPartsWarehouse(featureFlags) && (
                        <DialogButton
                          $orientation='right'
                          onClick={
                            () => {
                              const isOpen = openCasterDialog || openPartsWarehouse

                              if (!isOpen) {
                                this.handlers.handleToggleDialog('PartsWarehouse')

                                return
                              }

                              this.handlers.handleToggleDialog('PartsWarehouse')
                              this.handlers.handleToggleDialog('CasterDialog')
                            }
                          }
                          $isOpen={openCasterDialog || openPartsWarehouse}
                          title={t(`dialog.toggle.${openPartsWarehouse ? 'elementDetails' : 'partsWarehouse'}`)}
                          $isBig={false}
                          $currentCasterDialogWidth={currentCasterDialogWidth}
                          $up={1}
                          $smallIcon
                        >
                          {
                            openPartsWarehouse
                              ? <FontAwesomeIcon icon={faInfoCircle} />
                              : <FontAwesomeIcon icon={faWarehouse} />
                          }
                        </DialogButton>
                      )
                    }
                    <DialogButton
                      id={CasterDetailsID.ToggleSidebarButton}
                      $orientation='right'
                      onClick={
                        () => {
                          const isOpen = openCasterDialog || openPartsWarehouse

                          if (isOpen && openCasterDialog) {
                            this.handlers.handleToggleDialog('CasterDialog')

                            return
                          }

                          if (isOpen && openPartsWarehouse) {
                            this.handlers.handleToggleDialog('PartsWarehouse')

                            return
                          }

                          this.handlers.handleToggleDialog('CasterDialog')
                        }
                      }
                      $isOpen={openCasterDialog || openPartsWarehouse}
                      title={t(`dialog.${openCasterDialog ? 'hide' : 'show'}`)}
                      $isBig={false}
                      $currentCasterDialogWidth={currentCasterDialogWidth}
                    >
                      {
                        openCasterDialog || openPartsWarehouse
                          ? <FontAwesomeIcon icon={faAngleRight} />
                          : <FontAwesomeIcon icon={faAngleLeft} />
                      }
                    </DialogButton>
                    {
                      canCompareCasters && isComparingCasters && selectedPaths.size && (
                        <>
                          <ComparisonControllerButton
                            $orientation='right'
                            onClick={() => this.handlers.handleShowMore()}
                            $isOpen={openCasterDialog}
                            title={t('dialog.showMore')}
                            $isBig={false}
                            $topOffset={-30}
                            $disabled={selectedComparisonCaseIds.length === amountOfComparisonCasterColumns}
                            $currentCasterDialogWidth={currentCasterDialogWidth}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </ComparisonControllerButton>
                          <ComparisonControllerButton
                            $orientation='right'
                            onClick={() => this.handlers.handleShowLess()}
                            $isOpen={openCasterDialog}
                            title={t('dialog.showLess')}
                            $isBig={false}
                            $topOffset={-65}
                            $disabled={amountOfComparisonCasterColumns === 0}
                            $currentCasterDialogWidth={currentCasterDialogWidth}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </ComparisonControllerButton>
                          <ComparisonControllerButton
                            $orientation='right'
                            $disabled={!amountOfComparisonCasterColumns}
                            onClick={() => this.handlers.handleSetDefaultView()}
                            $isOpen={openCasterDialog}
                            title={t('dialog.defaultView')}
                            $isBig={false}
                            $topOffset={-100}
                            $currentCasterDialogWidth={currentCasterDialogWidth}
                          >
                            <FontAwesomeIcon icon={faDotCircle} />
                          </ComparisonControllerButton>
                        </>
                      )
                    }
                    <CasterTree onResizeCasterTree={this.handleResizeCasterTree} isOpen={openCasterTree} />
                    <CasterDialog />
                  </div>
                  {/*  eslint-disable-next-line react/jsx-closing-tag-location */}
                </div>
              )
              : <DialogManager login />
          }
        </AppContainer>
      </MuiThemeProvider>
    )
  }
}

const composedComponent = compose<any>(withTranslation('caster'), connector)(App)

export default hoistStatics(composedComponent, App)
