import { faCircle, faSquare, faStar, faSun, type IconDefinition } from '@fortawesome/free-regular-svg-icons'
import { faAngleDown, faAngleUp, faCrosshairs, faTh, faTimes, faTrain } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEqual from 'lodash/isEqual'
import { enqueueSnackbar } from 'notistack'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { compose } from 'redux'
import styled, { css } from 'styled-components'
import tinycolor from 'tinycolor2'

import { useConfig } from '@/config'
import ApiClient from '@/store/apiClient'
import DataActions from '@/store/data/actions'
import { getElementMapsObject } from '@/store/elements/logic'
import * as FilterActions from '@/store/filter/actions'
import { getReferenceDate } from '@/store/timestamps'
import { setPlotsCompareCasterInformation } from '@/store/visualization/actions'
import { CompareFilterHandler } from '@/three/logic/CompareFilterHandler'
import FilterHandler from '@/three/logic/FilterHandler'
import ThreeUtil from '@/three/logic/Util'
import ThreeManager from '@/three/ThreeManager'
import type { CasterElementNames } from '@/types/data'
import type { FilterableElementType, FilterControlDefinition } from '@/types/filter'
import type { DefaultState, ElementMaps, MountLogMapKey, SlotMapKey, TagName } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { Mapping } from '@/Util/mapping/Mapping'
import { NumericIdMapping } from '@/Util/mapping/NumericIdMapping'

import { UI3DID } from './driver/DriverID'
import FeatureFlags from './FeatureFlags'
import UIControls from './UIControls'
import { ViewCompareLogic } from './visualization/dashboard/ViewCompareLogic'

const defaultBackgroundColor = '#5384db'

const darkenColor = (color: string, amount: number) => tinycolor(color).darken(amount).toString()

const FilterContainer = styled.div<{
  $CasterTree: boolean
  $CasterDialog: boolean
  $CasterDashboard: boolean
  $disabled: boolean
  $dashboardWidth: number
  $casterDialogWidth: number
}>`${({
  $CasterTree,
  $CasterDialog,
  $CasterDashboard,
  $disabled,
  $dashboardWidth,
  $casterDialogWidth,
}: any) =>
  css`
  position: absolute;
  top: 0;
  right: ${$CasterDialog ? `${$casterDialogWidth || 335}px` : '0'};
  height: 50px;
  left: ${$CasterTree ? ($CasterDashboard ? `${$dashboardWidth}px` : '280px') : '0'};
  pointer-events: ${$disabled ? 'none' : 'initial'};
  background: #22282e;
`}`

const Button = styled.button`
  position: absolute;
  color: #FFFFFF;
  top: 10px;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;
  cursor: pointer;
`

const ClearButton = styled(Button)<{ $small: boolean }>`${({ $small }: any) =>
  css`
  top: 17px;
  right: ${$small ? '35%' : '20px'};
`}`

const SearchInput = styled.input<{ $small: boolean, $termDisabled: boolean }>`${({ $small, $termDisabled }: any) =>
  css`
  width: ${$small ? '65%' : 'calc(100% - 20px)'};
  margin: 0;
  height: 32px;
  border: none;
  border-radius: 5px;
  background: #333a44;
  padding: 0 30px 0 10px;
  outline: none;
  color: ${$termDisabled ? '#666666' : '#FFFFFF'};
  ${$termDisabled && 'font-style: italic;'}
  margin-top: 9px;
  margin-left: 10px;

  &::placeholder{
    font-style: italic;
    user-select: none;
  }
`}`

const FilterControlInput = styled.input`
  height:             30px;
  font-size:          20px;
  width:              30%;
  display:            flex;
  align-items:        center;
  justify-content:    center;
  text-align:         center;
  background:         none;
  border:             none;
  border-radius:      5px;
  color:              #FFFFFF;
  font-size:          16px;

  &::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  &::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
`

const FilterControlTitle = styled.span`
  width:              60%;
  overflow:           hidden;
  white-space:        nowrap;
  text-overflow:      ellipsis;
`

const ControlInputsContainer = styled.div`
  position:             absolute;
  top:                  9px;
  margin:               0 10px;
  padding:              0 5px 0 10px;
  right:                0;
  height:               32px;
  border-radius:        5px;
  color:                #FFFFFF;
  background:           #333a44;
  width:                calc(35% - 30px);
  display:              flex;
  font-size:            16px;
  align-items:          center;
  justify-content:      space-between;
  overflow:             hidden;
  gap:                  10px;
`

const ControlInputs = styled.div`
  display:              flex;
  align-items:          center;
  justify-content:      space-between;
  overflow:             hidden;
  flex-grow: 1;
  flex-basis: 0;
`

export const StyledButton = styled.button<{ disabled?: boolean }>`${({ disabled }) =>
  css`
  border:           none;
  font-size:        20px;
  background-color: transparent;
  color:            ${disabled ? '#777777' : 'inherit'};
  ${!disabled && 'cursor: pointer;'}

  &:hover {
    height: 10px;
    ${!disabled && 'color: #CCCCCC;'}
  }

  &:focus {
    outline: 0;
  }
`}`

// calc left is the combination of the width of the searchInput 65% + 20 px of margin
// and a offset for 6 buttons with the width of 22px and a gap of 5px
const FilterSelectorsContainer = styled.div`
  position:         absolute;
  top:              55px;
  left:            calc(65% - 145px);
  display:          flex;
  gap:             5px;
  z-index:          100;
  align-items:      center;
  justify-content:  center;
`

export const FilterControl = styled.div<{
  $backgroundColor?: string
  $active: boolean
  $color?: string
}>`${({ $backgroundColor, $color, $active }) =>
  css`
  height:         22px;
  width:          22px;
  ${$color ? `color: ${$color};` : ''}
  background:     ${$active
    ? darkenColor($backgroundColor ?? defaultBackgroundColor, 10)
    : $backgroundColor ?? defaultBackgroundColor
};
  display:        flex;
  align-items:    center;
  justify-content:center;
  border:         ${$active ? '2px solid #0353e9' : 'none'};
`}`

const Target = styled.div`
  display:        flex;
  flex-direction: column;
  color:          #FFFFFF;
  svg {
    height:       18px;
    font-size:    18px;
    border:       none;
  }
  svg:hover {
    color:       ${tinycolor('#FFFFFF').darken(20).toString()};
  }
`

const ShowMoreFilterControlsToggler = styled.div<{ $active: boolean }>`${({ $active }) =>
  css`
  height:         22px;
  width:          22px;
  background:     ${$active ? darkenColor('#474B4E', 10) : '#474B4E'};
  display:        flex;
  align-items:    center;
  justify-content:center;

  svg {
    color: ${$active ? darkenColor('#B0B1B2', 10) : '#B0B1B2'};
  }
`}`

const ExpandedFilterControlsContainer = styled.div`
  position:         absolute;
  top:              27px;
  right:            0px;
  display:          flex;
  width:            142px;
  background:       rgba(51, 58, 68, 0.5);
  flex-wrap:        wrap;
  padding:          0 5px 5px 5px;
  display:          flex;
  z-index:          100;
  gap:              5px;
  row-gap:          0;

  div {
    margin-top:     5px;
  }
`

const connector = connect((state: DefaultState) => ({
  term: state.filter.term,
  currentCasterDialogWidth: state.visualization.currentCasterDialogWidth,
  activeFilterControlIndexes: state.filter.activeFilterControlIndexes,
  filterControlDefinitions: state.filter.filterControlDefinitions,
  currentFilterControlValues: state.filter.currentFilterControlValues,
  termDisabled: state.filter.termDisabled,
  openDialogs: state.application.main.openDialogs,
  casterDashboardTabIndex: state.visualization.casterDashboardTabIndex,
  casterDashboardWidth: state.visualization.casterDashboardWidth,
  featureFlags: FeatureFlags.getRealFeatureFlags(state),
  timestamps: state.timestamps,
  currentProjectCasesMetadata: state.application.main.currentProjectCasesMetadata,
  plotsCompareCasterInformation: state.visualization.plotsCompareCasterInformation,
  currentSimulationCase: state.application.main.currentSimulationCase,
  currentProject: state.application.main.currentProject,
  rollerChildren: state.util.rollerChildren,
  ...getElementMapsObject(state),
}), {
  setTerm: FilterActions.setTerm,
  setTermDisabled: FilterActions.setTermDisabled,
  setActiveFilterControlIndexes: FilterActions.setActiveFilterControlIndexes,
  setTarget: FilterActions.setTarget,
  setCurrentFilterControlValues: FilterActions.setCurrentFilterControlValues,
  setSelectedElementPaths: DataActions.setSelectedElementPaths,
  setPlotsCompareCasterInformation,
})

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  t(key: string, params?: Record<string, unknown>): string
}

type State = {
  initialized: boolean
  inputValuesPerIndex: Record<number, number>
  elementsIncludedInFilterControls: CasterElementNames[]
  minAndMaxPerFilterControl: Record<string, Record<string, { min?: number, max?: number }>>
  minOrMaxWithVariables: {
    elementType: CasterElementNames
    filter: string
  }[]
  showMoreFilterControls: boolean
  term: string
}
export class CasterFilter extends Component<Props, State> {
  private static readonly icons: Record<string, IconDefinition> = {
    sun: faSun,
    square: faSquare,
    train: faTrain,
    star: faStar,
    // faCircle is used as a fallback icon, so it is not included in the icons object
  }

  private changeFilterValueTimeout?: NodeJS.Timeout

  private readonly timeoutMs = 250

  public constructor (props: Props) {
    super(props)

    this.state = {
      initialized: false,
      inputValuesPerIndex: { ...props.currentFilterControlValues },
      elementsIncludedInFilterControls: [],
      minOrMaxWithVariables: [],
      minAndMaxPerFilterControl: {},
      showMoreFilterControls: false,
      term: props.term ?? '',
    }
  }

  public override componentDidMount () {
    const { currentFilterControlValues } = this.props

    this.setState({ inputValuesPerIndex: { ...currentFilterControlValues } })
  }

  public override componentDidUpdate (prevProps: Props) {
    const { currentFilterControlValues, termDisabled, setTerm } = this.props
    const { elementsIncludedInFilterControls, minOrMaxWithVariables, initialized, inputValuesPerIndex } = this.state
    const elementMaps = getElementMapsObject(this.props)
    const prevElementMaps = getElementMapsObject(prevProps)
    const newState: Partial<State> = {}
    const prevSegmentGroups = Object.keys(prevElementMaps.SegmentGroupSlot)
    const currentSegmentGroups = Object.keys(elementMaps.SegmentGroupSlot)
    const segmentGroupsLoaded = prevSegmentGroups.length !== currentSegmentGroups.length

    if ((!termDisabled && prevProps.term !== this.props.term)) {
      newState.term = this.props.term
    }
    else if (this.state.term && prevProps.termDisabled && !this.props.termDisabled) {
      setTerm(this.state.term)
    }

    if (
      prevProps.filterControlDefinitions.length !== this.props.filterControlDefinitions.length ||
      !isEqual(prevProps.currentFilterControlValues, currentFilterControlValues) ||
      segmentGroupsLoaded
    ) {
      const {
        elementsIncludedInFilterControls,
        minOrMaxWithVariables,
      } = this.getElementsIncludedInFilterControls(this.props)

      newState.elementsIncludedInFilterControls = elementsIncludedInFilterControls
      newState.minOrMaxWithVariables = minOrMaxWithVariables
    }

    if (
      !isEqual(prevProps.currentFilterControlValues, currentFilterControlValues) &&
      !isEqual(currentFilterControlValues, inputValuesPerIndex)
    ) {
      newState.inputValuesPerIndex = { ...currentFilterControlValues }
    }

    const minAndMaxPerFilterControl: Record<string, Record<string, { min: number, max: number }>> = {}

    const elements = elementsIncludedInFilterControls?.length
      ? elementsIncludedInFilterControls
      : newState.elementsIncludedInFilterControls ?? []

    const minMax = minOrMaxWithVariables?.length
      ? minOrMaxWithVariables
      : newState.minOrMaxWithVariables ?? []

    for (let i = 0; i < elements.length; i++) {
      const elementType = `${elements[i]}MountLog` as keyof ElementMaps
      const prevMountLogMap = prevElementMaps[elementType]
      const currentMountLogMap = elementMaps[elementType]

      if (
        !prevMountLogMap ||
        !currentMountLogMap ||
        (initialized && isEqual(prevMountLogMap, currentMountLogMap))
      ) {
        continue
      }

      const filtersContainingElementType = minMax
        .filter(filterVariable => filterVariable.elementType === elements[i])

      for (let j = 0; j < filtersContainingElementType.length; j++) {
        const { elementType, filter } = filtersContainingElementType[j] ?? {}

        if (!elementType || !filter || minAndMaxPerFilterControl[elementType]?.[filter]) {
          continue
        }

        if (!minAndMaxPerFilterControl[elementType]) {
          minAndMaxPerFilterControl[elementType] = {}
        }

        const { min, max } = this.getKeyMinAndMaxFromElementType(elementType, filter)

        minAndMaxPerFilterControl[elementType][filter] = { min, max }
      }

      newState.minAndMaxPerFilterControl = minAndMaxPerFilterControl
    }

    if (Object.keys(newState).length > 0) {
      this.setState({ ...newState as State, initialized: true })
    }
  }

  private readonly handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      setTerm,
      setSelectedElementPaths,
      currentProjectCasesMetadata,
      plotsCompareCasterInformation,
      currentSimulationCase,
      timestamps,
      currentProject,
      setPlotsCompareCasterInformation,
    } = this.props
    const term = event.target.value as string
    const elementMaps = getElementMapsObject(this.props)

    if (this.changeFilterValueTimeout) {
      clearTimeout(this.changeFilterValueTimeout)
    }

    this.setState({ term })
    this.changeFilterValueTimeout = setTimeout(async () => {
      if (term.includes('@')) {
        const typesToBeRequestedPerCase: Record<string, TagName[]> = {}
        const individualTerms = FilterHandler.getTokens(term)
        const filtersWithCompareCasterReference = individualTerms.filter(term => term.includes('@'))

        for (const individualTerm of filtersWithCompareCasterReference) {
          const [ caseIndicator, sanitizedFilter ] = individualTerm.split('@')
          const currentCaseIndexInArray = currentProjectCasesMetadata
            .findIndex(({ id }) => id === currentSimulationCase.id)
          const caseIndex = Number(caseIndicator?.replace('C', '')) - 1

          if (
            Number.isNaN(caseIndex) ||
            caseIndex < 0 ||
            caseIndex >= currentProjectCasesMetadata.length ||
            caseIndex === currentCaseIndexInArray ||
            !sanitizedFilter
          ) {
            continue
          }

          const filterElementTypes = FilterHandler.getFilterElementTypes(sanitizedFilter) as TagName[]

          for (const type of filterElementTypes) {
            if (!ElementsUtil.typeIsOfTypeCasterElementName(type)) {
              continue
            }

            const extraNeededTypes = ElementsUtil.allNeededParentTypesPerType[type]

            if (extraNeededTypes?.length) {
              for (const extraType of extraNeededTypes) {
                if (!filterElementTypes.includes(extraType)) {
                  filterElementTypes.push(extraType)
                }
              }
            }

            const referencedCaseId = currentProjectCasesMetadata[caseIndex]?.id

            if (!referencedCaseId) {
              continue
            }

            if (!typesToBeRequestedPerCase[referencedCaseId]) {
              typesToBeRequestedPerCase[referencedCaseId] = []
            }

            const requiredTypes = ElementsUtil.getMountLogMapKeysByTagName(type)

            for (const requiredType of requiredTypes) {
              if (
                plotsCompareCasterInformation[referencedCaseId]?.[requiredType] ||
                typesToBeRequestedPerCase[referencedCaseId].includes(type)
              ) {
                continue
              }

              typesToBeRequestedPerCase[referencedCaseId].push(type)
            }
          }
        }

        const data = await ApiClient
          .get(`${useConfig().apiBaseURL}/casters/compare-plots`, {
            params: {
              date: getReferenceDate(timestamps).toISOString(),
              typesToBeRequestedPerCase,
              projectId: currentProject.id,
              dataLineRefs: [],
            },
          })

        const normalizedData = ElementsUtil.normalizeCompareCasterInformation(plotsCompareCasterInformation, data)

        for (const caseId in normalizedData) {
          const elementMaps = normalizedData[caseId] as ElementMaps

          NumericIdMapping.build(elementMaps, caseId)
          ViewCompareLogic.buildComparePaths(elementMaps, caseId)
        }

        setPlotsCompareCasterInformation(normalizedData)

        for (const individualTerm of filtersWithCompareCasterReference) {
          const [ caseIndicator, sanitizedFilter ] = individualTerm.split('@')
          const caseIndex = Number(caseIndicator?.replace('C', '')) - 1

          if (
            Number.isNaN(caseIndex) ||
            caseIndex < 0 ||
            caseIndex >= currentProjectCasesMetadata.length ||
            !sanitizedFilter
          ) {
            continue
          }

          const referencedCaseId = currentProjectCasesMetadata[caseIndex]?.id

          if (!referencedCaseId) {
            continue
          }

          const referencedCaseElementMaps = (Object.keys(normalizedData[referencedCaseId] ?? {}).length > 0)
            ? normalizedData[referencedCaseId] as ElementMaps
            : plotsCompareCasterInformation[referencedCaseId] as ElementMaps

          const filteredElementsInReferencedCase = CompareFilterHandler
            .getFilteredElementsPerCompareCaseId(
              referencedCaseElementMaps,
              referencedCaseId,
              sanitizedFilter,
            )

          // for every path in filteredElementsInReferencedCase, get the element mount log and then get the current
          // element that is in the same position
          const pathsAndTypesArray = Object.entries(filteredElementsInReferencedCase)

          for (let i = 0; i < pathsAndTypesArray.length; i++) {
            const [ path, type ] = pathsAndTypesArray[i] ?? []

            if (!path || !type) {
              continue
            }

            const { id } = ThreeUtil.getElementInfo(path)
            const typeMountLog = `${type}MountLog` as MountLogMapKey

            if (!Mapping.mountLogIdByCaseIdAndTypeAndNumericId[referencedCaseId]) {
              continue
            }

            const mountLogId = Mapping.mountLogIdByCaseIdAndTypeAndNumericId[referencedCaseId][type][id]
            const mountLog = mountLogId ? referencedCaseElementMaps[typeMountLog]?.[mountLogId] : null
            const slot = referencedCaseElementMaps[`${type}Slot` as SlotMapKey][mountLog?.slotId ?? '']

            if (!mountLog || !slot) {
              continue
            }

            if (!/(SegmentGroup:\d+\/)?Segment:\d+/.test(path)) {
              continue
            }

            // const regex = /(SegmentGroup:\d+\/)?Segment:\d/

            // // Use match to find the substring
            // const match = path.match(regex)

            // // If a match is found, return it; otherwise, return an empty string or null
            // const segmentPath = match ? match[0] : ''
            // const segmentNumericId = ThreeUtil.getElementInfo(segmentPath).id

            // if (!segmentPath || Number.isNaN(segmentNumericId)) {
            //   continue
            // }

            // // eslint-disable-next-line max-len
            // const segmentMountLogId = Mapping
            //   .mountLogIdByCaseIdAndTypeAndNumericId[referencedCaseId]
            //   .Segment[segmentNumericId]
            // const segmentMountLog = referencedCaseElementMaps.SegmentMountLog[segmentMountLogId]

            // if (!segmentMountLog) {
            //   continue
            // }

            // const { side } = segmentMountLog
            const { widthCoord, passlineCoord, thicknessCoord, side } = slot as BaseSideSlot
            const { type: tagName } = ThreeUtil.getElementInfo(path)
            const { type: parentTagName } = ThreeUtil.getParentInfo(path)
            const elementName = ElementMapsUtil.getElementName(tagName, parentTagName)

            // find in the current case the element that has the same side and position
            const mountLogMap = elementMaps[`${elementName}MountLog`]
            const slotMap = elementMaps[`${elementName}Slot`]

            for (const mountLogId in mountLogMap) {
              const mountLog = mountLogMap[mountLogId]
              const slot = slotMap[mountLog?.slotId ?? ''] as BaseSideSlot
              const candidatePath = Mapping.elementPathByMountLogId[mountLog?.id ?? '']

              if (
                !slot ||
                !candidatePath ||
                !(
                  widthCoord === slot.widthCoord &&
                  passlineCoord === slot.passlineCoord &&
                  thicknessCoord === slot.thicknessCoord
                )
              ) {
                continue
              }
 
              if (slot.side === side) {
                if (!FilterHandler.filteredElementsByReferencedCase[individualTerm]) {
                  FilterHandler.filteredElementsByReferencedCase[individualTerm] = {}
                }

                FilterHandler.filteredElementsByReferencedCase[individualTerm][candidatePath] = type
              }
            }
          }
        }
      }

      setTerm(term)
      setSelectedElementPaths()
    }, this.timeoutMs)
  }

  private readonly handleClearFilter = () => {
    const { setTerm, setActiveFilterControlIndexes, setCurrentFilterControlValues } = this.props

    setTerm('')
    setActiveFilterControlIndexes([])
    setCurrentFilterControlValues({})
    this.setState({ term: '' })
  }

  private readonly handleDeIncrementFilter = (
    definition: FilterControlDefinition,
    nextValue: number,
    index: number,
  ) => {
    const { currentFilterControlValues, setCurrentFilterControlValues, setTerm } = this.props
    const { filter } = definition
    const varName = definition.setVar

    if (varName && typeof varName === 'string') {
      window.dispatchEvent(new CustomEvent(`${varName}Changed`, { detail: { nextValue } }))

      if (!(window as any).filterControlVariables) {
        ;(window as any).filterControlVariables = {}
      }

      ;(window as any).filterControlVariables[varName] = nextValue
    }

    setCurrentFilterControlValues({ ...currentFilterControlValues, [index]: nextValue })
    setTerm(this.getNextFilter(filter, nextValue))
  }

  private readonly handleIncrementFilter = (index: number) => {
    const { filterControlDefinitions, currentFilterControlValues } = this.props

    const definition = filterControlDefinitions[index]

    if (!definition) {
      // TODO: translate
      enqueueSnackbar('No filter control definition found', { variant: 'error', autoHideDuration: 3000 })

      return
    }

    const { maxValue = Infinity, valueStep = 1 } = definition

    const currentValue = currentFilterControlValues[index] ?? 0
    const nextValue = currentValue + valueStep
    const numberedMaxValue = typeof maxValue === 'number'
      ? maxValue
      : this.getMinOrMaxForFilterControlDefinition('max', definition)

    if (nextValue <= numberedMaxValue) {
      this.handleDeIncrementFilter(definition, nextValue, index)
    }
  }

  private readonly handleDecrementFilter = (index: number) => {
    const { filterControlDefinitions, currentFilterControlValues } = this.props
    const definition = filterControlDefinitions[index]

    if (!definition) {
      // TODO: translate
      enqueueSnackbar('No filter control definition found', { variant: 'error', autoHideDuration: 3000 })

      return
    }

    const { minValue = -Infinity, valueStep = 1 } = definition
    const currentValue = currentFilterControlValues[index] ?? 0
    const nextValue = currentValue - valueStep

    const numberedMinValue = typeof minValue === 'number'
      ? minValue
      : this.getMinOrMaxForFilterControlDefinition('min', definition)

    if (nextValue >= numberedMinValue) {
      this.handleDeIncrementFilter(definition, nextValue, index)
    }
  }

  private readonly handleInputChange = (event: any, index: number) => {
    this.setState({ inputValuesPerIndex: { ...this.state.inputValuesPerIndex, [index]: Number(event.target.value) } })
  }

  private readonly handleInputKeyDown = (event: any, index: number) => {
    if (event.keyCode === 13) {
      this.handleChangeFilterWithInput(index)
    }
  }

  private readonly handleChangeFilterWithInput = (index: number) => {
    const {
      filterControlDefinitions,
      currentFilterControlValues,
      setCurrentFilterControlValues,
      setTerm,
    } = this.props
    const value = this.state.inputValuesPerIndex[index] ?? 0
    const definition = filterControlDefinitions[index]

    if (!definition) {
      // TODO: translate
      enqueueSnackbar('No filter control definition found', { variant: 'error', autoHideDuration: 3000 })

      return
    }

    const {
      minValue = -Infinity,
      maxValue = Infinity,
      valueStep = 1,
      defaultValue = 1,
      setVar,
      filter,
    } = definition

    const numberedMinValue = typeof minValue === 'number' || !Number.isNaN(Number(minValue))
      ? Number(minValue)
      : this.getMinOrMaxForFilterControlDefinition('min', definition)

    const numberedMaxValue = typeof maxValue === 'number' || !Number.isNaN(Number(maxValue))
      ? Number(maxValue)
      : this.getMinOrMaxForFilterControlDefinition('max', definition)

    if (
      (minValue !== undefined && Number.isNaN(numberedMinValue)) ||
      (maxValue !== undefined && Number.isNaN(numberedMaxValue))
    ) {
      enqueueSnackbar(
        'Min or max value is not a number',
        { variant: 'error', autoHideDuration: 3000 },
      )
    }

    if (value < numberedMinValue || value > numberedMaxValue || (value - defaultValue) % valueStep !== 0) {
      if (value < numberedMinValue) {
        enqueueSnackbar(
          'Value is smaller than the defined min value',
          { variant: 'error', autoHideDuration: 3000 },
        )
      }
      else if (value > numberedMaxValue) {
        enqueueSnackbar(
          'Value is greater than the defined max value',
          { variant: 'error', autoHideDuration: 3000 },
        )
      }
      else if ((value - defaultValue) % valueStep !== 0) {
        const closestSmaller = value - (value - defaultValue) % valueStep
        const closestBigger = closestSmaller + valueStep

        enqueueSnackbar(
          `Value is not compatible with step and default value
          nearest values are ${closestSmaller} and ${closestBigger}`,
          { variant: 'error', autoHideDuration: 3000 },
        )
      }

      this.setState({ inputValuesPerIndex: { ...currentFilterControlValues } })

      return
    }

    if (setVar) {
      window.dispatchEvent(new CustomEvent(`${setVar}Changed`, { detail: { nextValue: value } }))

      if (!(window as any).filterControlVariables) {
        ;(window as any).filterControlVariables = {}
      }

      ;(window as any).filterControlVariables[setVar] = value
    }

    setTerm(this.getNextFilter(filter, value))
    setCurrentFilterControlValues({ ...currentFilterControlValues, [index]: value, [filter]: value })
  }

  private readonly handleTargetClick = (_event: any) => {
    ThreeManager.base.jumpToFiltered(true)
  }

  private readonly handleToggleShowMoreFilterControls = () => {
    const { showMoreFilterControls } = this.state

    this.setState({ showMoreFilterControls: !showMoreFilterControls })
  }

  private readonly getKeyMinAndMaxFromElementType = (elementType: CasterElementNames, filter: string) => {
    const elementMaps = getElementMapsObject(this.props)
    const filteredElements = FilterHandler
      .getFilteredElements(elementMaps, filter, false) as Record<string, FilterableElementType>
    const paths = Object
      .entries(filteredElements)
      .map(([ path ]) => path)
    const pathsLength = paths.length
    let min, max

    for (let i = 0; i < pathsLength; i++) {
      const path = paths[i]

      if (!path) {
        continue
      }

      const { type } = ThreeUtil.getElementInfo(path)

      if (type !== elementType) {
        continue
      }

      const element = ElementMapsUtil.getFullCasterElementByPath(path, elementMaps)

      if (!element) {
        continue
      }

      const key = String(filter.split('#')[1])

      if (!key) {
        continue
      }

      const keyValue = element[key as keyof typeof element] as unknown
      const numberedKeyValue = typeof keyValue === 'number' ? keyValue : Number(keyValue)

      if (keyValue === undefined || keyValue === null || Number.isNaN(numberedKeyValue)) {
        continue
      }

      // at the start, min and max are both undefined
      if (min === undefined || max === undefined) {
        min = numberedKeyValue
        max = numberedKeyValue
      }
      else if (min > numberedKeyValue) {
        min = numberedKeyValue
      }
      else if (max < numberedKeyValue) {
        max = numberedKeyValue
      }
    }

    return { min: min ?? -Infinity, max: max ?? Infinity }
  }

  private readonly getElementsIncludedInFilterControls = (props: Props) => {
    const elementTypes = [
      'StrandGuide',
      'AirLoop',
      'CoolingLoop',
      'CoolingZone',
      'LoopAssignment',
      'SegmentGroup',
      'Segment',
      'SupportPoint',
      'SegmentGroupSupportPoints',
      'RollerBody',
      'RollerBearing',
      'Nozzle',
      'Roller',
      'SensorPoint',
      'DataPoint',
      'DataLine',
    ]
    const { filterControlDefinitions } = props
    const elementsIncludedInFilterControls: CasterElementNames[] = []
    const minOrMaxWithVariables: {
      elementType: CasterElementNames
      filter: string
    }[] = []

    for (let i = 0; i < filterControlDefinitions.length; i++) {
      const definition = filterControlDefinitions[i]
      const { minValue, maxValue } = definition ?? {}

      if (typeof minValue === 'string' && !minValue.includes(' ')) {
        const [ type ] = minValue.split('#') as CasterElementNames[]

        if (!type) {
          continue
        }

        const isTypeValid = elementTypes.includes(type)
        const typeIsAlreadyIncluded = elementsIncludedInFilterControls.includes(type)

        if (isTypeValid) {
          if (!typeIsAlreadyIncluded) {
            elementsIncludedInFilterControls.push(type)
          }

          const minAlreadyIncluded = minOrMaxWithVariables
            .findIndex(min => min.elementType === type && min.filter === minValue) > -1

          if (minAlreadyIncluded) {
            continue
          }

          minOrMaxWithVariables.push({
            elementType: type,
            filter: minValue,
          })
        }
      }

      if (typeof maxValue === 'string' && !maxValue.includes(' ')) {
        const [ type ] = maxValue.split('#') as CasterElementNames[]
        
        if (!type) {
          continue
        }

        const isTypeValid = elementTypes.includes(type)
        const typeIsAlreadyIncluded = elementsIncludedInFilterControls.includes(type)

        if (isTypeValid) {
          if (!typeIsAlreadyIncluded) {
            elementsIncludedInFilterControls.push(type)
          }

          const maxAlreadyIncluded = minOrMaxWithVariables
            .findIndex(max => max.elementType === type && max.filter === maxValue) > -1

          if (maxAlreadyIncluded) {
            continue
          }

          minOrMaxWithVariables.push({
            elementType: type,
            filter: maxValue,
          })
        }
      }
    }

    return { elementsIncludedInFilterControls, minOrMaxWithVariables }
  }

  private readonly toggleFilter = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    definition: FilterControlDefinition,
    index: number,
  ) => {
    const {
      activeFilterControlIndexes,
      setActiveFilterControlIndexes,
      setTerm,
      setSelectedElementPaths,
      setCurrentFilterControlValues,
      term: currentTerm,
      currentFilterControlValues,
    } = this.props

    const elementMaps = getElementMapsObject(this.props)
    const term = this.getTermFromDefinition(definition)

    if (activeFilterControlIndexes.includes(index)) {
      setActiveFilterControlIndexes(activeFilterControlIndexes.filter(i => i !== index))
    
      const newActiveFilterControlIndexes = { ...currentFilterControlValues }

      delete newActiveFilterControlIndexes[index]
      setCurrentFilterControlValues(newActiveFilterControlIndexes)
    
      let newTerm = currentTerm
    
      // remove && {term} or {term} && from the term
      const escapedTerm = term.replace(/[\\^$*+?.()|[\]{}]/g, '\\$&') // escape special characters in the term

      newTerm = newTerm
        .replace(new RegExp(`\\s*&&\\s*${escapedTerm}`), '')
        .replace(new RegExp(`${escapedTerm}\\s*&&\\s*`), '')
    
      // remove || {term} or {term} ||
      newTerm = newTerm
        .replace(new RegExp(`\\s*\\|\\|\\s*${escapedTerm}`), '')
        .replace(new RegExp(`${escapedTerm}\\s*\\|\\|\\s*`), '')

      // remove the filter (in case it is the only filter)
      newTerm = newTerm.replace(new RegExp(escapedTerm), '')
    
      setTerm(newTerm)
    
      return
    }

    if (activeFilterControlIndexes.length === 2) {
      enqueueSnackbar(
        'You can only select two filter controls at a time', // TODO: translate
        { variant: 'error', autoHideDuration: 3000 },
      )

      return
    }

    setSelectedElementPaths()
    setActiveFilterControlIndexes([
      ...activeFilterControlIndexes,
      index,
    ])
    setCurrentFilterControlValues({
      ...currentFilterControlValues,
      [index]: definition.defaultValue ?? 1,
    })
    setTerm(term)

    const isCtrlClick = e.ctrlKey === true
    const isShiftClick = e.shiftKey === true

    if ((isCtrlClick || isShiftClick) && activeFilterControlIndexes.length === 1) {
      if (isCtrlClick) {
        setTerm(`${currentTerm} && ${term}`)
      }

      if (isShiftClick) {
        setTerm(`${currentTerm} || ${term}`)
      }

      setActiveFilterControlIndexes([ ...activeFilterControlIndexes, index ])
    }
    else {
      setTerm(term)
      setActiveFilterControlIndexes([ index ])
    }

    if (definition.selectAll) {
      const filteredElements = FilterHandler
        .getFilteredElements(elementMaps, term, false) as Record<string, FilterableElementType>

      const paths = Object
        .entries(filteredElements)
        .filter(([ _path, type ]) => !/^Segment(Group)?/.test(type))
        .map(([ path ]) => path)

      setSelectedElementPaths(paths, true)
    }
  }

  private readonly getTermFromDefinition =
    ({ filter, defaultValue = 1 }: FilterControlDefinition) => filter.trim().replace('{VALUE}', String(defaultValue))

  private readonly getNextFilter =
    (filter: string, nextValue: number) => {
      const { term } = this.props

      // Escape special characters in the filter string
      const escapedFilter = filter.replace(/[\\^$*+?.()|[\]{}-]/g, '\\$&')
    
      // Create the regex, replacing {VALUE} with a pattern that matches the number in the term
      const regex = new RegExp(escapedFilter.replace('\\{VALUE\\}', '\\d+'))
    
      // Perform the replacement in the term
      return term.replace(regex, String(filter.replace('{VALUE}', nextValue.toString())))
    }

  private readonly getMinOrMaxValue = (filter: string, minOrMax: 'max' | 'min') => {
    const { minAndMaxPerFilterControl } = this.state
    const [ elementType ] = String(filter).split('#')

    return minAndMaxPerFilterControl[elementType ?? '']?.[filter]?.[minOrMax]
  }

  private readonly getFilterControlTooltip = (definition: FilterControlDefinition) => {
    const {
      title = '',
      filter = '',
      defaultValue,
      minValue,
      maxValue,
      valueStep,
    } = definition

    const minValueTooltip = typeof minValue === 'string'
      ? `${minValue} (${this.getMinOrMaxValue(minValue, 'min')})`
      : minValue
    const maxValueTooltip = typeof maxValue === 'string'
      ? `${maxValue} (${this.getMinOrMaxValue(maxValue, 'max')})`
      : maxValue

    let result = `${title}\n`

    if (filter !== undefined) {
      result += `\nfilter: ${filter}`
    }

    if (defaultValue !== undefined) {
      result += `\ndefault value: ${defaultValue}`
    }

    if (minValueTooltip !== undefined) {
      result += `\nmin value: ${minValueTooltip}`
    }

    if (maxValueTooltip !== undefined) {
      result += `\nmax value: ${maxValueTooltip}`
    }

    if (valueStep !== undefined) {
      result += `\nstep: ${valueStep}`
    }

    return result
  }

  private readonly getMinMaxAndStepForFilterControlIndex = (index: number) => {
    const { activeFilterControlIndexes, filterControlDefinitions } = this.props
    const defaults = {
      minValue: -Infinity,
      maxValue: Infinity,
      valueStep: 1,
    }

    if (activeFilterControlIndexes.length === 0) {
      return defaults
    }

    const definition = filterControlDefinitions[index]

    if (!definition) {
      return defaults
    }

    const { valueStep } = definition

    return {
      minValue: this.getMinOrMaxForFilterControlDefinition('min', definition),
      maxValue: this.getMinOrMaxForFilterControlDefinition('max', definition),
      valueStep: valueStep ?? 1,
    }
  }

  private readonly getMinOrMaxForFilterControlDefinition = (
    minOrMax: 'max' | 'min',
    definition: FilterControlDefinition,
  ) => {
    const { minAndMaxPerFilterControl } = this.state
    const { minValue, maxValue } = definition

    if (minOrMax === 'min' && typeof minValue === 'number') {
      return minValue
    }

    if (minOrMax === 'max' && typeof maxValue === 'number') {
      return maxValue
    }

    const filter = definition[`${minOrMax}Value`]
    const [ elementType ] = String(filter ?? '').split('#')

    if (
      !elementType ||
      !filter ||
      !minAndMaxPerFilterControl[elementType] ||
      minAndMaxPerFilterControl[elementType][filter] === undefined
    ) {
      return minOrMax === 'min' ? -Infinity : Infinity
    }

    return minAndMaxPerFilterControl[elementType][filter][minOrMax] ?? 0
  }

  private readonly getFilterControlInputs = () => {
    const { activeFilterControlIndexes, filterControlDefinitions, featureFlags, t } = this.props
    const elementMaps = getElementMapsObject(this.props)
    const { inputValuesPerIndex } = this.state

    if (activeFilterControlIndexes.length === 0) {
      return null
    }

    const filterControlDefs = filterControlDefinitions ?? []
    const showFilterControls = FeatureFlags.canUseFilterControls(featureFlags) &&
      filterControlDefs.length > 0 &&
      elementMaps.Caster

    return (
      <ControlInputsContainer>
        {
          activeFilterControlIndexes.map((index) => {
            const definition = filterControlDefinitions[index]
            const { title = '', defaultValue = 1 } = definition ?? {}
            const { minValue, maxValue, valueStep } = this.getMinMaxAndStepForFilterControlIndex(index)
            const inputValue = inputValuesPerIndex[index]
            const value = inputValue ?? defaultValue
            const filterControlHasValue = definition?.filter?.includes('{VALUE}')
            const downButtonDisabled = inputValue === undefined || inputValue - valueStep < minValue
            const topButtonDisabled = inputValue === undefined || inputValue + valueStep > maxValue

            return (
              <ControlInputs key={index}>
                <FilterControlTitle title={title}>
                  {title}
                </FilterControlTitle>
                {
                  showFilterControls && definition?.isInputVisible !== false && filterControlHasValue && (
                    <FilterControlInput
                      type='number'
                      min={minValue}
                      max={maxValue}
                      step={valueStep}
                      value={value}
                      onChange={(e) => this.handleInputChange(e, index)}
                      onKeyDown={(e) => this.handleInputKeyDown(e, index)}
                      onBlur={() => this.handleChangeFilterWithInput(index)}
                    />
                  )
                }
                {
                  definition?.hasUpDown && (
                    <div style={{ display: 'flex', marginRight: '10px' }}>
                      <StyledButton
                        title={t('down')}
                        value='down'
                        onClick={() => this.handleDecrementFilter(index)}
                        disabled={downButtonDisabled}
                      >
                        <FontAwesomeIcon icon={faAngleDown} />
                      </StyledButton>
                      <StyledButton
                        title={t('up')}
                        value='up'
                        onClick={() => this.handleIncrementFilter(index)}
                        disabled={topButtonDisabled}
                      >
                        <FontAwesomeIcon icon={faAngleUp} />
                      </StyledButton>
                    </div>
                  )
                }
                <Target>
                  <FontAwesomeIcon icon={faCrosshairs} onClick={this.handleTargetClick} />
                </Target>
              </ControlInputs>
            )
          })
        }
      </ControlInputsContainer>
    )
  }

  public override render () {
    const {
      activeFilterControlIndexes,
      currentCasterDialogWidth,
      casterDashboardTabIndex,
      casterDashboardWidth,
      featureFlags,
      filterControlDefinitions,
      openDialogs,
      t,
      termDisabled,
      rollerChildren,
    } = this.props

    const elementMaps = getElementMapsObject(this.props)

    const { showMoreFilterControls } = this.state
    const filterControlDefs = filterControlDefinitions ?? []

    const filterBarDisabled = !FeatureFlags.canUseFilterBar(featureFlags)
    const canViewFilterBar = FeatureFlags.canViewFilterBar(featureFlags)

    const showFilterControls = FeatureFlags.canUseFilterControls(featureFlags) &&
      filterControlDefs.length > 0 &&
      elementMaps.Caster
    const firstFiveFilterControlDefinitions = filterControlDefs.slice(0, 5)
    const expandedFilterControlDefinitions = filterControlDefs.slice(5)

    // const disabledUpOrDownButtonStyle = { color: tinycolor('#FFFFFF').darken(50).toString() }

    return (
      <FilterContainer
        $CasterTree={openDialogs.includes('CasterTree')}
        $CasterDialog={openDialogs.includes('CasterDialog') || openDialogs.includes('PartsWarehouse')}
        $CasterDashboard={casterDashboardTabIndex > 0}
        $disabled={!elementMaps.Caster}
        $dashboardWidth={casterDashboardWidth}
        $casterDialogWidth={currentCasterDialogWidth}
      >
        {
          canViewFilterBar &&
          (
            <SearchInput
              $termDisabled={termDisabled}
              $small={Boolean(activeFilterControlIndexes.length)}
              placeholder={t('searchBar.typeToFilter')}
              onChange={this.handleChange}
              value={this.state.term}
              disabled={filterBarDisabled}
            />
          )
        }
        {this.getFilterControlInputs()}
        {
          canViewFilterBar &&
          (
            <ClearButton
              onClick={this.handleClearFilter}
              title={t('searchBar.clearFilter')}
              $small={Boolean(activeFilterControlIndexes.length)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ClearButton>
          )
        }
        <FilterSelectorsContainer>
          <UIControls
            setSelectedElementPaths={this.props.setSelectedElementPaths}
            setTermDisabled={this.props.setTermDisabled}
            termDisabled={this.props.termDisabled}
            key='UIControls'
            featureFlags={featureFlags as Record<string, boolean>}
            rollersVisible={rollerChildren === 2}
          />
          {
            showFilterControls && firstFiveFilterControlDefinitions.map((definition, index) => (
              <FilterControl
                id={`${UI3DID.FilterControl}-${index}`}
                $backgroundColor={definition.color ?? 'cornflowerblue'}
                key={`${filterControlDefs}-${index}`}
                onClick={(e) => this.toggleFilter(e, definition, index)}
                $active={activeFilterControlIndexes.includes(index)}
                title={this.getFilterControlTooltip(definition)}
              >
                <FontAwesomeIcon icon={CasterFilter.icons[definition.icon ?? ''] ?? faCircle} />
              </FilterControl>
            ))
          }
          {
            filterControlDefs.length > 5 && (
              <ShowMoreFilterControlsToggler
                $active={showMoreFilterControls}
                onClick={this.handleToggleShowMoreFilterControls}
              >
                <FontAwesomeIcon icon={faTh} />
              </ShowMoreFilterControlsToggler>
            )
          }
          {
            this.state.showMoreFilterControls && (
              <ExpandedFilterControlsContainer>
                {
                  expandedFilterControlDefinitions.map((definition, index) => (
                    <FilterControl
                      $backgroundColor={definition.color ?? 'cornflowerblue'}
                      key={`${filterControlDefs}-${index + 5}`}
                      onClick={(e) => this.toggleFilter(e, definition, index + 5)}
                      $active={activeFilterControlIndexes.includes(index + 5)}
                      title={this.getFilterControlTooltip(definition)}
                    >
                      <FontAwesomeIcon icon={CasterFilter.icons[definition.icon ?? ''] ?? faCircle} />
                    </FilterControl>
                  ))
                }
              </ExpandedFilterControlsContainer>
            )
          }
        </FilterSelectorsContainer>
      </FilterContainer>
    )
  }
}

export default compose<any>(withTranslation('caster'), connector)(CasterFilter)
