import isEqual from 'lodash/isEqual'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import type { PassedData } from '@/react/Caster'
import FeatureFlags from '@/react/FeatureFlags'
import FilterHandler from '@/three/logic/FilterHandler'
import Util from '@/three/logic/Util'
import { ElementCacheKey } from '@/three/objects'
import { SetValuesData } from '@/three/objects/BaseObject'
import ThreeMold from '@/three/objects/Mold'
import PasslineCurve from '@/three/objects/PasslineCurve'
import ThreeSegmentGroup from '@/three/objects/SegmentGroup'
import type { ElementMaps } from '@/types/state'
import { ElementMapsUtil } from '@/Util/ElementMapsUtil'
import { ElementsUtil } from '@/Util/ElementsUtil'
import { Mapping } from '@/Util/mapping/Mapping'
import { NumericIdMapping } from '@/Util/mapping/NumericIdMapping'
import { dispatchTestingEvent } from '@/Util/testingUtil'

import MainView from '.'
import CalculationUtil from './CalculationUtil'
import ConditionUtil from './ConditionUtil'
import DrawHandlers from './DrawHandlers'
import UpdateTransformHandler from './UpdateTransformHandler'

export default class MainUtil {
  public static updateCasterData (view: MainView, data: PassedData) {
    const { elementMaps } = view
    const { Caster } = elementMaps

    const { moldSlot, moldMountLog } = ElementsUtil.getMoldAndMoldMountLogByDate(elementMaps)
    const sections = ElementsUtil.getPasslineSectionsByDate(elementMaps, view.referenceCasterDate)
    const moldWidthChanged = view.additionalData?.Mold?.width !== data.additionalData?.Mold?.width

    if (!Caster || !sections?.length || !moldSlot || !view.views) {
      return
    }

    if (!isEqual(data.dirtyDeletePaths, view.deleteList)) {
      MainUtil.handleDeleteListUpdate(view, data)
    }

    view.plHeight = CalculationUtil.plHeightCalculator(sections) / 1000

    const dirtyElements = view.dirtyList && view.dirtyList.length > 0

    if (!view.caster && moldSlot && moldMountLog) {
      MainUtil.handleNoCaster(view, data, moldSlot, moldMountLog)
    }
    else if (Boolean(dirtyElements) || data.newCopiedElementsToDraw) {
      DrawHandlers.drawStrandGuide(view)

      if (view.className === 'MainView' && view.views.uiView && view.views.sectionView) {
        view.views.sectionView.updateMinAndMaxPasslineCoordinates()
      }

      if (dirtyElements && view.clearDirtyList) {
        view.clearDirtyList()
      }

      if (data.newCopiedElementsToDraw) {
        data.setNewCopiedElementsToDraw(false)
      }
    }

    if (moldWidthChanged) {
      MainUtil.updateAdditionalData(view, data)
    }

    if (!isEqual(data.selectedPaths, view.selectedElements)) {
      MainUtil.handleNewSelection(view.selectedElements, data, view.phantomElementList, view.elementList)
    }

    MainUtil.handleElementsOrParentsPathDifference(view, data)

    if (!view.sectionDetail && (Boolean(view.dataChanged) || moldWidthChanged)) {
      DrawHandlers.redrawSectionPlane(view, Caster)
    }

    view.selectedElements = data.selectedPaths

    view.setTerm = data.setTerm

    const sgPasslineCoordChanged = view.sgStartPasslnCoord !== data.sgStartPasslnCoord ||
      view.sgEndPasslnCoord !== data.sgEndPasslnCoord

    view.sgStartPasslnCoord = data.sgStartPasslnCoord
    view.sgEndPasslnCoord = data.sgEndPasslnCoord

    if (data.term !== view.term || data.newCopiedElementsToDraw || sgPasslineCoordChanged) {
      view.term = data.term
      MainUtil.handleFilter(
        data.term,
        view.elementList,
        data.elementMaps,
        data.rollerChildren === 1 && view.className === 'MainView',
        view.referenceCasterDate,
      )
    }
  }

  private static updateAdditionalData (view: MainView, data: PassedData) {
    view.additionalData = data.additionalData

    const { moldSlot, moldMountLog } = ElementsUtil
      .getMoldAndMoldMountLogByDate(view.elementMaps)

    if (!moldSlot || !moldMountLog) {
      return
    }

    const caster = view.elementMaps.Caster
    const sections = ElementsUtil.getPasslineSectionsByDate(view.elementMaps, view.referenceCasterDate)

    if (!caster || !sections) {
      return
    }

    view.passlineCurve?.setData(sections, view.clickableObjects, view.applyCurve)

    const moldData = ElementMapsUtil.getFullCasterElement(moldSlot, moldMountLog, 1)

    view.mold?.setValues({
      elementData: moldData as unknown as SetValuesData<MoldSlot, MoldMountLog>['elementData'],
      path: '',
      isDeleted: false,
      isHidden: false,
      isPhantom: false,
      view,
    })

    view.passlineCurve?.drawStrand()

    view.views?.sectionView?.updateTransform(true)

    UpdateTransformHandler.updateTransformElements(view.elementList, data)

    const moldFaceMountLogs = ElementsUtil.getMoldFaceMountLogsByMoldMountLogId(view.elementMaps, moldMountLog.id)

    moldFaceMountLogs.forEach((moldFaceMountLog) => {
      for (const dataPointId of moldFaceMountLog.dataPointMountLogs) {
        // TODO: is this path correct? (Mold/DataPoint:UUID)
        const dataPointElement = view.elementList.DataPoint?.[`Mold/DataPoint:${dataPointId}`]

        if (dataPointElement) {
          dataPointElement.updateTransform()
        }
      }
    })
    UpdateTransformHandler.updateTransformSegments(
      view.containerList.Segment,
      view.elementList.Segment,
    )

    if (view.sectionDetail) {
      return
    }

    for (const side of Util.wideSides) {
      view.coordinate?.generateCoordinateAxes(caster, sections, moldMountLog, side)
      view.coordinateStraight?.generateCoordinateAxes(caster, sections, moldMountLog, side)
    }
  }

  // this gets called twice 1 for main view 1 for section view so thats normal!
  private static handleParentMismatch (view: MainView) {
    const pathsToRemove: string[] = []

    view.deleteList.forEach(path => {
      // element
      const { type: rawType, id } = Util.getElementInfo(path)
      const { id: parentId, type: parentType } = Util.getParentInfo(path)
      const type = rawType as ElementCacheKey
      const elementMapsMountLogName = rawType !== 'SensorPoint'
        ? `${type}MountLog` as keyof ElementMaps
        : `${parentType}SensorPointMountLog` as keyof ElementMaps

      const mountLogId = Mapping.mountLogIdByTypeAndNumericId[rawType][id]
      const mountLog = (view.elementMaps[elementMapsMountLogName] as Record<string, BaseMountLog>)[mountLogId ?? '']

      // parent
      const parentMountLogType = `${parentType}MountLog` as keyof ElementMaps
      // change parentMountLogType to have the first letter lowercase
      const parentMountLogIdKey = `${parentMountLogType[0]!.toLowerCase() + parentMountLogType.substring(1)}Id`
      const parentMountLogId = mountLog?.[parentMountLogIdKey as keyof BaseMountLog] as string | undefined
      const parentMountLog = (view
        .elementMaps[parentMountLogType] as Record<string, BaseMountLog>)[parentMountLogId ?? '']
      const parentMountLogMappedByNumericId = Mapping.mountLogIdByTypeAndNumericId[parentType][parentId]

      if (!mountLog) {
        pathsToRemove.push(path)
      }
      else if (view.elementList?.[type] && (!parentMountLog || parentMountLog.id !== parentMountLogMappedByNumericId)) {
        pathsToRemove.push(path)

        const element = view.elementList[type]?.[path]
        const phantom = view.phantomElementList[type]?.[path]

        if (element) {
          Object.values(element.objects).forEach((object: any) => {
            view.clickableObjects.splice(view.clickableObjects.findIndex(obj => obj.uuid === object.uuid), 1)

            if (object.geometry) {
              object.geometry.dispose()
            }

            element.container.remove(object)
          })

          delete view.elementList[type]?.[path]

          if (phantom) {
            Object.values(phantom.objects).forEach((object: any) => {
              if (object.geometry) {
                object.geometry.dispose()
              }

              phantom.container.remove(object)
            })
          }

          delete view.phantomElementList[type]?.[path]
        }
      }
    })

    if ((pathsToRemove.length > 0) && view.removeDeletePaths) {
      view.removeDeletePaths(pathsToRemove)
    }
  }

  // Work around for roller body behavior
  // TODO: find another way
  public static reloadFilteredElements (view?: MainView) {
    if (!view) {
      return
    }

    const { moldSlot } = ElementsUtil.getMoldAndMoldMountLogByDate(view.elementMaps)

    if (!moldSlot) {
      return
    }

    MainUtil.handleFilter(
      view.term,
      view.elementList,
      view.elementMaps,
      false,
      view.referenceCasterDate,
    )
  }

  private static handleFilter (
    term: string | undefined,
    elementList: any,
    elementMaps: ElementMaps,
    skipRollerChildren: boolean,
    date: Date | undefined,
  ) {
    let newTerm = term
    const lowerNewTerm = newTerm?.toLowerCase()

    // TODO: this should not happen outside of the FilterHandler!
    if (lowerNewTerm === 'r' || lowerNewTerm === 'roller') {
      newTerm = 'r/*'
    }

    const filteredElements = date
      ? FilterHandler.getFilteredElements(
        elementMaps,
        newTerm,
        skipRollerChildren,
        true,
      )
      : {}

    FilterHandler.applyFilterToElements(
      // segmentGroupSlotMap,
      filteredElements,
      elementList,
      // views,
      elementMaps,
      skipRollerChildren,
    )
  }

  private static handleNoCaster (view: MainView, data: any, mold: MoldSlot, moldMountLog: MoldMountLog) {
    view.caster = new THREE.Group()
    view.caster.name = 'Caster'

    view.passlineCurve = new PasslineCurve(view.caster, null)
    view.mold = new ThreeMold(view.caster, null)

    const sections = ElementsUtil.getPasslineSectionsByDate(view.elementMaps, view.referenceCasterDate)

    // TODO: this is called twice 1x for main view 1x for section view maybe we can optimize this
    NumericIdMapping.build(view.elementMaps)

    if (!sections) {
      // eslint-disable-next-line no-console
      console.error('no sections')

      return
    }

    // as long as they are not editable they can stay here
    view.passlineCurve.setData(sections, view.clickableObjects, view.applyCurve)

    const moldData = ElementMapsUtil.getFullCasterElement(mold, moldMountLog, 1)

    view.mold?.setValues({
      elementData: moldData as unknown as SetValuesData<MoldSlot, MoldMountLog>['elementData'],
      path: '',
      isDeleted: false,
      isHidden: false,
      isPhantom: false,
      view: {
        ...view,
        additionalData: data.additionalData,
      } as MainView,
    })
    view.passlineCurve.drawStrand()

    const moldFaces = new THREE.Group()

    moldFaces.name = 'moldFaces'
    ;(view as any).moldFaces = moldFaces
    Util.addOrReplace(view.caster, moldFaces)

    if (view.sectionDetail) {
      view.caster.rotation.y = Util.RAD180
      view.caster.position.x = -(moldMountLog.thickness ?? 0) / 1000
    }

    view.phantoms = new THREE.Group()
    view.phantoms.name = 'Phantoms'
    view.phantoms.rotation.y = Util.RAD270
    DrawHandlers.drawStrandGuide(view)
    DrawHandlers.drawCaster(view)

    Util.addOrReplace(view.scene, view.caster)
    Util.addOrReplace(view.caster, view.phantoms)

    // TODO: maybe wait for the section view to finish!?
    if (!view.sectionDetail && view.setLoadingStatus) {
      view.setLoadingStatus(false)

      requestAnimationFrame(() => {
        dispatchTestingEvent('casterLoaded')
      })
    }

    view.sceneReady = true
    view.isRedrawing = false
  }

  private static handleSelectSegmentGroup (segmentGroupPath: string, elementList: any, isSelected: boolean) {
    const segmentGroup = elementList.SegmentGroup[segmentGroupPath]

    if (!segmentGroup) {
      // eslint-disable-next-line no-console
      console.log('segmentGroup not found', segmentGroupPath)

      return
    }

    for (const child of segmentGroup.container?.children ?? []) {
      if (!Util.wideSides.includes(child.userData.side)) {
        continue
      }

      const { path } = child.userData

      elementList.Segment[path]?.setSegmentGroupSelected(isSelected)
    }
  }

  private static handleNewSelection (selectedElements: any[], data: any, phantomElementList: any, elementList: any) {
    selectedElements.forEach(path => {
      const { type } = Util.getElementInfo(path)

      if (type === 'SegmentGroup') {
        MainUtil.handleSelectSegmentGroup(path, elementList, false)

        return
      }

      if (
        !data.selectedPaths.has(path) &&
        elementList[type] &&
        elementList[type][path]
      ) {
        elementList[type][path].setSelected(false)

        if (phantomElementList[type] && phantomElementList[type][path]) {
          phantomElementList[type][path].hide()
        }
      }
    })

    data.selectedPaths.forEach((path: any) => {
      const { type } = Util.getElementInfo(path)

      if (type === 'SegmentGroup') {
        MainUtil.handleSelectSegmentGroup(path, elementList, true)

        return
      }

      if (elementList[type] && elementList[type][path]) {
        elementList[type][path].setSelected(true)

        if (phantomElementList[type] && phantomElementList[type][path]) {
          phantomElementList[type][path].show()
        }
      }
    })
  }

  private static handleElementsOrParentsPathDifference (view: MainView, data: PassedData) {
    if (!ConditionUtil.editElementsOrParentPathAreDifferent(view, data)) {
      return
    }

    const { featureFlags, sectionDetail, phantomElementList } = view
    const { editElements } = data

    const editElementPath = Object.keys(data.editElements)

    view.editElements = data.editElements
    view.parentPath = data.parentPath
    editElementPath.forEach(path => {
      const { type: rawType } = Util.getElementInfo(path)
      const type = rawType as ElementCacheKey
      const phantomElement = phantomElementList[type]?.[path]

      if (!Util.isPhantom(rawType, sectionDetail) || !phantomElement) {
        return
      }

      if (FeatureFlags.canEditElement(type, featureFlags)) {
        let parentInfo: any = {}

        while (parentInfo.type !== 'Segment') {
          parentInfo = Util.getParentInfo(parentInfo.path || path)
        }

        const side = view.elementList.Segment?.[parentInfo.path]?.container?.userData?.['side']

        phantomElement.container.userData['side'] = side

        phantomElement.setValues({
          elementData: editElements[path] as never, // TODO: fix the never type
          path,
          isDeleted: false,
          isHidden: view.hideList?.includes(path) ?? false,
          isPhantom: true,
          view,
        })
      }
    })
  }

  public static resetSegments (containerList: any) {
    Object.values(containerList.Segment ?? {}).forEach((segment: any) => {
      segment.position.x = 0
      segment.position.z = 0
      segment.rotation.y = Util.RAD270
    })
  }

  private static handleDeleteListUpdate (view: MainView, data: PassedData) {
    if (!view.views) {
      return
    }

    view.deleteList = data.dirtyDeletePaths

    MainUtil.handleParentMismatch(view)

    MainUtil.handleFilter(
      undefined,
      view.elementList,
      data.elementMaps,
      false,
      view.referenceCasterDate,
    )

    delete view.term

    view.updateRoller(Util.RollerMode)
  }

  public static handleNoSectionDetail (view: MainView) {
    if (!view.coordinate || !view.coordinateStraight) {
      return
    }

    DrawHandlers.drawGridHelper(view)

    if (view.applyCurve) {
      view.coordinate.show()
      view.coordinateStraight.hide()
    }
    else {
      view.coordinate.hide()
      view.coordinateStraight.show()
    }
  }

  public static handleIntersects (view: MainView, intersects: any[]) {
    for (let i = 0; i < intersects.length; i++) {
      const { userData } = intersects[i].object
      const { type, path } = userData

      if (userData.disabled) {
        return
      }

      // needed for selection (setSelected())
      if (
        type === 'Nozzle' ||
        (type === 'Roller' && view.rollerChildren !== 2) ||
        (type === 'RollerBody' && view.rollerChildren !== 1) ||
        (type === 'RollerBearing' && view.rollerChildren !== 1) ||
        type === 'SensorPoint' ||
        type === 'DataPoint' ||
        type === 'DataLine' ||
        type === 'SegmentGroupDetails' ||
        type === 'SupportPoint'
      ) {
        if (
          (type === 'Nozzle' && FeatureFlags.canSelectNozzle(view.featureFlags)) ||
          (type === 'Roller' && view.rollerChildren !== 2 && FeatureFlags.canSelectRoller(view.featureFlags)) ||
          (type === 'RollerBody' && view.rollerChildren !== 1 && FeatureFlags.canSelectRollerBody(view.featureFlags)) ||
          (
            type === 'RollerBearing' &&
            view.rollerChildren !== 1 &&
            FeatureFlags.canSelectRollerBearing(view.featureFlags)
          ) ||
          // TODO: add feature flag for sensor point
          (type === 'SensorPoint') || //  && FeatureFlags.canSelectSensorPoint(view.featureFlags)
          (type === 'DataPoint' && FeatureFlags.canSelectDataPoint(view.featureFlags)) ||
          (type === 'DataLine' && FeatureFlags.canSelectDataLine(view.featureFlags)) ||
          (type === 'SegmentGroupDetails' && FeatureFlags.canSelectSupportPoint(view.featureFlags)) ||
          (type === 'SupportPoint' && FeatureFlags.canSelectSupportPoint(view.featureFlags))
        ) {
          view.selection.push(path)
        }

        return
      }

      if (type === 'SegmentGroupShimMarker') {
        ThreeSegmentGroup
          .updateShimMarker(view, path)
          .then(() => {})
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error)
          })

        return
      }

      if (userData.filter) {
        if (!view.setTerm) {
          return
        }

        const { filter } = userData

        if (view.term?.includes(filter)) {
          view.setTerm(filter, false, false, true)
          view.jumpToFiltered()
        }
        else {
          view.setTerm(filter, true, false, true)
          view.jumpToFilter(filter, () => {
            setTimeout(() => {
              view.setTerm(filter, false, false, false)
            }, 1)
          })
        }

        view.setSelectedElementPaths?.()

        return
      }

      if (type === 'Strand') {
        return
      }
    }
  }

  public static setupPerspectiveControls (view: any) {
    view.perspectiveControls = new (OrbitControls as any)(view.perspectiveCamera, view.renderer.domElement)
    view.perspectiveControls.enabled = false
    view.perspectiveControls.enableKeys = false
    view.perspectiveControls.enableRotate = true
    view.perspectiveControls.update()
  }

  public static setupOrthographicControls (view: any) {
    view.orthographicControls = new (OrbitControls as any)(view.orthographicCamera, view.renderer.domElement)
    view.orthographicControls.enabled = false
    view.orthographicControls.enableKeys = false
    view.orthographicControls.enableRotate = false
    view.orthographicControls.update()
  }
}
