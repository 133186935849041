import * as THREE from 'three'

import { StrandSides } from '@/types/elements/enum'

import BaseObject, { BaseObjects, SetValuesData } from './BaseObject'
import PasslineCurve from './PasslineCurve'
import Util from '../logic/Util'

interface Objects extends BaseObjects {
  moldMesh?: THREE.Mesh
}

export default class ThreeMold extends BaseObject<MoldSlot, MoldMountLog, unknown, Objects> {
  private static readonly material = new THREE.MeshStandardMaterial({
    color: '#ff7e34',
    roughness: 0.5,
    metalness: 0.5,
  })

  public static sideDistance: any = {}

  public static height: number

  public static width: number

  public static minWidth: number | undefined

  public static maxWidth: number | undefined

  private static originalWidth: number

  public static thickness: number

  private static passlnCoord: number

  private static copperThickness: number

  public static innerShape: THREE.Shape

  private isFirstTime = true

  public static getWidestWidth () {
    return Math.max(ThreeMold.width ?? 0, ThreeMold.originalWidth ?? 0)
  }

  protected override internalSetValues (data: SetValuesData<MoldSlot, MoldMountLog>) {
    const { elementData, view } = data

    if (!elementData?.height || !elementData.width || !elementData.thickness || !elementData.copperThickness) {
      // eslint-disable-next-line no-console
      console.error('Cannot build ThreeMold, one or more mold values are missing')
    }

    ThreeMold.height = (elementData.height ?? 0) / 1000
    ThreeMold.width = (elementData.width ?? 0) / 1000
    ThreeMold.minWidth = elementData.widthMin ? elementData.widthMin / 1000 : undefined
    ThreeMold.maxWidth = elementData.widthMax ? elementData.widthMax / 1000 : undefined
    ThreeMold.thickness = (elementData.thickness ?? 0) / 1000
    ThreeMold.passlnCoord = (elementData.passlineCoord ?? 0) / 1000
    ThreeMold.copperThickness = (elementData.copperThickness ?? 0) / 1000

    if (this.isFirstTime) {
      this.isFirstTime = false

      ThreeMold.originalWidth = ThreeMold.width
    }

    const { width, thickness, copperThickness } = ThreeMold

    ThreeMold.sideDistance = {
      FixedSide: new THREE.Vector3(0, 0, 0),
      LooseSide: new THREE.Vector3(0, 0, -thickness),
      NarrowFaceRight: new THREE.Vector3(-width / 2, 0, -0),
      NarrowFaceRightWidest: new THREE.Vector3(-ThreeMold.getWidestWidth() / 2, 0, -0),
      NarrowFaceLeft: new THREE.Vector3(width / 2, 0, 0),
      NarrowFaceLeftWidest: new THREE.Vector3(ThreeMold.getWidestWidth() / 2, 0, 0),
    }

    const { General: strand } = view.additionalData ?? {}
    const { width: addWidth, height: addHeight } = strand ?? {}

    const halfWidth = (addWidth || width) / 2
    const nHeight = addHeight || thickness

    const innerShape = new THREE.Shape()

    innerShape.moveTo(-halfWidth, 0)
    innerShape.lineTo(-halfWidth, -nHeight)
    innerShape.lineTo(halfWidth, -nHeight)
    innerShape.lineTo(halfWidth, 0)
    innerShape.lineTo(-halfWidth, 0)

    ThreeMold.innerShape = innerShape

    const moldWidth = ThreeMold.maxWidth ?? ThreeMold.originalWidth
    const fixedSideShape = new THREE.Shape()

    fixedSideShape.moveTo(-moldWidth / 2 - copperThickness, 0)
    fixedSideShape.lineTo(-moldWidth / 2 - copperThickness, copperThickness)
    fixedSideShape.lineTo(moldWidth / 2 + copperThickness, copperThickness)
    fixedSideShape.lineTo(moldWidth / 2 + copperThickness, 0)
    fixedSideShape.lineTo(-moldWidth / 2 - copperThickness, 0)

    const looseSideShape = new THREE.Shape()

    looseSideShape.moveTo(-moldWidth / 2 - copperThickness, -thickness - copperThickness)
    looseSideShape.lineTo(-moldWidth / 2 - copperThickness, -thickness)
    looseSideShape.lineTo(moldWidth / 2 + copperThickness, -thickness)
    looseSideShape.lineTo(moldWidth / 2 + copperThickness, -thickness - copperThickness)
    looseSideShape.lineTo(-moldWidth / 2 - copperThickness, -thickness - copperThickness)

    const leftSideShape = new THREE.Shape()

    leftSideShape.moveTo(halfWidth, -thickness)
    leftSideShape.lineTo(halfWidth, 0)
    leftSideShape.lineTo(halfWidth + copperThickness, 0)
    leftSideShape.lineTo(halfWidth + copperThickness, -thickness)
    leftSideShape.lineTo(halfWidth, -thickness)

    const rightSideShape = new THREE.Shape()

    rightSideShape.moveTo(-halfWidth - copperThickness, -thickness)
    rightSideShape.lineTo(-halfWidth - copperThickness, 0)
    rightSideShape.lineTo(-halfWidth, 0)
    rightSideShape.lineTo(-halfWidth, -thickness)
    rightSideShape.lineTo(-halfWidth - copperThickness, -thickness)

    const extrudeOptions = this.getExtrudeOptions()

    if (!extrudeOptions) {
      return
    }

    const moldGroup = new THREE.Group()

    moldGroup.name = 'ThreeMold'
    moldGroup.position.set(0, PasslineCurve.plHeight, 0)

    this.createSideFromShape(moldGroup, fixedSideShape, extrudeOptions, StrandSides.Fixed)
    this.createSideFromShape(moldGroup, looseSideShape, extrudeOptions, StrandSides.Loose)
    this.createSideFromShape(moldGroup, leftSideShape, extrudeOptions, StrandSides.Left)
    this.createSideFromShape(moldGroup, rightSideShape, extrudeOptions, StrandSides.Right)

    const labelWidth = ThreeMold.maxWidth ?? ThreeMold.getWidestWidth()

    this.generateLabel(labelWidth, ThreeMold.height, ThreeMold.copperThickness, ThreeMold.thickness, StrandSides.Fixed)
    this.generateLabel(labelWidth, ThreeMold.height, ThreeMold.copperThickness, ThreeMold.thickness, StrandSides.Loose)

    Util.addOrReplace(this.container, moldGroup)
  }

  private getExtrudeOptions (): THREE.ExtrudeGeometryOptions | null {
    const start = ThreeMold.passlnCoord / PasslineCurve.plHeight
    const end = (ThreeMold.height + ThreeMold.passlnCoord) / PasslineCurve.plHeight

    const steps = 8

    const points = []
    const step = (end - Math.max(0, start)) / (steps - 1)

    for (let i = Math.max(0, start); i < end; i += step) {
      points.push(PasslineCurve.getCurve().getPointAt(i))
    }

    points.splice(-1)
    points.push(PasslineCurve.getCurve().getPointAt(end))

    if (start < 0) {
      const first = points[0].clone()

      first.y += ThreeMold.passlnCoord * -1
      points.unshift(first)
    }

    if (points.length < 2) {
      // eslint-disable-next-line no-console
      console.error('Cannot build ThreeMold, not enough points in curve!')

      return null
    }

    const curve = new THREE.CatmullRomCurve3(points)

    return {
      steps,
      bevelEnabled: false,
      extrudePath: curve,
    }
  }

  private createSideFromShape (
    container: any,
    shape: THREE.Shape,
    options: THREE.ExtrudeGeometryOptions,
    side: string,
  ) {
    const geometry = new THREE.ExtrudeGeometry(shape, options)

    // geometry.translate(0, PasslineCurve.plHeight, 0)

    const moldMesh = new THREE.Mesh(geometry, ThreeMold.material)

    moldMesh.name = `Mold${side}`

    this.objects.moldMesh = moldMesh

    Util.addOrReplace(container, moldMesh)
  }

  private generateLabel (width: number, height: number, copperThickness: number, thickness: number, side: string) {
    const backgroundMaterial = new THREE.MeshStandardMaterial({ color: '#22282e' })
    const buttonMaterial = new THREE.MeshStandardMaterial({ color: '#474b4e' })

    const label = Util.getText(
      Util.angles2Sides(`${ThreeMold.getWidestWidth() * 1000} x ${thickness * 1000}`),
      0.10,
      false,
      true,
    )

    if (!label) {
      // eslint-disable-next-line no-console
      console.error('Cannot create label for mold!')

      return
    }

    label.position.z += 0.02
    label.position.y -= 0.01

    const backgroundWidth = 1
    const geometry = new THREE.PlaneGeometry(backgroundWidth + 0.1, 0.3, 1)
    const textBackgroundGeometry = new THREE.PlaneGeometry(backgroundWidth, 0.2, 1)

    const background = new THREE.Mesh(geometry, backgroundMaterial)
    const textBackground = new THREE.Mesh(textBackgroundGeometry, buttonMaterial)

    textBackground.position.z += 0.01

    const group = new THREE.Group()

    group.name = `MoldLabel${side}`

    group.add(label)
    group.add(background)
    group.add(textBackground)

    if (side === StrandSides.Fixed) {
      group.position.set(
        copperThickness,
        PasslineCurve.plHeight - height / 1000 / 2 - 0.1,
        -(width / 2 + copperThickness + backgroundWidth / 2 + 0.05),
      )

      group.rotation.y = Util.RAD90
    }
    else {
      group.position.set(
        -copperThickness - thickness,
        PasslineCurve.plHeight - height / 1000 / 2 - 0.1,
        width / 2 + copperThickness + backgroundWidth / 2 + 0.05,
      )

      group.rotation.y = Util.RAD270
    }

    Util.addOrReplace(this.container, group)
  }
}
